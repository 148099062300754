import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { COLORS, FONTS } from '../constants';

const { Title } = Typography;

const AppHeader = ({
  fullWidth,
  headerOptions = {
    leftComponent: null,
    rightComponent: null,
    layout: {
      left: 4,
      middle: 16,
      right: 4
    },
    title: '',
    hide: false,
    titleStyle: {},
    containerStyle: {},
  }
}) => {
  const {
    title,
    leftComponent,
    rightComponent,
    titleStyle,
    hide,
    containerStyle,
    layout = {
      left: 4,
      middle: 16,
      right: 4
    }
  } = headerOptions;

  if (hide) {
    return null;
  }

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        ...styles.container,
        ...containerStyle,
        minHeight: (!headerOptions.title && !headerOptions.leftComponent && !headerOptions.rightComponent) ? 0 : 90
      }}
    >
      <Col span={fullWidth ? 22 : 24}>
        <Row justify="center" align="middle">
          <Col span={layout.left} style={{ textAlign: 'left'}}>
            {leftComponent}
          </Col>
          <Col span={layout.middle} style={{ textAlign: 'center'}}>
            <Title style={{ ...styles.title, ...titleStyle }}>{title}</Title>
          </Col>
          <Col span={layout.right} style={{ textAlign: 'right'}}>
            {rightComponent}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const styles = {
  container: {
    // padding: '30px 0px',
    // minHeight: 80,
    backgroundColor: '#fff'
  },
  title: {
    margin: 0,
    ...FONTS.header,
    color: COLORS.dark
  }
}


export default AppHeader;

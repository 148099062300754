import React, { useEffect } from 'react';
import {
  Row, Col, Layout
} from 'antd';
import _ from 'lodash'
import { useLocation } from 'react-router-dom';
import AppHeader from './AppHeader';
import AppModal from './AppModal';

const { Content } = Layout

const AppLayout = ({
  headerOptions = {
    leftComponent: null,
    rightComponent: null,
    title: '',
    hide: false,
    titleStyle: {},
    containerStyle: {},
  },
  containerStyle = {},
  children,
  color = '#fff',
  fullWidth = false
}) => {
  const location = useLocation()
  useEffect(() => {
    if (!_.isEmpty(window)) window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location.pathname])

  return (
    <Content
      style={{
        background: color,
        minHeight: window.innerHeight + 5,
        ...styles.container,
        ...containerStyle,
        paddingBottom: fullWidth ? 0 : 30
      }}
    >
      <Row justify="center">
        <Col
          xs={fullWidth ? 24 : 22}
          sm={fullWidth ? 24 : 22}
          md={fullWidth ? 18 : 16}
          lg={fullWidth ? 14 : 12}
          xl={fullWidth ? 10 : 8}
        >
          <AppHeader
            fullWidth={fullWidth}
            headerOptions={headerOptions}
          />
          <Content
            style={styles.body}
          >
            <Row justify="center">
              <Col span={24}>
                {children}
              </Col>
            </Row>
          </Content>
        </Col>
      </Row>
      <AppModal />
    </Content>
  )
}

const styles = {
  container: {
    paddingBottom: 30,
  },
  body: {
    minHeight: '100%',
    height: 'auto',
    position: 'relative'
  }
}


export default AppLayout;

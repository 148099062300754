import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import CarParkItem from "../CarParkItem";
import * as CommonAcitions from "../../redux/actions/common";
import { DefaultStyles } from "../../constants";
import CarParkItemMap from "../CarParkItemMap";

const SlidingUpPanel = ({
  setCenter,
  defaultGeolocation,
  geolocation,
  redirect,
}) => {
  const { t } = useTranslation();
  const charger = useSelector((state) => state.charger);
  const { selectedCarPark } = charger;
  const dispatch = useDispatch();
  const history = useHistory();
  function generateMapURL(geoObj) {
    return `https://www.google.com/maps/search/?api=1&query=${geoObj.lat},${geoObj.lng}`;
  }
  const CarParkSettingObject = {
    reserveBtn: {
      onClick: () => {
        dispatch(CommonAcitions.setBookingStep("select-time-slot"));
        if (redirect) {
          history.push("/booking");
        }
      },
    },
  };

  const getTransform = () => {
    // if (_.isEmpty(selectedCarPark)) {
    //   return 'translateY(100%)'
    // }

    return "translateY(0%)";
  };

  return (
    <div
      style={{
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
        // zIndex: 99,
        // width: '100%',
        // borderTopLeftRadius: 35,
        // borderTopRightRadius: 35,
        // minHeight: 100,
        // transform: getTransform(),
        // transition: 'transform 0.2s ease-in',
        // padding: '10px 30px 30px 30px',
        // backgroundColor: '#fff'
        ...DefaultStyles.slidingUpCard,
        // maxHeight: '50%',
        overflow: "scroll",
      }}
    >
      {/* <Row justify="center">
        <Col>
          <Button
            type="text"
            style={{ textAlign: 'center'}}
            onClick={() => {
              // dispatch(CommonAcitions.setSelectedCarPark({}))
            }}
          >
            Click to Hide
          </Button>
        </Col>
      </Row> */}
      <Row
        justify="center"
        align="middle"
        style={{
          minHeight: 120,
        }}
      >
        {_.isEmpty(selectedCarPark) ? (
          <Col
            span={18}
            style={{
              ...DefaultStyles.subHeader,
              textAlign: "center",
            }}
          >
            {t("select_your_location")}
            <br />
            {t("to_show_more_details")}
          </Col>
        ) : (
          <Col span={24} style={{ paddingTop: 30 }}>
            <CarParkItemMap
              customizeObj={CarParkSettingObject}
              item={selectedCarPark}
              showAvailabilityAcAndDc
            />
          </Col>
        )}
      </Row>

      <Row justify="space-between" gutter={[20, 0]}>
        <Col span={18}>
          <a
            // href={generateMapURL({
            //   lat: selectedCarPark.lat,
            //   lng: selectedCarPark.lng,
            // })}
            href={selectedCarPark.google_redirect_link}
            target="blank"
          >
            <Button
              disabled={
                _.isEmpty(selectedCarPark) || !selectedCarPark.google_redirect_link
              }
              style={{
                ...DefaultStyles.button,
                opacity:
                  !_.isEmpty(selectedCarPark)
                  && selectedCarPark.google_redirect_link
                    ? 1
                    : 0.6,
              }}
              // onClick={() => {
              //   dispatch(CommonAcitions.setBookingStep("select-time-slot"));
              //   if (redirect) {
              //     history.push("/booking");
              //   }
              // }}
            >
              <span style={{ ...DefaultStyles.buttonText }}>
                {t("show_on_google_map")}
              </span>
            </Button>
          </a>
        </Col>
        <Col span={6}>
          <Button
            style={{ ...DefaultStyles.button }}
            icon={(
              <img
                alt="My Location"
                src="/assets/paper-plane.svg"
                style={{ ...styles.buttonIcon }}
              />
            )}
            onClick={() => {
              dispatch(CommonAcitions.setSelectedCarPark({}));
              if (geolocation.lat === 0 && geolocation.lng === 0) {
                return setCenter(defaultGeolocation);
              }
              setCenter(geolocation);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  buttonIcon: {
    fontSize: 18,
    color: "#fff",
  },
};

export default SlidingUpPanel;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, notification
} from 'antd';
import _ from 'lodash'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { COLORS, DefaultStyles, FONTS } from '../constants';

const CarParkItem = ({
  item, showAvailability = false, showAvailabilityAcAndDc = false, hideAddress = false
}) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language;
  const app = useSelector((state) => state.app)
  console.log("lang >>>", lang);

  if (_.isEmpty(item)) return null

  if (showAvailability) {
    return (
      <Row align="middle" style={{ marginBottom: 20 }}>
        <Col span={4} style={{ textAlign: 'center' }}>
          <div
            alt={lang !== 'en' && item.name_chinese ? item.name_chinese : item.name}
            style={{...styles.photo, backgroundImage: `url(${item.path ? `${app.origin}/${item.path}` : '/assets/default.png'})`}}
          />
        </Col>
        <Col span={20} style={{ padding: '0px 20px 0px 10px' }}>
          <Row justify="space-between">
            <Col style={DefaultStyles.subHeader}>{lang !== 'en' && item.name_chinese ? item.name_chinese : item.name}</Col>
            <Col span={8}>
              <div style={{
                ...styles.status,
                backgroundColor: item.availablePids ? COLORS.primary : COLORS.error
              }}
              >
                {item.availablePids ? 'AVAILABLE' : 'FULL'}
              </div>
            </Col>
          </Row>
          <Col span={20} style={{marginTop: 10}}>
            <Row>
              <Col style={DefaultStyles.subBody}>{lang !== 'en' && item.address_chinese ? item.address_chinese : item.address}</Col>
            </Row>
            {!_.isUndefined(item.availablePIDs) && (
            <Row>
              <Col style={DefaultStyles.subBody}>
                {`${t('available_charger')}: ${item.availablePIDs}`}
              </Col>
            </Row>
            )}
            {!_.isUndefined(item.pids) && (
            <Row>
              <Col style={DefaultStyles.subBody}>
                {`${t('total_subscription_charger')}: ${item.pids.length}`}
              </Col>
            </Row>
            )}
            {!_.isUndefined(item.totalPIDs) && (
            <Row>
              <Col style={DefaultStyles.subBody}>
                {`${t('total_charger')}: ${item.totalPIDs}`}
              </Col>
            </Row>
            )}
          </Col>
        </Col>
      </Row>
    )
  }

  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
      <Col span={4} style={{ textAlign: 'center' }}>
        <div
          alt={item.name}
          style={{...styles.photo, backgroundImage: `url(${item.path ? `${app.origin}/${item.path}` : '/assets/default.png'})`}}
        />
      </Col>
      <Col span={20} style={{ padding: '0px 10px', wordBreak: 'break-word' }}>
        <Row>
          <Col span={24} style={DefaultStyles.subHeader}>
            {lang !== 'en' && item.name_chinese ? item.name_chinese : item.name}
          </Col>
        </Row>
        {!hideAddress && (
        <Row>
          <Col span={24} style={DefaultStyles.subBody}>
            {lang !== 'en' && item.address_chinese ? item.address_chinese : item.address}
          </Col>
        </Row>
        )}
        {!_.isUndefined(item.availablePIDs) && !showAvailabilityAcAndDc && (
        <Row>
          <Col style={DefaultStyles.subBody}>
            {`${t('available_charger')}: ${item.availablePIDs}`}
          </Col>
        </Row>
        )}
        {showAvailabilityAcAndDc && (
          <>
            <Row>
              <Col style={{...DefaultStyles.subBody}}>
                {`${t('available_ac_chargers')}: ${item.availableAcPIDs}`}
              </Col>
            </Row>
            <Row>
              <Col style={{...DefaultStyles.subBody}}>
                {`${t('available_dc_chargers')}: ${item.availableDcPIDs}`}
              </Col>
            </Row>
          </>
        )}
        {!_.isUndefined(item.bookingPIDs) && (
        <Row>
          <Col style={DefaultStyles.subBody}>
            {`${t('total_booking_charger')}: ${item.bookingPIDs}`}
          </Col>
        </Row>
        )}
        {!_.isUndefined(item.totalPIDs) && (
        <Row>
          <Col style={DefaultStyles.subBody}>
            {`${t('total_charger')}: ${item.totalPIDs}`}
          </Col>
        </Row>
        )}
      </Col>
    </Row>
  )
}

const styles = {
  photo: {
    borderRadius: 10,
    ...DefaultStyles.image,
    backgroundSize: 'contain',
    paddingTop: '100%'
  },
  status: {
    ...FONTS.tag,
    color: COLORS.white,
    textAlign: 'center',
    borderRadius: 5,
    padding: 5
  }
}

export default CarParkItem

import React, { useEffect } from 'react';
import {
  Button, Col, Input, Row, Select, Form, notification, message
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setAuth, setUser } from '../redux/actions/common'
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout';
import OTPCodeButton from '../components/OTPCodeButton';
import { COLORS, DefaultStyles, FONTS } from '../constants';

const { Option } = Select;

const ForgetPassword = () => {
  const app = useSelector((state) => state.app)
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const { config = {} } = app;
  const {
    COUNTRY = "",
  } = config;

  const onFinish = async (formData) => {
    if (_.isEmpty(formData.phone) || formData.phone.length > defaultPhoneLength || formData.phone.length < minPhoneLength) {
      return notification.error({
        message: t('please_input_a_valid_phone_number')
      })
    }

    if (_.isEmpty(formData.code)) {
      return notification.error({
        message: t('please_input_a_valid_code')
      })
    }

    const resp = await Service.call(
      'post',
      '/api/web/verify/confirm_otp',
      {
        phone: `${formData.phonePrefix}-${formData.phone}`,
        code: formData.code
      }
    );

    if (resp.errorMessage) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      })
    }

    const phone = `${formData.phonePrefix}-${formData.phone}`
    return history.push(`/reset-password?phone=${encodeURIComponent(phone)}&code=${formData.code}`)
  }

  let defaultPhonePrefix = "+852";
  let defaultPhoneLength = 8;
  let minPhoneLength = 8;
  if (COUNTRY === "AU") {
    defaultPhonePrefix = "+61";
    defaultPhoneLength = 10;
    minPhoneLength = 9
  }
  const prefixSelector = (
    <Form.Item
      style={{ display: "none" }}
      name="phonePrefix"
      initialValue={defaultPhonePrefix}
      noStyle
    >
      <Select size="large" bordered={false} disabled style={{ ...DefaultStyles.body, width: '100%' }} defaultValue={defaultPhonePrefix}>
        <Option value={defaultPhonePrefix}>{defaultPhonePrefix}</Option>
      </Select>
    </Form.Item>
  );

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('forgot_password'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.goBack();
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row justify="center">
        <Col
          span={24}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="phone"
              validateFirst
              rules={[
                { required: true, message: t('required') }, {
                  min: minPhoneLength,
                  max: defaultPhoneLength,
                  message: t(`phone_number_should_be_${defaultPhoneLength}_digits`)
                }]}
            >
              <Input
                style={DefaultStyles.formInput}
                size="large"
                prefix={(
                  <Row align="middle">
                    <Col>
                      <img alt="phone" src="/assets/phone.svg" style={DefaultStyles.fieldIcon} />
                    </Col>
                    <Col>
                      {prefixSelector}
                    </Col>
                  </Row>
                    )}
                placeholder={t('phone')}
              />
            </Form.Item>
            <Row align="middle" gutter={[20, 0]}>
              <Col span={15}>
                <Form.Item
                  name="code"
                  rules={[{ required: true, message: t('required') }]}
                  noStyle
                >
                  <Input
                    style={DefaultStyles.formInput}
                    size="large"
                    prefix={<img alt="phone 2fa" src="/assets/otp.svg" style={DefaultStyles.fieldIcon} />}
                    placeholder={t('verification_code')}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <OTPCodeButton
                  style={DefaultStyles.button}
                  form={form}
                  action="/api/web/verify/forgot_password/send_otp"
                />
              </Col>
            </Row>
            <Row gutter={[20, 0]}>
              <Col span={15} />
              <Col span={9}>
                <Row justify="center" style={{ fontSize: 12, textAlign: 'center', color: '#777777' }}>{t('resend_after_60_seconds')}</Row>
              </Col>
            </Row>
            <div style={{ margin: '30px 0px' }}>
              <Button
                style={DefaultStyles.button}
                type="primary"
                htmlType="submit"
              >
                <span style={DefaultStyles.buttonText}>{t('reset_password')}</span>
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default ForgetPassword;

import React, { useEffect, useState, useRef } from 'react';
import {
  Row, Col, Modal
} from 'antd';
import {
  CheckCircleFilled,
} from '@ant-design/icons';
import _ from 'lodash'
import { useStripe, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service'
import * as Main from '../core/Main'

import AppLayout from './AppLayout'
import { DefaultStyles } from '../constants';
import styles from '../constants/styles';
import {
  setBookingStep, setChargerStatus, setChargerStep, setErrorCode, setErrorMessage, setMonthlySelectedDate, setMonthlyStep, setOrder, setSelectedCarPark, setSelectedCoupon, setSelectedDiscount, setSelectedParkingSlot, setSelectedPID, setSelectedPlan, setSelectedTimeSlot, setUserChargeRecord
} from '../redux/actions/common';

const queryString = require('query-string');

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentCompleted = (props) => {
  const location = useLocation()
  const history = useHistory()

  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const secret = parsed.payment_intent_client_secret;
    if (!secret) return history.replace('/')
    setClientSecret(secret);
  }, [location.search]);

  return (
    <AppLayout
      headerOptions={{
        hide: true
      }}
    >
      {clientSecret && (
        <Elements
          options={{
            theme: 'flat',
            clientSecret,
          }}
          stripe={stripePromise}
        >
          <CheckPaymentIntent clientSecret={clientSecret} />
        </Elements>
      )}
    </AppLayout>
  )
}

const CheckPaymentIntent = ({
  clientSecret
}) => {
  const { t } = useTranslation()
  const stripe = useStripe();
  const dispatch = useDispatch()
  const history = useHistory()
  const intervalRef = useRef()
  const started = useRef(false)
  const charger = useSelector((state) => state.charger);
  const orders = useSelector((state) => state.orders);
  const {
    selectedCar, selectedPlan
  } = charger;

  const { order } = orders;
  const orderRef = useRef()


  const [loadingDots, setLoadingDots] = useState('')
  const dotsIntervalRef = useRef()
  const dotsRef = useRef('')


  useEffect(() => {
    dotsIntervalRef.current = setInterval(() => {
      if (dotsRef.current.length >= 3) {
        dotsRef.current = '.'
      } else {
        dotsRef.current = `${dotsRef.current}.`
      }

      setLoadingDots((prev) => (dotsRef.current))
    }, 400)


    return () => clearInterval(dotsIntervalRef.current)
  }, [])


  useEffect(() => {
    // keep polling API per second
    intervalRef.current = setInterval(() => {
      checkOrderStatus()
    }, 1000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  useEffect(() => {
    if (!stripe) return;
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(async ({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "requires_capture":
        case "succeeded":
        {
          const resp = await Service.call('get', `/api/web/payment/key/${paymentIntent.description}`)
          if (resp.status <= 0) {
            started.current = false
            clearInterval(intervalRef.current)
            dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
            dispatch(setErrorCode(resp.errorCode))
            dispatch(setChargerStep('charger-fail'))
            history.replace('/charger')
            return
          }

          dispatch(setOrder(resp.data.order))
          dispatch(setChargerStatus({
            selectedPID: resp.data.pidDetail,
            selectedPlan: resp.data.planDetail,
            selectedCarPark: resp.data.carParkDetail,
            selectedCoupon: resp.data.order.promo_code_discount,
            selectedDiscount: resp.data.order.discount,
          }))
          if (resp.data.pidDetail.plan_category === 'Monthly') {
            dispatch(setMonthlySelectedDate(resp.data.selectedDate))
          }
          if (resp.data.pidDetail.plan_category === 'UltraFast') {
            dispatch(setSelectedTimeSlot(resp.data.selectedTimeSlot))
            dispatch(setSelectedParkingSlot(resp.data.parkingSlot))
          }

          orderRef.current = resp.data.order
          started.current = true;
          // setMessage("Payment succeeded!");
          break;
        }
        case "processing":
          // setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          // setMessage("Your payment was not successful, please try again.");
          break;
        default:
          // setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);


  const checkOrderStatus = async () => {
    if (!started.current) return;
    const resp = await Service.call('get', `/api/web/order/order_info/${orderRef.current.order_key}`)
    if (resp.errorMessage) {
      started.current = false
      clearInterval(intervalRef.current)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }

    switch (resp.data.order.status) {
      // keep track of order status -> success response
      case 'payment_hold':
      case 'payment_confirmed': {
        await success();
        break;
      }
      // keep track of order status -> fail/cancelled response
      // redirect to charger fail page
      case 'payment_failed':
      case 'cancelled': {
        fail();
        break;
      }
      default: break;
    }
  }

  const success = async (payload) => {
    if (!started.current) return;
    started.current = false;
    clearInterval(intervalRef.current)
    const resp = await Service.call('get', `/api/web/pid/${orderRef.current.pid}`)
    if (resp.errorMessage) {
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }
    dispatch(setSelectedPID(resp.data))


    switch (resp.data.plan_category) {
      // walkin
      case "HourlyNight":
      case "DayNight": {
        await startCharge()
        break;
      }
      case "Monthly": {
        dispatch(setMonthlyStep('payment-success'))
        history.replace('/subscription')
        break;
      }
      case "UltraFast": {
        dispatch(setBookingStep('payment-success'))
        history.replace('/booking')
        break;
      }
      default: break;
    }
  }

  const startCharge = async () => {
    const dataObj = {
      selectedCar,
      selectedPlan,
      order_key: _.isEmpty(orderRef) ? '' : orderRef.current.order_key
    }
    const resp = await Service.call('post', `/api/web/start_charge/${orderRef.current.pid}`, dataObj)
    if (resp.errorMessage) {
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }
    dispatch(setUserChargeRecord(resp.data.userCharge))
    dispatch(setSelectedPID(resp.data.pid))
    dispatch(setSelectedPlan(resp.data.plan))
    dispatch(setSelectedCarPark(resp.data.carPark))
    dispatch(setChargerStep('start-charge'))
    return history.replace('/charger')
  }

  const fail = () => {
    clearInterval(intervalRef.current)
    dispatch(setErrorMessage('payment failed/cancelled'))
    dispatch(setChargerStep('charger-fail'))
    return history.replace('/charger')
  }

  return (
    <Row style={{ minHeight: '80vh' }} align="middle">
      <Col span={24}>
        <Row justify="center" gutter={[0, 20]} style={{ textAlign: 'center', marginTop: 30 }}>
          <Col span={24}>
            <CheckCircleFilled style={styles.circleIcon} />
          </Col>
          <Col span={24}>
            <div style={DefaultStyles.header}>
              {t('your_payment_is_completed')}
            </div>
          </Col>
          <Col span={24}>
            <Row gutter={[5, 0]} justify="center">
              <Col>
                <span style={DefaultStyles.subHeader}>
                  {t('redirect')}
                </span>
              </Col>
              <Col span={2}>
                <div style={{
                  ...DefaultStyles.subHeader,
                  textAlign: 'start',
                }}
                >
                  {loadingDots}
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div style={DefaultStyles.body}>
              *
              {t('do_not_close_the_window')}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default PaymentCompleted;


const fontFamily = 'Inter';

// local color
let colors = {
  green: '#1A468F',
  dark: '#3B3B3C',
  gray: '#F5F5F5',
  white: '#ffffff',
  orange: '#FFBB12',
  yellow: '#FFC709',
  red: '#E74C3C',
  gradient: 'linear-gradient(90deg, #1A468F 0%, #FFC709 100%)',
  transparent: 'transparent',
  transparentWhite: 'rgba(255, 255, 255, 0.2)',
  transparentDark: 'rgba(0, 0, 0, 0.4)',
}

const COLORS = {
  ...colors,
  primary: colors.green,
  secondary: '#A5A5A5',
  darkPrimary: '#2D2D2D',
  bg: colors.white,
  darkBg: colors.dark,
  error: colors.red,
  light: colors.yellow,
  tretiary: colors.orange
}

const SIZES = {
  base: 12,
  padding: 30,
  padding2: 40,
  paddingHorizontal: '30px 0px',
  paddingHorizontal2: '40px 0px',
  paddingVertical: '0px 30px',
  paddingVertical2: '0px 40px',
  margin: 30,
  margin2: 40,
  marginHorizontal: '30px 0px',
  marginHorizontal2: '40px 0px',
  marginVertical: '0px 30px',
  marginVertical2: '0px 40px',
  circle: '100%',
  rounded: 10,
  pill: 30,

  largetTitle: 34,
  h1: 24,
  h2: 20,
  h3: 18, // not use
  h4: 14, // not use
  h5: 16,
  h6: 14,
  body1: 16,
  body2: 14,
  body3: 18, // not use
  body4: 16 // not use
}

const FONTS = {
  banner: {
    fontWeight: '600', fontSize: SIZES.largetTitle, fontFamily
  },
  header: {
    fontWeight: '600', fontSize: SIZES.h1, fontFamily
  },
  subHeader: {
    fontWeight: '600', fontSize: SIZES.h2, fontFamily, lineHeight: '28px'
  },
  h3: {
    fontWeight: '600', fontSize: SIZES.h3, fontFamily, lineHeight: 1.2
  }, // not use
  h4: {
    fontWeight: '500', fontSize: SIZES.h4, fontFamily, lineHeight: 1.2
  }, // not use
  h5: {
    fontWeight: '700', fontSize: SIZES.h5, fontFamily, lineHeight: '22px'
  },
  h6: {
    fontWeight: '500', fontSize: SIZES.h6, fontFamily, lineHeight: '18px'
  },
  body: {
    fontWeight: '400', fontSize: SIZES.body1, fontFamily, lineHeight: '24px'
  },
  subBody: {
    fontWeight: '500', fontSize: SIZES.body2, fontFamily, lineHeight: '24px'
  },
  body3: {
    fontWeight: '500', fontSize: SIZES.body3, fontFamily
  }, // not use
  body4: {
    fontWeight: '500', fontSize: SIZES.body4, fontFamily
  }, // not use
  button: {
    fontWeight: '500', fontSize: SIZES.body2, fontFamily
  },
  tag: {
    fontWeight: '500', fontSize: SIZES.body2, fontFamily
  },
  planPrice: {
    fontWeight: '600', fontSize: SIZES.h2, fontFamily
  },
}

const theme = {
  COLORS,
  FONTS,
  SIZES
}


export {
  COLORS,
  FONTS,
  SIZES
}

export default theme

import React, { useState, useEffect } from 'react';
import {
  Row, Col, Button, Modal
} from 'antd'
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment'
import AddToCalendar from 'react-add-to-calendar'
import { useTranslation } from 'react-i18next';
import { DefaultStyles } from '../constants';

const CalendarButton = ({
  type
}) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  if (_.isEmpty(type)) return null

  return (
    <>
      <Button
        style={{
          ...DefaultStyles.buttonOutline,
          marginBottom: 60
        }}
        onClick={() => setVisible(true)}
      >
        <Row align="middle" gutter={[10, 0]} style={{...DefaultStyles.buttonOutlineText, height: '100%'}}>
          <Col>
            <img src="/assets/add-to-calendar.svg" alt="" style={{ width: 30, height: 30 }} />
          </Col>
          <Col
            span={20}
          >
            {t('add_to_calendar')}
          </Col>
        </Row>
      </Button>
      <AddToCalendarModal type={type} visible={visible} setVisible={setVisible} />
    </>
  )
}


const AddToCalendarModal = ({
  visible, setVisible, type
}) => {
  const { t } = useTranslation()
  const charger = useSelector((state) => state.charger)
  const booking = useSelector((state) => state.booking)
  const monthly = useSelector((state) => state.monthly)
  const [period, setPeriod] = useState({})

  useEffect(() => {
    getTime()
  }, [type])

  const getTime = () => {
    if (type === 'booking') {
      if (!booking.selectedParkingSlot) return;
      let firstBooking = _.first(booking.selectedParkingSlot.booking_items)
      let lastBooking = _.last(booking.selectedParkingSlot.booking_items)
      setPeriod({
        startTime: moment.unix(firstBooking.start_time).toISOString(),
        endTime: moment.unix(lastBooking.end_time).add(1, 'second').toISOString(),
      })
      return
    }

    if (type === 'monthly') {
      setPeriod({
        startTime: moment.unix(monthly.selectedDate).toISOString(),
        endTime: moment.unix(monthly.selectedDate).add(30, 'days').toISOString(),
      })
    }
  }


  return (
    <Modal
      className="add-to-calendar-modal"
      destroyOnClose
      visible={visible}
      closable={false}
      footer={null}
      onCancel={() => {
        setVisible(false)
      }}
      centered
      bodyStyle={{ paddingBottom: "1px", minHeight: 400 }}
      style={{ top: 20 }}
      width="80%"
    >

      <Row>
        <Col span={24}>
          <AddToCalendar
            event={{
              title: `[${_.toUpper(type)}] ${t('charger_schedule')} - ${charger.selectedPID.pid}`,
              description: `
                Car Park - ${charger.selectedCarPark.name}, ${t('charger_pid')} - ${charger.selectedPID.pid}
              `,
              location: charger.selectedCarPark.address,
              startTime: period.startTime,
              endTime: period.endTime
            }}
            rootClass="add-to-calendar"
            optionsOpen
          />
        </Col>
        <Col span={24}>
          <Button
            style={{
              ...DefaultStyles.buttonOutline,
              marginTop: 30
            }}
            onClick={() => setVisible(false)}
          >
            <span style={{
              ...DefaultStyles.buttonOutlineText,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            >
              {t('close')}
            </span>
          </Button>
        </Col>
      </Row>

    </Modal>
  )
}

export default CalendarButton;

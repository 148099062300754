import React, { useEffect, useState } from 'react';
import { Spin, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import {
  MonthlyList,
  MonthlyDetail,
  MonthlyLocation,
  MonthlyPlan,
  MonthlyPaymentDetail,
  MonthlyCoupon
} from '../components/monthly'
import PaymentSuccess from '../components/PaymentSuccess'
import { setChargerInit, setMonthlyStep } from '../redux/actions/common';
import { SubscriptionChargingDetail } from '../components/ChargingDetail';
import SkeletonScreen from '../components/SkeletonScreen';

const Booking = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const monthly = useSelector((state) => state.monthly)

  const {
    step
  } = monthly;
  const history = useHistory()


  const workFlow = () => {
    switch (step) {
      case 'my-monthly-list':
        return (
          <MonthlyList
            setLoading={setLoading}
            loading={loading}
          />
        )
      case 'monthly-detail':
        return (
          <MonthlyDetail />
        )
      case 'select-location':
        return (
          <MonthlyLocation setLoading={setLoading} />
        )
      case 'select-plan':
        return (
          <MonthlyPlan setLoading={setLoading} />
        )
      case 'select-coupon':
        return (
          <MonthlyCoupon setLoading={setLoading} />
        )
      case 'payment-detail':
        return (
          <MonthlyPaymentDetail setLoading={setLoading} />
        )
      case 'payment-success':
        return (
          <PaymentSuccess
            type="monthly"
            chargingDetail={(<SubscriptionChargingDetail mode="payment" />)}
            button={(
              <Button
                style={{ ...styles.button }}
                onClick={() => {
                  dispatch(setChargerInit({}))
                  dispatch(setMonthlyStep({}))
                  history.replace('/')
                }}
              >
                <span style={{ ...styles.buttonText }}>{t('back_to_home')}</span>
              </Button>
                )}
          />
        )
      default: return history.replace('/')
    }
  }

  return (
    <SkeletonScreen loading={loading}>
      {workFlow()}
    </SkeletonScreen>
  )
}

const styles = {
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: '#1A468F',
    borderColor: 'transparent',
    height: 50
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600'
  }
}


export default Booking;

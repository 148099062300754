import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DefaultStyles } from '../../constants';

const RejectPayment = ({ status }) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <div>
      <p
        style={{
          ...DefaultStyles.subHeader,
          textAlign: 'center',
          margin: '30px 0px',
        }}
      >
        {`${t('order')} ${t(status)}`}
      </p>
      <Button
        style={{
          ...DefaultStyles.button,
          position: 'absolute',
          bottom: '30%'
        }}
        onClick={() => {
          history.replace('/')
        }}
      >
        <span style={DefaultStyles.buttonText}>{t('back_to_home')}</span>
      </Button>
    </div>
  )
}

export default RejectPayment;

// app reducer
export const SET_USER = 'SET_USER';
export const SET_AUTH = 'SET_AUTH';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_LOADING = 'SET_LOADING';
export const SET_MODAL = 'SET_MODAL';
export const SET_LOGOUTWAY = 'SET_LOGOUTWAY';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
export const SET_SITE_META = 'SET_SITE_META';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_DEFAULT_CAR = 'SET_DEFAULT_CAR';
export const SET_LANGUAGE = 'SET_LANGUAGE';

// charger reducer
export const SET_SELECTED_CAR = 'SET_SELECTED_CAR';
export const SET_CHARGER_INIT = 'SET_CHARGER_INIT';
export const SET_CHARGER_STEP = 'SET_CHARGER_STEP';
export const SET_SELECTED_PID = 'SET_SELECTED_PID';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const SET_SELECTED_CAR_PARK = 'SET_SELECTED_CAR_PARK';
export const SET_SELECTED_COUPON = 'SET_SELECTED_COUPON';
export const SET_USER_CHARGE_RECORD = 'SET_USER_CHARGE_RECORD';
export const SET_SELECTED_DISCOUNT = 'SET_SELECTED_DISCOUNT';
export const SET_CHARGER_STATUS = 'SET_CHARGER_STATUS';
export const SET_START_TIME = 'SET_START_TIME';
export const SET_CHARGED_TIME = 'SET_CHARGED_TIME';

// car reducer
export const SET_MY_CAR_INIT = 'SET_MY_CAR_INIT';
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const SET_LICENSE = 'SET_LICENSE';
export const SET_MY_CAR_STEP = 'SET_MY_CAR_STEP';

// order reducer
export const SET_ORDER = 'SET_ORDER';

// booking reducer
export const SET_BOOKING_INIT = 'SET_BOOKING_INIT';
export const SET_BOOKING_STEP = 'SET_BOOKING_STEP';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_SELECTED_PARKING_SLOT = 'SET_SELECTED_PARKING_SLOT';
export const SET_SELECTED_BOOKING = 'SET_SELECTED_BOOKING';
export const SET_SELECTED_TIMESLOT = 'SET_SELECTED_TIMESLOT';

// monthly reducer
export const SET_MONTHLY_INIT = 'SET_MONTHLY_INIT';
export const SET_MONTHLY_STEP = 'SET_MONTHLY_STEP';
export const SET_SELECTED_SUBSCRIPTION = 'SET_SELECTED_SUBSCRIPTION';
export const SET_MONTHLY_SELECTED_DATE = 'SET_MONTHLY_SELECTED_DATE';

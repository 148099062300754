import React, { useEffect, useState } from 'react';
import {
  Row, Col, notification, Divider
} from 'antd'
import _ from 'lodash'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import { DefaultStyles } from '../constants';

const PenaltyDetail = ({ penaltyList }) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)


  const getPenaltyItems = () => {
    return _.map(penaltyList, (item) => {
      const duration = _.max([moment.duration(
        moment.unix(item.charger_end_time).diff(
          moment.unix(item.adjust_end_time)
        )
      ).asMinutes() - item.penalty_time, 0])
      return (
        <div key={item.id}>
          <Divider style={{ margin: '10px 0px'}}>
            <span style={DefaultStyles.penaltyDetailLabel}>{moment.unix(item.adjust_start_time).format('YYYY-MM-DD')}</span>
          </Divider>
          <PenaltyItem
            label={t('charger')}
            value={item.pid}
          />
          <PenaltyItem
            label={t('penalty_amount')}
            value={`+HK$${item.penalty_amount.toFixed(2)}`}
          />
          <PenaltyItem
            label={t('expected_end_time')}
            value={moment.unix(item.adjust_end_time).format('h:mm a')}
          />
          <PenaltyItem
            label={t('actual_end_time')}
            value={moment.unix(item.charger_end_time).format('h:mm a')}
          />
          <PenaltyItem
            label={t('penalty_time')}
            value={`${_.ceil(duration)} ${t('mins')}`}
          />
        </div>
      )
    })
  }

  return (
    <Row style={{
      ...DefaultStyles.chargingDetailCard,
      paddingBottom: 10
    }}
    >
      <Col span={24}>
        <PenaltyTitle title={t('penalty_details')} />
        <PenaltyItem
          label={t('penalty_amount')}
          value={`+HK$${_.sumBy(penaltyList, 'penalty_amount').toFixed(2)}`}
        />
        <Row gutter={[0, 0]}>
          <Col
            onClick={() => setShowMore(!showMore)}
            span={24}
            style={{
              ...DefaultStyles.subBody,
              textAlign: 'center',
              cursor: "pointer"
            }}
          >
            {showMore ? t('hide_penalty_details') : t('show_penalty_details') }
          </Col>
        </Row>
        {showMore && getPenaltyItems()}
      </Col>
    </Row>
  )
}

const PenaltyItem = ({
  label = null,
  value = null
}) => {
  if (label === null || value === null) return null

  return (
    <Row justify="space-between" align="middle" gutter={[0, 10]}>
      <Col
        span={12}
        style={DefaultStyles.penaltyDetailLabel}
      >
        {label}
      </Col>
      <Col
        span={12}
        style={DefaultStyles.chargingDetailValue}
      >
        {value}
      </Col>
    </Row>
  )
}

const PenaltyTitle = ({
  title,
  styles = DefaultStyles.chargingDetailLabel
}) => {
  return (
    <Row gutter={[0, 10]}>
      <Col
        span={24}
        style={{
          ...styles,
          textAlign: 'center'
        }}
      >
        {title}
      </Col>
    </Row>
  )
}


export default PenaltyDetail;

import React, { useEffect, useState, useRef } from 'react';
import {
  notification, Button, Row, Col
} from 'antd';
import _ from 'lodash'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { WalkinChargingDetail } from '../ChargingDetail'
import {
  setChargerStep, setErrorCode, setErrorMessage
} from '../../redux/actions/common'

import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import PaymentSuccess from '../PaymentSuccess';
import { DefaultStyles } from '../../constants';

const ChargerStart = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const charger = useSelector((state) => state.charger)
  const {
    userChargeRecord
  } = charger;
  const userChargeRecordRef = useRef(userChargeRecord)
  const fetchingRef = useRef(false);

  useEffect(() => {
    userChargeRecordRef.current = charger.userChargeRecord
  }, [charger.userChargeRecord])

  const intervalRef = useRef()

  const [loadingDots, setLoadingDots] = useState('')
  const dotsIntervalRef = useRef()
  const dotsRef = useRef('')


  useEffect(() => {
    dotsIntervalRef.current = setInterval(() => {
      if (dotsRef.current.length >= 3) {
        dotsRef.current = '.'
      } else {
        dotsRef.current = `${dotsRef.current}.`
      }

      setLoadingDots((prev) => (dotsRef.current))
    }, 500)


    return () => clearInterval(dotsIntervalRef.current)
  }, [])

  useEffect(() => {
    // request by 3s
    intervalRef.current = setInterval(detectChargingStatus, 3000)
    return () => clearInterval(intervalRef.current)
  }, [])

  const detectChargingStatus = async () => {
    if (_.isEmpty(userChargeRecordRef.current)) return;
    if (fetchingRef.current) return;
    fetchingRef.current = true
    const resp = await Service.call('get', `/api/web/pid/start/${userChargeRecordRef.current.pid}/${userChargeRecordRef.current.id}`)
    if (resp.errorMessage) {
      clearInterval(intervalRef.current)
      clearInterval(dotsIntervalRef.current)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      if (resp.errorCode === -93003) {
        dispatch(setChargerStep('charger-no-response'))
      } else {
        dispatch(setChargerStep('charger-fail'))
      }
      return
    }

    const pid = resp.data
    if (pid.redirect) {
      clearInterval(intervalRef.current)
      clearInterval(dotsIntervalRef.current)
      return dispatch(setChargerStep('charging-status'))
    }
    fetchingRef.current = false
  }

  return (
    <PaymentSuccess
      chargingDetail={(<WalkinChargingDetail mode={undefined} />)}
      button={(
        <Button
          disabled
          style={DefaultStyles.button}
        >
          <Row gutter={[5, 0]} justify="center">
            <Col>
              <span style={DefaultStyles.buttonText}>
                {t('start_charging_soon')}
              </span>
            </Col>
            <Col span={2}>
              <div style={{
                ...DefaultStyles.buttonText,
                textAlign: 'start',
              }}
              >
                {loadingDots}
              </div>
            </Col>
          </Row>

        </Button>
        )}
    />
  )
}

export default ChargerStart;

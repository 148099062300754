import React, { useEffect, useState } from 'react';
import {
  Row, Col
} from 'antd';
import _ from 'lodash'
import { useSelector } from 'react-redux'
import moment from 'moment';
import { DefaultStyles } from '../constants';
import { useTranslation } from 'react-i18next';

const WalkinChargingDetail = ({ mode = 'view' }) => {
  const user = useSelector((state) => state.app.user)
  const charger = useSelector((state) => state.charger)
  const orders = useSelector((state) => state.orders)
  const { t } = useTranslation();
  const {
    selectedCar,
    selectedPID,
    selectedPlan,
    selectedCarPark,
    selectedCoupon,
    selectedDiscount,
    userChargeRecord
  } = charger
  const {
    order,
  } = orders

  let orderKey = order?.order_key;
  if (_.includes(["payment"], mode)) {
    orderKey = '';
  }

  if (mode === 'view') {
    return (
      <ChargingDetail
        title={t('charging_details')}
        selectedCarPark={selectedCarPark}
        selectedPID={selectedPID}
        // selectedPlan={selectedPlan}
        selectedCoupon={selectedCoupon}
        discount={selectedDiscount}
        date={moment.unix(userChargeRecord.adjust_start_time).format('YYYY-MM-DD')}
        startTime={userChargeRecord.adjust_start_time}
        showStartTime
        chargedTime={userChargeRecord.charged_time}
        chargedEnergy={userChargeRecord.charged_energy}
        carPlateNumber={selectedCar.license_plate_number}
        userId={user.uid}
        penalty={userChargeRecord.total_penalty}
        orderKey={orderKey}
      />
    )
  }

  if (mode === 'stop') {
    return (
      <ChargingDetail
        title={t('charging_details')}
        selectedCarPark={userChargeRecord.car_park}
        selectedPID={userChargeRecord.pid}
        selectedPlan={userChargeRecord.plan}
        selectedCoupon={userChargeRecord.order.promo_code_discount}
        discount={userChargeRecord.order.discount}
        date={moment.unix(userChargeRecord.adjust_start_time).format('YYYY-MM-DD')}
        startTime={userChargeRecord.adjust_start_time}
        showStartTime
        chargedTime={userChargeRecord.charged_time}
        chargedEnergy={userChargeRecord.charged_energy}
        carPlateNumber={selectedCar.license_plate_number}
        userId={user.uid}
        showChargedEnergy
        endTime={userChargeRecord.utime}
        showEndTime
        penalty={userChargeRecord.total_penalty}
        ptx_id={userChargeRecord.order.ptx_id}
        orderKey={orderKey}
        promo_code_discount={userChargeRecord.order.promo_code_discount}
        />
    )
  }

  return (
    <ChargingDetail
      title={t('charging_details')}
      selectedCarPark={selectedCarPark}
      selectedPID={selectedPID}
      selectedPlan={selectedPlan}
      selectedCoupon={selectedCoupon}
      discount={selectedDiscount}
      carPlateNumber={selectedCar.license_plate_number}
      userId={user.uid}
      date={moment().format('YYYY-MM-DD')}
      orderKey={orderKey}
    />
  )
}

const BookingChargingDetail = ({ mode = 'view' }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.app.user)
  const booking = useSelector((state) => state.booking)
  const charger = useSelector((state) => state.charger)
  const orders = useSelector((state) => state.orders)
  const {
    order,
  } = orders

  const {
    selectedBooking, selectedTimeSlot, step
  } = booking;

  const {
    selectedCar, selectedPlan, selectedCoupon, selectedCarPark, selectedPID, selectedDiscount
  } = charger

  if (mode === 'view') {
    return (
      <ChargingDetail
        title={t('charging_details')}
        discount={selectedBooking.orderInfoDetail.discount}
        selectedCoupon={selectedBooking.orderInfoDetail.promo_code_discount}
        selectedCarPark={selectedBooking.carParkDetail}
        selectedPID={selectedBooking.pidDetail}
        selectedPlan={selectedBooking.planDetail}
        date={moment.unix(selectedTimeSlot.start_time).format('YYYY-MM-DD')}
        startTime={selectedBooking.bookingDetail.start_time}
        showStartTime
        carPlateNumber={selectedCar.license_plate_number}
        userId={user.uid}
        orderKey={selectedBooking?.orderInfoDetail?.order_key}
        promo_code_discount={selectedBooking.orderInfoDetail.promo_code_discount}
      />
    )
  }

  let orderKey = order?.order_key;
  if (_.includes(["payment-success"], step)) {
    orderKey = '';
  }

  return (
    <ChargingDetail
      title={t('charging_details')}
      selectedCarPark={selectedCarPark}
      selectedPID={selectedPID}
      selectedPlan={selectedPlan}
      selectedCoupon={selectedCoupon}
      discount={selectedDiscount}
      date={moment.unix(selectedTimeSlot.start_time).format('YYYY-MM-DD')}
      startTime={selectedTimeSlot.start_time}
      showStartTime
      carPlateNumber={selectedCar.license_plate_number}
      userId={user.uid}
      orderKey={orderKey}
      promo_code_discount={selectedBooking?.orderInfoDetail?.promo_code_discount || 0}
    />
  )
}


const SubscriptionChargingDetail = ({ mode = 'view' }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.app.user);
  const charger = useSelector((state) => state.charger)
  const monthly = useSelector((state) => state.monthly)
  const order = useSelector((state) => state.orders.order)
  const {
    selectedCar, selectedPID, selectedPlan, selectedCarPark, selectedCoupon, selectedDiscount,
  } = charger

  const { selectedSubscription, selectedDate, step } = monthly

  if (mode === 'view') {
    return (
      <ChargingDetail
        title={t('plan_details')}
        selectedCarPark={selectedSubscription.carParkDetail}
        selectedPID={selectedSubscription.pidDetail}
        selectedPlan={selectedSubscription.planDetail}
        selectedCoupon={selectedSubscription.orderInfoDetail.promo_code_discount}
        discount={selectedSubscription.orderInfoDetail.discount}
        date={moment.unix(selectedSubscription.start_time).format('YYYY-MM-DD')}
        startTime={selectedSubscription.start_time}
        endTime={moment.unix(selectedSubscription.end_time).endOf('day').unix()}
        showEndTime
        type="monthly"
        chargedEnergy={selectedSubscription.total_charged_energy}
        showChargedEnergy
        carPlateNumber={selectedCar.license_plate_number}
        userId={user.uid}
        ptx_id={selectedSubscription.orderInfoDetail.ptx_id}
        penalty={selectedSubscription.orderInfoDetail.penalty}
        orderKey={selectedSubscription.orderInfoDetail.order_key}
      />
    )
  }

  let orderKey = order?.order_key;
  if (_.includes(["payment-success"], step)) {
    orderKey = '';
  }

  return (
    <ChargingDetail
      title={t('charging_details')}
      selectedCarPark={selectedCarPark}
      selectedPID={selectedPID}
      selectedPlan={selectedPlan}
      selectedCoupon={selectedCoupon}
      discount={selectedDiscount}
      date={moment.unix(selectedDate).format('YYYY-MM-DD')}
      startTime={monthly.selectedDate}
      endTime={moment.unix(selectedDate).add(30, 'day').endOf('day').unix()}
      showEndTime
      type="monthly"
      carPlateNumber={selectedCar.license_plate_number}
        userId={user.uid}
      orderKey={orderKey}
    />
  )
}


const PaymentHistoryDetail = () => {
  const { t } = useTranslation();
  const charger = useSelector((state) => state.charger)
  const {
    userChargeRecord,
    selectedPID, selectedPlan, selectedCarPark, selectedCoupon, selectedDiscount, startTime, chargedTime,
    refundStatus, refundTime
  } = charger
  return (
    <ChargingDetail
      title={t('charging_details')}
      selectedCarPark={selectedCarPark}
      selectedPID={selectedPID}
      selectedPlan={selectedPlan}
      selectedCoupon={selectedCoupon}
      discount={selectedDiscount}
      date={moment.unix(startTime).format('YYYY-MM-DD')}
      startTime={startTime}
      showStartTime
      chargedTime={_.toNumber(_.sumBy(userChargeRecord.userChargeList, 'charged_time'))}
      chargedEnergy={_.toNumber(_.sumBy(userChargeRecord.userChargeList, 'charged_energy'))}
      showChargedEnergy
      penalty={userChargeRecord.orderInfoDetail.penalty}
      ptx_id={userChargeRecord.ptx_id}
      carPlateNumber={userChargeRecord?.userEv?.license_plate_number}
      userId={userChargeRecord.uid}
      refundStatus={refundStatus}
      refundTime={refundTime}
      orderKey={userChargeRecord.order_key}
    />
  )
}

const ChargingDetail = ({
  title = 'Charging Details',
  selectedCarPark = {},
  selectedPID = {},
  selectedPlan = {},
  selectedCoupon = null,
  discount = 0,
  startTime = 0,
  chargedTime = null,
  showEndTime = false,
  chargedEnergy = 0,
  showChargedEnergy = false,
  showStartTime = false,
  penalty = 0,
  endTime = 0,
  type = '',
  ptx_id = 0,
  userId = 0,
  carPlateNumber = 0,
  refundStatus = 0,
  refundTime = 0,
  orderKey = '',
}) => {
  const { t, i18n } = useTranslation()
  const config = useSelector((state) => state.app.config)
  const [showPaymentDetail, setShowPaymentDetail] = useState(false)
  const [_coupon, setCoupon] = useState(0)
  const [minChargeDiscount, setMinChargeDiscount] = useState(0)

  useEffect(() => {
    getCoupon()
  }, [selectedCoupon, discount])

  const getCoupon = () => {
    let value = 0
    if (_.isNull(selectedCoupon)) {
      getMinChargeDiscount(value)
      return setCoupon(0);
    }
    if (_.isInteger(selectedCoupon)) {
      value = selectedCoupon
      value = value > selectedPlan.price - discount ? selectedPlan.price - discount : value
      setCoupon(value)
      getMinChargeDiscount(value)
      return
    }

    if (_.isObject(selectedCoupon)) {
      value = selectedCoupon?.discount ?? 0
      value = value > selectedPlan.price - discount ? selectedPlan.price - discount : value
      setCoupon(value)
      getMinChargeDiscount(value)
    }
  }

  const displayTime = (time) => {
    const meridiem = moment.unix(time).format('a')
    if (meridiem === 'am') {
      return t('AM_WITH_TIME', { time: moment.unix(time).format('h:mm') })
    }
    return t('PM_WITH_TIME', { time: moment.unix(time).format('h:mm') })
  }

  const getChargingTime = () => {
    if (_.isEmpty(selectedPlan)) return null;
    if (selectedPlan.category === 'Monthly') return null;

    if (selectedPlan.category === 'UltraFast' || 
    selectedPlan.category === 'OCPI' && selectedPID.plan_category === 'UltraFast') {
      return (
        <>
          <ChargingDetailItem
            label={t('charging_period')}
            value={selectedPlan.minute ? `${selectedPlan.minute - 5} ${t('mins')}` : `${selectedPlan.hours} ${t('hrs')}`}
          />
          <ChargingDetailItem
            label={t('buffer')}
            value={`${config.CHARGING_BUFFER} ${t('mins')}`}
          />
        </>
      )
    }

    return (
      <>
        <ChargingDetailItem
          label={t('charging_period')}
          value={selectedPlan.minute ? `${selectedPlan.minute} ${t('mins')}` : `${selectedPlan.hours}  ${t('hrs')}`}
        />
      </>
    )
  }

  const getMinChargeDiscount = (coupon) => {
    let price = 0
    if (!selectedPlan.price) return 0;
    price = selectedPlan.price;
    if (discount) {
      price -= discount
    }
    
    if (coupon) {
      price = price < coupon ? 0 : price - coupon;
    }
    
    if (penalty) {
      price += penalty
    }

    // no min charge
    if (price >= 4) return 0;
    if (price <= 0) return 0;

    setMinChargeDiscount(_.round(price, 2))
  }

  const processRefundStatus = (refund_status) => {
    if (refund_status === 1) {
      return t('all_refund')
    }
    if (refund_status === 2) {
      return t('partial_refund')
    }
    return t('no')
  }

  return (
    <Row justify="center">
      <Col
        span={24}
        style={DefaultStyles.chargingDetailCard}
      >
        <CharingDetailTitle title={title} />
        <ChargingDetailItem
          label={t('car_park_name')}
          value={i18n.language !== 'en' && selectedCarPark.name_chinese ? selectedCarPark.name_chinese : selectedCarPark.name}
        />
        <ChargingDetailItem
          label={t('parking_slot')}
          value={selectedPID.parking_lot}
        />
        <ChargingDetailItem
          label={t('pid')}
          value={selectedPID.pid}
        />
        <ChargingDetailItem
          label={type === 'monthly' ? t('start_date') : t('date')}
          value={
            _.toInteger(startTime)
              ? moment.unix(startTime).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD')
          }
        />
        <ChargingDetailItem
          label={t('order_key')}
          value={orderKey}
        />
        {showStartTime ? (
          <ChargingDetailItem
            label={t('start_time')}
            value={_.toInteger(startTime)
              ? displayTime(startTime)
              : '-'}
          />
        ) : null}
        {showEndTime
          ? (
            <ChargingDetailItem
              label={type === 'monthly' ? t('end_date') : t('end_time')}
              value={_.toInteger(endTime)
                ? type === 'monthly' ? moment.unix(endTime).format('YYYY-MM-DD') : displayTime(endTime) 
                : '-'}
            />
          ) : null}
        {
        !_.isNull(chargedTime)
          ? (
            <ChargingDetailItem
              label={t('charged_time')}
              value={`${_.floor(chargedTime / 60)} ${t('mins')}`}
            />
          ) : null
        }
        {showChargedEnergy && (
        <ChargingDetailItem
          label={t('charged_energy')}
          value={chargedEnergy ? `${_.round(chargedEnergy, 1)} kWh` : '-'}
        />
        )}
        {getChargingTime()}
        {
          !_.isEmpty(selectedPlan) ?     
          (<Row gutter={[0, 10]}>
            <Col
              onClick={() => setShowPaymentDetail(!showPaymentDetail)}
              span={24}
              style={{
                ...DefaultStyles.subBody,
                textAlign: 'center',
                cursor: "pointer"
              }}
            >
              {showPaymentDetail ? t('hide_payment_details') : t('show_payment_details') }
            </Col>
          </Row>) : null
        }
        {showPaymentDetail && (
          <>
            <CharingDetailTitle title={t('payment_details')} />
            {_.toInteger(ptx_id) ? 
            <ChargingDetailItem
              label={t('payment_id')}
              value={ptx_id}
            /> : null
            }
            <ChargingDetailItem
              label={i18n.language !== 'en' && selectedPlan.name_chinese ? selectedPlan.name_chinese : selectedPlan.name}
              value={!_.isEmpty(selectedPlan) ? `HK$${selectedPlan.price.toFixed(2)}` : `HK$0.00`}
            />
    
            <ChargingDetailItem
              label={t('discount')}
              value={`-HK$${discount.toFixed(2)}`}
            />
            <ChargingDetailItem
              label={t('promo_discount')}
              value={`-HK$${_coupon.toFixed(2)}`}
            />
            <ChargingDetailItem
              label={t('user_id')}
              value={userId}
            />
            <ChargingDetailItem
              label={t('license_plate_number')}
              value={carPlateNumber}
            />
            {penalty ? (
              <ChargingDetailItem
                label={t('penalty')}
                value={`+HK$${penalty.toFixed(2)}`}
              />
            ) : null}
            {minChargeDiscount ? (
              <ChargingDetailItem
                label={t('min_charge_discount')}
                value={`-HK$${minChargeDiscount.toFixed(2)}`}
              />
            ) : null}
            <ChargingDetailItem
              label={t('refund')}
              value={processRefundStatus(refundStatus)}
            />
            {refundStatus ? (
              <ChargingDetailItem
                label={t('refund_time')}
                value={moment.unix(refundTime).format('YYYY-MM-DD HH:mm:ss')}
              />
            ) : null}
          </>
        )}
      </Col>
    </Row>
  )
}

const ChargingDetailItem = ({
  label = null,
  value = null
}) => {
  if (label === null || value === null) return null

  return (
    <Row justify="space-between" align="middle" gutter={[0, 10]}>
      <Col
        span={13}
        style={DefaultStyles.chargingDetailLabel}
      >
        {label}
      </Col>
      <Col
        span={11}
        style={DefaultStyles.chargingDetailValue}
      >
        {value}
      </Col>
    </Row>
  )
}

const CharingDetailTitle = ({
  title,
  styles = DefaultStyles.chargingDetailLabel
}) => {
  return (
    <Row gutter={[0, 10]}>
      <Col
        span={24}
        style={{
          ...styles,
          textAlign: 'center'
        }}
      >
        {title}
      </Col>
    </Row>
  )
}

export {
  WalkinChargingDetail,
  ChargingDetail,
  SubscriptionChargingDetail,
  BookingChargingDetail,
  PaymentHistoryDetail,
}

import React, { useEffect } from 'react';
import {
  Button, Col, Input, Row, Form, notification, message
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Joi from 'joi';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';

function ChangePassword() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const onFinish = async (formData) => {
    const resp = await Service.call('put', '/api/web/user/change_password', formData);
    if (resp.errorCode) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      })
    }

    message.success('Update Success');
    return history.push(`/login`);
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('change_password'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.push('/account');
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row justify="center">
        <Col
          span={24}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              className="change-password"
              name="old_password"
              label={t('password')}
              validateFirst
              rules={[
                {
                  required: true,
                  message: t('input_required'),
                },
                () => ({
                  validator(_, value) {
                    const schema = Joi.string().alphanum()
                    const validateResult = schema.validate(value)
                    if (validateResult.error) {
                      return Promise.reject(new Error(t('alphanum_required', { label: t('password') })));
                    }
                    return Promise.resolve();
                  },
                }),
                {
                  min: 8,
                  max: 10
                },
              ]}
            >
              <Input.Password
                style={DefaultStyles.formInput}
                size="large"
                prefix={<img alt="" src="/assets/password.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('old_password')}
              />
            </Form.Item>
            <Form.Item
              className="change-password"
              name="new_password"
              label={t('password')}
              validateFirst
              rules={[
                {
                  required: true,
                  message: t('input_required'),
                },
                () => ({
                  validator(_, value) {
                    const schema = Joi.string().alphanum()
                    const validateResult = schema.validate(value)
                    if (validateResult.error) {
                      return Promise.reject(new Error(t('alphanum_required', { label: t('password') })));
                    }
                    return Promise.resolve();
                  },
                }),
                {
                  min: 8,
                  max: 10
                },
              ]}
            >
              <Input.Password
                style={DefaultStyles.formInput}
                size="large"
                prefix={<img alt="" src="/assets/password.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('new_password')}
              />
            </Form.Item>
            <Form.Item
              className="change-password"
              name="confirm_password"
              label={t('password')}
              dependencies={['password']}
              hasFeedback
              validateFirst
              rules={[
                {
                  required: true,
                  message: t('please_confirm_your_password'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('password_not_match')));
                  },
                }),
              ]}
            >
              <Input.Password
                style={DefaultStyles.formInput}
                size="large"
                prefix={<img alt="" src="/assets/password.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('confirm_password')}
              />
            </Form.Item>
            <div style={{ margin: '30px 0px' }}>
              <Button
                style={DefaultStyles.button}
                type="primary"
                htmlType="submit"
              >
                <span style={DefaultStyles.buttonText}>{t('change_password')}</span>
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </AppLayout>
  );
}

export default ChangePassword;

import { Row, Col, Skeleton } from 'antd'
import React from 'react'

const SkeletonScreen = ({
  loading,
  children
}) => {
  return (
    <>
      <div style={{width: '100%', position: 'absolute', display: loading ? 'block' : 'none'}}>
        <Row justify="center" gutter={[0, 40]} style={{ paddingTop: 40 }}>
          <Col xs={22} md={8}>
            <Row justify="space-between">
              <Col span={4} style={{ textAlign: 'center' }}>
                <Skeleton.Avatar size="large" round active paragraph={false} />
              </Col>
              <Col span={10}>
                <Skeleton.Button style={{ width: '100%' }} size="large" shape="round" active paragraph={false} />
              </Col>
              <Col span={4} />
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={22} md={8}>
            <Skeleton round active />
          </Col>
        </Row>
      </div>
      <div style={{display: !loading ? 'block' : 'none'}}>
        {children}
      </div>
    </>
  )
}

export default SkeletonScreen;

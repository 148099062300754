import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, notification, Progress
} from 'antd';
import {
  CheckCircleFilled
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import AppLayout from '../AppLayout'
import StatusBar from "./StatusBar"
import { WalkinChargingDetail } from '../ChargingDetail'
import { setChargerInit } from '../../redux/actions/common';
import { DefaultStyles } from '../../constants';
import styles from '../../constants/styles'

const ChargingStopSuccess = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const charger = useSelector((state) => state.charger)
  const { userChargeRecord } = charger
  const {
    energy_to_charge, charged_energy, plan_category, total_charged_energy
  } = userChargeRecord

  const backToHome = () => {
    dispatch(setChargerInit())
    history.push('/')
  }

  return (
    <AppLayout
      headerOptions={{
        hide: true
      }}
    >
      <Row style={{ minHeight: '80vh' }} align="middle">
        <Col>
          <Row justify="center" gutter={[0, 20]} style={{ textAlign: 'center', marginTop: 30 }}>
            <Col span={24}>
              <CheckCircleFilled style={styles.circleIcon} />
            </Col>
            <Col span={24}>
              <span style={DefaultStyles.header}>
                {t('charging_ended_successful')}
              </span>
              <br />
              <span style={DefaultStyles.body}>
                {t('your_charging_result')}
              </span>
            </Col>
          </Row>
          <Row justify="center" gutter={[0, 10]}>
            <Col span={24}>
              <StatusBar
                mode="stop"
                value={total_charged_energy}
                total={energy_to_charge}
              />
            </Col>
            <Col span={24}>
              <WalkinChargingDetail mode="stop" />
            </Col>

            <Col span={24}>
              <Button
                style={DefaultStyles.button}
                onClick={backToHome}
              >
                <span style={DefaultStyles.buttonText}>{t('back_to_home')}</span>
              </Button>
            </Col>

          </Row>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default ChargingStopSuccess;

import React, { useEffect, useState } from 'react';
import { Spin, Button, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import MyCar from './MyCar'
import {
  BookingList,
  BookingDetail,
  BookingTimeSlot,
  BookingMapView,
  ParkingSlot,
  BookingLocation,
  BookingPaymentDetail,
  BookingCoupon
} from '../components/booking'
import PaymentSuccess from '../components/PaymentSuccess';
import { BookingChargingDetail } from '../components/ChargingDetail';
import { setBookingInit, setChargerInit } from '../redux/actions/common';
import SkeletonScreen from '../components/SkeletonScreen';
import { COLORS } from '../constants';

const Booking = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const booking = useSelector((state) => state.booking)

  const {
    step
  } = booking;
  const history = useHistory()


  const workFlow = () => {
    switch (step) {
      case 'my-booking':
        return (
          <BookingList
            setLoading={setLoading}
            loading={loading}
          />
        )
      case 'booking-detail':
        return (
          <BookingDetail />
        )
      case 'select-location':
        return (
          <BookingLocation loading={loading} setLoading={setLoading} />
        )
      case 'booking-map-view':
        return (
          <BookingMapView setLoading={setLoading} />
        )

      case 'select-time-slot':
        return (
          <BookingTimeSlot loading={loading} setLoading={setLoading} />
        )
      case 'select-parking-slot':
        return (
          <ParkingSlot loading={loading} setLoading={setLoading} />
        )
      case 'select-car':
        return (
          <MyCar mode="booking" />
        )
      case 'select-coupon':
        return (
          <BookingCoupon setLoading={setLoading} />
        )
      case 'payment-detail':
        return (
          <BookingPaymentDetail setLoading={setLoading} />
        )
      case 'payment-success':
        return (
          <PaymentSuccess
            type="booking"
            chargingDetail={(<BookingChargingDetail mode="payment" />)}
            button={(
              <Button
                style={{ ...styles.button }}
                onClick={() => {
                  dispatch(setChargerInit({}))
                  dispatch(setBookingInit({}))
                  history.replace('/')
                }}
              >
                <span style={{ ...styles.buttonText }}>{t('back_to_home')}</span>
              </Button>
              )}
          />
        )
      default: return history.replace('/')
    }
  }

  return (
    <SkeletonScreen loading={loading}>
      {workFlow()}
    </SkeletonScreen>
  )
}

const styles = {
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.primary,
    borderColor: 'transparent',
    height: 50
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600'
  }
}


export default Booking;

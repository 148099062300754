import React, { useEffect, useState, useCallback } from 'react';
import {
  notification, Select, TreeSelect
} from 'antd';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import * as Main from '../core/Main';


const { TreeNode } = TreeSelect
const LocationSelect = ({
  originalCarParkList,
  setCarParkList,
  className
}) => {
  const { t, i18n } = useTranslation()
  const [locationList, setLocationList] = useState([])
  const [locationSelect, setLocationSelect] = useState()

  useEffect(() => {
    getLocation()
  }, [])

  useEffect(() => {
    getLocationList();
  }, [locationList])


  const getLocation = async() => {
    let dataSource = []
    try {
      const resp = await Service.call('get', '/api/web/location')

      if (resp.status <= 0) {
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }
      dataSource = resp.data
      setLocationList(dataSource)
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setLocationList(dataSource);
    }
  }

  const getLocationList = () => {
    const tempLocation = _.map(locationList, (item) => {
      return ({
        ...item,
        key: item.id,
        value: item.id,
        title: i18n.language === 'en' ? item.name : item.name_zh
      })
    });
    let options = []

    _.map(tempLocation, (location) => {
      if (!location.id || !location.parent_id || location.status === 'Inactive') {
        return
      }

      const parent = _.find(tempLocation, { id: location.parent_id });

      if (_.isUndefined(parent) || !parent.parent_id) {
        return options.push(location)
      }
      if (parent.status === 'Inactive') {
        return
      }

      if (_.isUndefined(parent.children)) {
        parent.children = [];
      }

      return parent.children.push(location);
    })


    setLocationSelect(options)
  }

  const onChangeLocation = (value) => {
    if (!value) return setCarParkList(originalCarParkList)

    const carParks = _.filter(originalCarParkList, { location_id: value })
    setCarParkList(_.compact(carParks))
  }

  return (
    <TreeSelect
      className={className}
      bordered={false}
      size="large"
      placeholder={t('select_area')}
      style={styles.cascader}
      onChange={onChangeLocation}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeDefaultExpandedKeys={[1]}
    >
      {_.map(locationSelect, (level1) => {
        return (
          <TreeNode
            key={level1.value}
            selectable={_.isEmpty(level1.children)}
            isLeaf={_.isEmpty(level1.children) || level1.status === 'Inactive'}
            value={level1.value}
            title={level1.title}
            disabled={level1.status === 'Inactive'}
          >
            {
              _.map(level1.children, (level2) => {
                return (
                  <TreeNode
                    key={level2.value}
                    value={level2.value}
                    title={level2.title}
                    disabled={level2.status === 'Inactive'}
                  />
                )
              })
            }
          </TreeNode>
        )
      })}
    </TreeSelect>
  )
}

const styles = {
  cascader: {
    width: '100%',
    height: 54,
    border: '2px solid #1A468F',
    borderRadius: 10,
    padding: '5px 0px'
  }
}

export default LocationSelect;

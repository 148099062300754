import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Button, Row, Col } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash'
import { useTranslation } from "react-i18next";
import { DefaultStyles } from "../../constants";
import { setChargerStep } from "../../redux/actions/common";

const Card = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.origin)
  const {
    order,
    price,
    onSuccessCallBack,
    checkOrderStatus
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (!stripe || !elements) {
      setLoading(false)
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }


    try {
      const isCancelled = await checkOrderStatus(true)
      if (isCancelled) {
        setLoading(false)
        return;
      }
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
        // Make sure to change this to your payment completion page
          return_url: `${STATIC_SERVER_URL}/payment_completed`,
        },
      });
      setLoading(false);

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {
        stripe && elements ? (
          <CheckoutPanel price={price} stripe={stripe} elements={elements} loading={loading} />
        ) : null
      }
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

const CheckoutPanel = ({
  stripe,
  loading,
  elements,
  price
}) => {
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (!elements || !stripe || loading) {
      return setDisabled(true)
    }
    setDisabled(false)
  }, [stripe, elements])

  return (
    <Row
      justify="center"
      style={DefaultStyles.paymentStickyCard}
    >
      <Col span={24}>
        <Row
          justify="space-between"
          style={{...DefaultStyles.subHeader, minHeight: 60 }}
        >
          <Col>{t('total')}</Col>
          <Col style={{ fontWeight: '500'}}>
            {`HK$${_.round(price, 2).toFixed(2)}`}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Button
          style={{
            ...DefaultStyles.button,
            opacity: !stripe || !elements ? 0.6
              : 1
          }}
          type="white"
          htmlType="submit"
          size="large"
          disabled={disabled}
          loading={loading}
        >
          <span style={DefaultStyles.buttonText}>{t('checkout')}</span>
        </Button>
      </Col>
    </Row>
  )
}

export default Card;

import React, { useState } from 'react';
import {
  Row, Col, Button, notification, Divider, Typography, Space, Spin
} from 'antd';
import {
  LeftOutlined, CreditCardOutlined, CheckOutlined, RightOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FONTS, COLORS, DefaultStyles } from '../constants';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import * as Service from '../core/Service';

const styles = {
  dot: {
    width: 4,
    height: 4,
    backgroundColor: COLORS.dark,
    borderRadius: 8,
  },
};

const PaymentSelection = (props) => {
  const app = useSelector((state) => state.app)
  const { t } = useTranslation()
  const history = useHistory();
  const { userShellCard = {} } = app.user;
  const { value, onChange = () => { } } = props;

  const [loading, setLoading] = useState(true);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);

  useEffect(() => {
    getUserPaymentMethodList();
  }, []);

  const getUserPaymentMethodList = async () => {
    setLoading(true);
    const resp = await Service.call("get", "/api/web/payment/stripe_method");
    setSavedPaymentMethods(resp.data);
    setLoading(false);
  }

  if (loading) return <Spin />;

  let methods = _.map(savedPaymentMethods, (method) => {
    return {
      icon: <CreditCardOutlined />,
      label: method.brand,
      desc: (
        <Space size="small">
          <div style={styles.dot} />
          <div style={styles.dot} />
          <div style={styles.dot} />
          <div style={styles.dot} />
          {method.last4}
        </Space>
      ), key: method.id,
    }
  })
  const paymentMethods = [];
  if(methods[0]){
    paymentMethods.push(methods[0])
    methods.shift()
  }
  paymentMethods.push(...methods)
  paymentMethods.push({
    icon: <CreditCardOutlined />,
    label: t('credit_debit_card'),
    key: 'credit_card',
  });
  
  return (
    <Row
      style={{
        ...DefaultStyles.chargingDetailCard,
        backgroundColor: COLORS.white,
        paddingBottom: 20,
      }}
      justify="center"
      gutter={[0, 10]}
    >
      <Col style={{
        ...DefaultStyles.chargingDetailLabel,
        textAlign: 'center',
        fontWeight: '600'
      }}
      >
        {t('payment_method')}
      </Col>
      {_.map(paymentMethods, (method, index) => {
        const list = [];
        if( _.isNil(value) && paymentMethods[0]?.key === method.key){ onChange(paymentMethods[0].key) }
        list.push(
          <PaymentButton
            {...method}
            key={`paymentMethods_${index}`}
            isSelected={ _.isNil(value)
              ? paymentMethods[0]?.key === method.key : value === method.key}
            onChange={() => onChange(method.key)}
          />
        )
        if (index < paymentMethods.length - 1) {
          list.push(<Divider style={{ margin: '3px 0' }} />);
        }

        return list;
      })}
    </Row>
  )
}

const PaymentButton = (props) => {
  const {
    label, desc, icon, isSelected, onChange, onClick, disabled = false
  } = props;

  return (
    <Col span={24} onClick={onClick || onChange}>
      <Row align="middle" justify="space-around" gutter={[10, 0]}>
        <Col flex="none">
          {icon}
        </Col>
        <Col flex="auto">
          <Row align="middle"justify="space-between">
            <Col>
              {label}
            </Col>
            <Col>
              {desc}
            </Col>
          </Row>
        </Col>
        {!disabled && (
          <Col flex="none">
            <CheckOutlined style={{color: isSelected ? 'black': 'transparent'}} />
          </Col>
        )}
      </Row>
    </Col>

  )
}

export default PaymentSelection;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import {
  setChargerStep, setErrorCode, setErrorMessage, setOrder, setSelectedPID, setSelectedPlan
} from '../../redux/actions/common'

import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import { COLORS, DefaultStyles, FONTS } from '../../constants';

const PIDForm = ({
  setLoading,
  loading,
  navigateCallback
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [PID, setPID] = useState('');
  const dispatch = useDispatch()
  const config = useSelector((state) => state.app.config);

  const getPID = async () => {
    if (_.isEmpty(PID)) {
      return notification.warning({
        message: t('input_pid')
      })
    }

    setLoading(true)
    const resp = await Service.call('get', `/api/web/start_charge/pid/check_available/${PID}`)
    if (resp.status <= 0) {
      setLoading(false)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      return
      // return notification.error({
      //   message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
      // });
    }
    await Service.call('post', '/api/web/user_agent_log', {
      web_version: config.version,
      user_agent: navigator.userAgent
    })
    setLoading(false)

    const {
      pid, PLAN_CATEGORY, plan, orderInfo
    } = resp.data
    dispatch(setSelectedPID(pid))
    if (!_.isUndefined(orderInfo)) {
      dispatch(setOrder(orderInfo))
    }
    if (pid.OCPP_charger_status !== 'Available' && pid.OCPP_charger_status !== 'Preparing') return;
    if (pid.OCPP_charger_status === "Available") {
      return dispatch(setChargerStep('charger-notice'))
    }

    navigateCallback(pid, plan, PLAN_CATEGORY)
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('pid'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            dispatch(setChargerStep('scan-charger'))
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row justify="center" gutter={[0, 20]}>
        <Col span={24}>
          <Input
            value={PID}
            size="large"
            onChange={(e) => setPID(e.target.value)}
            placeholder={t('enter_charger_pid')}
            style={DefaultStyles.formInput}
            prefix={
              <img alt="" src="/assets/car-plate.svg" style={DefaultStyles.fieldIcon} />
            }
          />
        </Col>
        <Col span={24}>
          <Button
            disabled={loading}
            style={DefaultStyles.button}
            onClick={getPID}
          >
            <span style={DefaultStyles.buttonText}>{t('next')}</span>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default PIDForm;

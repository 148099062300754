import { FONTS, COLORS } from "./theme"

const DefaultStyles = {
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.primary,
    borderColor: 'transparent',
    height: 54
  },
  buttonText: {
    ...FONTS.subHeader,
    color: COLORS.white,
  },
  dangerButton: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.error,
    borderColor: 'transparent',
    height: 54
  },
  dangerButtonText: {
    ...FONTS.subHeader,
    color: COLORS.white,
  },
  buttonOutline: {
    width: '100%',
    borderRadius: 10,
    border: `2px solid ${COLORS.primary}`,
    height: 60
  },
  buttonOutlineText: {
    ...FONTS.subHeader,
    color: COLORS.primary,
    height: '100%'
  },
  dangerButtonOutline: {
    width: '100%',
    borderRadius: 10,
    border: `2px solid ${COLORS.error}`,
    height: 60
  },
  dangerButtonOutlineText: {
    ...FONTS.subHeader,
    color: COLORS.error,
    height: '100%'
  },
  chargingStatusCard: {
    borderRadius: 15,
    border: `2px solid ${COLORS.gray}`,
    padding: '20px 10px 0px 10px',
    marginBottom: 20,
    boxShadow: '0px 3px 5px rgb(0 0 0 / 0.1)'

  },
  loadingOverlay: {
    minHeight: '100vh',
    backgroundColor: 'rgba(255, 255,255, 0.8)',
    color: COLORS.dark,
    fontSize: 16
  },
  leftArrow: {
    fontSize: 32,
    textAlign: 'left',
    color: COLORS.dark
  },
  paymentStickyCard: {
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.25)',
    position: 'fixed',
    backgroundColor: COLORS.white,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    padding: '35px 30px',
    zIndex: 999,
  },
  slidingUpCard: {
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.25)',
    position: 'fixed',
    backgroundColor: COLORS.white,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    padding: '0px 30px 30px 30px',
    zIndex: 999,
  },
  banner: {
    ...FONTS.banner,
    color: COLORS.primary
  },
  header: {
    ...FONTS.header,
    color: COLORS.primary
  },
  subHeader: {
    ...FONTS.subHeader,
    color: COLORS.primary
  },
  body: {
    ...FONTS.body,
    color: COLORS.primary
  },
  subBody: {
    ...FONTS.subBody,
    color: COLORS.primary
  },
  formInput: {
    ...FONTS.subHeader,
    backgroundColor: COLORS.gray,
    color: COLORS.primary,
    width: '100%',
    height: 64,
    borderRadius: 10,
    border: 'none',
  },
  fieldIcon: {
    width: 40,
    height: 40,
    margin: '0px 5px'
  },
  couponText: {
    ...FONTS.subHeader,
    color: COLORS.tretiary
  },
  chargingDetailLabel: {
    ...FONTS.h5,
    color: COLORS.dark,
    textAlign: 'start',
    wordBreak: 'break-all'
  },
  chargingDetailValue: {
    ...FONTS.h6,
    textAlign: 'end',
    wordBreak: 'break-all'
  },
  penaltyDetailLabel: {
    ...FONTS.h6,
    color: COLORS.dark,
    textAlign: 'start',
    fontWeight: '600',
    wordBreak: 'break-all'
  },

  penaltyDetailValue: {
    ...FONTS.h6,
    textAlign: 'end',
    wordBreak: 'break-all'
  },
  bookingItemCard: {
    color: COLORS.primary,
    backgroundColor: COLORS.gray,
    padding: 20,
    borderRadius: 10,
    marginBottom: 10
  },
  subscriptionCard: {
    color: COLORS.primary,
    backgroundColor: COLORS.gray,
    padding: 20,
    borderRadius: 10,
    marginBottom: 10
  },
  chargingDetailCard: {
    padding: '15px 30px 0px 30px',
    backgroundColor: COLORS.gray,
    borderRadius: 20,
    marginBottom: 20
  },
  circleIcon: {
    fontSize: 60,
    color: '#32BEA6'
  },
  image: {
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  waitingNote: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
  }
}

export default DefaultStyles;

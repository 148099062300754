import Immutable from 'seamless-immutable';
import * as types from '../actions/types';

const initialState = Immutable({
  step: 'my-monthly-list',
  selectedSubscription: {},
  selectedDate: 0
});

const appReducer = {
  monthly: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_MONTHLY_INIT: {
        return {
          ...state,
          selectedSubscription: {},
          selectedDate: 0
        };
      }
      case types.SET_MONTHLY_STEP: {
        return {...state, step: action.data};
      }
      case types.SET_SELECTED_SUBSCRIPTION: {
        return {...state, selectedSubscription: action.data};
      }
      case types.SET_MONTHLY_SELECTED_DATE: {
        return {...state, selectedDate: action.data};
      }
      default:
        return state;
    }
  }
};

export default appReducer;


import React, { useEffect } from 'react';
import * as Service from '../Service'
import * as Main from '../Main'
import {
  setBookingStep, setChargerStep, setErrorCode, setErrorMessage, setMonthlyStep, setSelectedCarPark, setSelectedCoupon, setSelectedDiscount, setSelectedParkingSlot, setSelectedPID, setSelectedPlan, setSelectedTimeSlot, setUserChargeRecord
} from '../../redux/actions/common';
import { getStore } from '../../redux/store/configureStore';
import _ from 'lodash';

let intervalRef;
let started = false;

const checkOrderStatus = async (order_key, pid) => {
  if (!started) return;
  const resp = await Service.call('get', `/api/web/order/order_info/${order_key}`)
  if (resp.errorMessage) {
    started = false
    clearInterval(intervalRef)
    getStore().dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage)))
    getStore().dispatch(setErrorCode(resp.errorCode))
    getStore().dispatch(setChargerStep('charger-fail'))
    return '/charger'
  }
  switch (resp.data.order.status) {
    // keep track of order status -> success response
    case 'payment_hold':
    case 'payment_confirmed': {
      return success(pid, order_key);
    }
    // keep track of order status -> fail/cancelled response
    // redirect to charger fail page
    case 'payment_failed':
    case 'cancelled': {
      return fail();
    }
    default: break;
  }
}
const success = async (pid, order_key) => {
  if (!started) return;
  started = false;
  clearInterval(intervalRef)
  const resp = await Service.call('get', `/api/web/pid/${pid}`)
  if (resp.errorMessage) {
    getStore().dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage)))
    getStore().dispatch(setErrorCode(resp.errorCode))
    getStore().dispatch(setChargerStep('charger-fail'))
    return "/charger"
  }
  getStore().dispatch(setSelectedPID(resp.data))


  switch (resp.data.plan_category) {
    // walkin
    case "HourlyNight":
    case "DayNight": {
      return startCharge(order_key, pid)
    }
    case "Monthly": {
      getStore().dispatch(setMonthlyStep('payment-success'))
      return "/subscription"
    }
    case "UltraFast": {
      getStore().dispatch(setBookingStep('payment-success'))
      return "/booking"
    }
    default: break;
  }
}

const startCharge = async (order_key, pid) => {
  const charger = getStore().getState().charger;
  const {
    selectedCar, selectedPlan
  } = charger;

  const dataObj = {
    selectedCar,
    selectedPlan,
    order_key
  }
  const resp = await Service.call('post', `/api/web/start_charge/${pid}`, dataObj)
  if (resp.errorMessage) {
    getStore().dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage)))
    getStore().dispatch(setErrorCode(resp.errorCode))
    getStore().dispatch(setChargerStep('charger-fail'))
    return "/charger"
  }
  getStore().dispatch(setUserChargeRecord(resp.data.userCharge))
  getStore().dispatch(setSelectedPID(resp.data.pid))
  getStore().dispatch(setSelectedPlan(resp.data.plan))
  getStore().dispatch(setSelectedCarPark(resp.data.carPark))
  getStore().dispatch(setChargerStep('start-charge'))
  return "/charger"
}

const fail = () => {
  clearInterval(intervalRef)
  getStore().dispatch(setErrorMessage('payment failed/cancelled'))
  getStore().dispatch(setChargerStep('charger-fail'))
  return "/charger"
}

export default async function orderChecking(order_key, pid) {
  started = true;
  await new Promise(() => setTimeout(async () => {
    const path = await checkOrderStatus(order_key, pid);
    if (!_.isUndefined(path)) return path;
    orderChecking(order_key, pid)
  }, 1000))
}

export async function runSavedPaymentMethodStripe({
  order_key,
  paymentMethodID,
}) {
  if (!order_key) {
    console.log('missing_order_key');
    return;
  }
  if (!paymentMethodID) {
    console.log('missing_payment_method_ID');
    return;
  }

  let paymentResp = await Service.call('put', `/api/web/payment/methods`, {
    order_key,
    payment_method: paymentMethodID,
  });

  return paymentResp;
}

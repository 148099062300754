import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Progress, Statistic, Divider, Tag
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AppLayout from '../AppLayout'
import { SubscriptionChargingDetail } from '../ChargingDetail'
import { setMonthlyStep, setUserChargeList } from '../../redux/actions/common';
import { COLORS, DefaultStyles, FONTS } from '../../constants';

const MonthlyDetail = (props) => {
  const { t } = useTranslation()
  const app = useSelector((state) => state.app)
  const monthly = useSelector((state) => state.monthly)
  const dispatch = useDispatch();
  const { selectedSubscription } = monthly

  const endedRecord = _.filter(selectedSubscription.userChargeList, (item) => {
    return item.status === 'End'
  })

  const history = useHistory()

  useEffect(() => {
    if (_.isEmpty(selectedSubscription)) {
      dispatch(setMonthlyStep('my-monthly-list'))
      return history.push('/subscription')
    }
  }, [selectedSubscription])

  return (
    <AppLayout
      headerOptions={{
        title: t('energy_usage'),
        leftComponent: (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={() => {
              dispatch(setMonthlyStep('my-monthly-list'))
            }}
          />
        )
      }}
    >
      <Row justify="center" gutter={[0, 40]}>
        <Col>
          <Progress
            type="circle"
            percent={
              _.round(
                (_.toNumber(selectedSubscription.total_charged_energy) / selectedSubscription.planDetail.kwh),
                3
              ) * 100
            }
            strokeWidth={9}
            strokeColor={COLORS.light}
            strokeLinecap="square"
            width={250}
            format={
              (percent) => (
                <Row>
                  <Col
                    style={{
                      ...DefaultStyles.header,
                      color: COLORS.light,
                      marginBottom: 10
                    }}
                    span={24}
                  >
                    {_.round(selectedSubscription.total_charged_energy, 2)}
                    {' '}
                    kWh
                  </Col>
                  <Col
                    style={DefaultStyles.subBody}
                    span={24}
                  >
                    /
                    {' '}
                    {_.round(selectedSubscription.planDetail.kwh)}
                    {' '}
                    kWh
                  </Col>
                </Row>
              )
            }
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <SubscriptionChargingDetail mode="view" />
        </Col>
      </Row>
      <UserChargList endedRecord={endedRecord} />
    </AppLayout>
  )
}

const UserChargList = ({ endedRecord }) => {
  const { t } = useTranslation()
  if (_.isEmpty(endedRecord)) return null
  return (
    <>
      <Row>
        <Divider />
        <Col
          span={24}
          style={{
            ...DefaultStyles.subHeader,
            textAlign: 'center'
          }}
        >
          {t('monthly_charging_details')}
        </Col>
      </Row>
      {_.map(endedRecord, (item) => {
        return (
          <Row
            style={styles.card}
            align="middle"
            justify="center"

            key={item.id}
          >
            <Col span={20}>
              <Row gutter={[0, 10]} justify="center">
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <div
                        style={DefaultStyles.chargingDetailLabel}
                      >
                        {moment.unix(item.ctime).format("YYYY-MM-DD")}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="space-between" gutter={[0, 12]}>
                    <Col span={10}>
                      <div style={DefaultStyles.chargingDetailLabel}>
                        {t('start_time')}
                      </div>
                      <div style={DefaultStyles.body}>{moment.unix(item.charger_start_time).format('h:mm a')}</div>
                    </Col>
                    <Col span={10}>
                      <div style={DefaultStyles.chargingDetailLabel}>
                        {t('end_time')}
                      </div>
                      <div style={DefaultStyles.body}>{moment.unix(item.charger_end_time).format('h:mm a')}</div>
                    </Col>
                    <Col span={10}>
                      <div style={DefaultStyles.chargingDetailLabel}>
                        {t('charged_time')}
                      </div>
                      <div style={DefaultStyles.body}>
                        {`${_.floor(item.charged_time / 60)} ${t('mins')}`}
                      </div>
                    </Col>
                    <Col span={10}>
                      <div style={DefaultStyles.chargingDetailLabel}>
                        {t('charged_energy')}
                      </div>
                      <div style={DefaultStyles.body}>
                        {_.round(item.charged_energy, 2)}
                        {' '}
                        kWh
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      })}
    </>
  )
}
const styles = {
  card: {
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 10,
    marginBottom: 10,
    marginTop: 20
  }
}


export default MonthlyDetail;

import React, { useEffect, useState } from 'react';
import {
  Button, Col, Input, Row, Statistic, Switch, notification, message, Spin, Modal
} from 'antd';
import {
  LeftCircleFilled,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';
import { setAuth, setDefaultCar, setUser } from '../redux/actions/common';

const { confirm } = Modal;

function Account() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userObj = useSelector((state) => state.app.user);
  const [loading, setLoading] = useState(false)

  const updateOptions = async (value) => {
    setLoading(true)
    try {
      let resp = await Service.call('put', '/api/web/user/push_noti', { receive_promo: value })
      if (resp.errorCode) {
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        })
      }

      resp = await Service.call('get', '/api/web/user');
      if (resp.errorCode) {
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        })
      }
      dispatch(setUser(resp.data));
      message.success(t('update_success'));
    } finally {
      setLoading(false)
    }
  }

  const deleteAccount = async () => {
    try {
      setLoading(true)
      const resp = await Service.call('delete', '/api/web/user/delete')
      if (resp.status === 1) {
        dispatch(setUser({}));
        dispatch(setDefaultCar({}))
        dispatch(setAuth(false))
        message.success(t('update_success'));
        history.replace('/login')
      }
      if (resp.errorCode) {
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('account'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.push('/setting');
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={loading}
      >
        <Row gutter={[0, 20]}>
          <Col>
            <Statistic
              title={t('user_id')}
              formatter={(value) => <span>{userObj.uid}</span>}
            />
          </Col>
        </Row>
        <Row gutter={[0, 20]}>
          <Col>
            <Statistic
              title={t('name')}
              formatter={(value) => <span>{userObj.name}</span>}
            />
          </Col>
        </Row>
        <Row gutter={[0, 20]}>
          <Col>
            <Statistic
              title={t('phone')}
              formatter={(value) => <span>{userObj.phone}</span>}
            />
          </Col>
        </Row>
        {!_.isEmpty(userObj.email) && (
        <Row gutter={[0, 20]}>
          <Col>
            <Statistic
              title={t('email')}
              formatter={(value) => <span>{userObj.email}</span>}
            />
          </Col>
        </Row>
        )}
        <Row gutter={[0, 20]} justify="space-between" align="middle">
          <Col span={19} style={styles.label}>
            {t('push_notification_message')}
          </Col>
          <Col span={5}>
            <Row justify="end">
              <Switch className="receive-promo-switch" disabled={loading} defaultChecked={userObj.receive_promo} onChange={updateOptions} />
              {/* <div style={{ textAlign: 'center' }}>{LanguageSelect()}</div> */}
            </Row>
          </Col>
        </Row>
        <Row align="middle" gutter={[0, 10]}>
          <Col span={24}>
            <Button
              onClick={() => history.push('/account/update')}
              style={DefaultStyles.button}
            >
              <span style={DefaultStyles.buttonText}>{t('update_user_info')}</span>
            </Button>
          </Col>
        </Row>
        <Row align="middle" gutter={[0, 10]}>
          <Col span={24}>
            <Button
              onClick={() => history.push('/account/change-password')}
              style={DefaultStyles.button}
            >
              <span style={DefaultStyles.buttonText}>{t('change_password')}</span>
            </Button>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={24}>
            <Button
              onClick={() => confirm({
                title: t('delete_account_reminder'),
                icon: <ExclamationCircleOutlined />,
                content: t('delete_account_desc'),
                cancelText: t('cancel'),
                okButtonProps: {
                  style: { backgroundColor: COLORS.primary }
                },
                okText: t('confirm'),
                onOk: () => deleteAccount()
              })}
              style={DefaultStyles.dangerButton}
            >
              <span style={DefaultStyles.buttonText}>{t('delete_account')}</span>
            </Button>
          </Col>
        </Row>
      </Spin>
    </AppLayout>
  );
}

const styles = {
  label: {
    ...FONTS.h6,
    fontSize: 12,
    color: COLORS.dark
  }
}

export default Account;

import React, { useEffect, useState, useCallback } from 'react';
import {
  Row, Col, Button, notification, Radio, Skeleton
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _, { times } from 'lodash'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
// import moment from 'moment'
import { useTranslation } from 'react-i18next';
import moment from 'moment/min/moment-with-locales';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import * as CommonActions from '../../redux/actions/common';
import { COLORS, DefaultStyles, FONTS } from '../../constants';


const BookingTimeSlot = () => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    moment.locale(i18n.language === 'zh_hant' ? 'zh-hk' : 'en');
  }, [i18n.language])

  const history = useHistory()
  const app = useSelector((state) => state.app)
  const charger = useSelector((state) => state.charger)
  const { selectedCarPark } = charger
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [bookingInfo, setBookingInfo] = useState([])
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'))
  const [selectedType, setSelectedType] = useState(moment().locale('en').format('A'))


  useEffect(() => {
    getBookingInfo()
  }, [selectedDate])

  useEffect(() => {
    // today
    if (selectedDate.unix() === moment().startOf('day').unix()) {
      setSelectedType(moment().locale('en').format('A'))
    } else {
      setSelectedType('AM')
    }
  }, [selectedDate])

  const getBookingInfo = useCallback(async() => {
    setLoading(true)
    let dataSource = []
    try {
      let start_time = moment(selectedDate).clone().startOf('day').unix()
      let end_time = moment(selectedDate).clone().endOf('day').unix()
      const resp = await Service.call('get',
        `/api/web/booking_info/available/${selectedCarPark.id}/${start_time}/${end_time}`)
      if (resp.status <= 0) {
        setLoading(false)
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }
      dataSource = resp.data
      setBookingInfo(dataSource)
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setBookingInfo(dataSource);
    }
    setLoading(false)
  }, [selectedDate])

  return (
    <AppLayout
      headerOptions={{
        title: i18n.language !== 'en' && selectedCarPark.name_chinese ? selectedCarPark.name_chinese : selectedCarPark.name,
        leftComponent: (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={() => {
              dispatch(CommonActions.setSelectedLocation({}))
              dispatch(CommonActions.setBookingStep('select-location'))
            }}
          />
        )
      }}
    >
      <Row justify="center" gutter={[0, 20]}>
        <Col span={24}>
          <DatePicker setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div style={{backgroundColor: COLORS.gray, borderRadius: 10 }}>
            <Button
              onClick={() => {
                setSelectedType('AM')
              }}
              disabled={selectedDate.get('day') !== moment().get('day') ? false : moment().locale('en').format('A') !== 'AM'}
              style={{
                ...styles.timeButton,
                backgroundColor: selectedType === 'AM' ? COLORS.primary : COLORS.gray,
                color: selectedType === 'AM' ? COLORS.gray : COLORS.primary,
              }}
            >
              {t('am')}
            </Button>
            <Button
              onClick={() => setSelectedType('PM')}
              style={{
                ...styles.timeButton,
                backgroundColor: selectedType === 'PM' ? COLORS.primary : COLORS.gray,
                color: selectedType === 'PM' ? COLORS.gray : COLORS.primary,
              }}
            >
              {t('pm')}
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <Skeleton active round loading={loading}>
            <Timeslots
              bookingInfo={bookingInfo}
              selectedDate={selectedDate}
              selectedType={selectedType}
            />
          </Skeleton>
        </Col>
        <Col span={24}>
          <StatusIndicator />
        </Col>
      </Row>
    </AppLayout>
  )
}

const DatePicker = ({
  selectedDate,
  setSelectedDate
}) => {
  const getDate = () => {
    const dates = []

    const days = 5
    _.times(days, (item) => {
      let day = item;
      const target = moment().add(day, 'days').startOf('day');
      dates.push((
        <Col
          key={item}
          style={{
            cursor: 'pointer'
          }}
          onClick={() => setSelectedDate(target)}
        >
          <div style={{
            ...FONTS.tag,
            color: COLORS.primary,
            textAlign: 'center',
            marginBottom: 5,
          }}
          >
            {_.upperCase(target.format('ddd'))}
          </div>
          <div
            style={{
              backgroundColor: selectedDate.format('DD') === target.format('DD') ? COLORS.primary : COLORS.gray,
              color: selectedDate.format('DD') === target.format('DD') ? COLORS.gray : COLORS.primary,
              borderRadius: '100%',
              transition: 'all 0.2s ease-in'
            }}
          >
            <Row
              justify="center"
              align="middle"
              style={{
                height: 35, width: 35, fontSize: 14, fontWeight: '500'
              }}
            >
              <Col>
                <span>{target.format('DD')}</span>
              </Col>
            </Row>
          </div>
        </Col>
      ))
    })

    return dates
  }

  return (
    <Row justify="space-between">
      {getDate()}
    </Row>
  )
}

const Timeslots = ({
  bookingInfo,
  selectedDate,
  selectedType,
  loading
}) => {
  const dispatch = useDispatch()
  const [timeslotList, setTimeslotList] = useState({});


  useEffect(() => {
    if (_.isEmpty(bookingInfo)) return;
    let timesloTemplate = generateTimeslot(selectedDate)
    setTimeslotList(timesloTemplate)
  }, [bookingInfo])

  const generateTimeslot = (date) => {
    // get timeslot
    const timeslotArr = getBookingItemTimeslots(date)
    // generate full day timeslots
    // seperate timeslot in a.m & p.m.
    const timesloTemplate = getTimeslotTemplate(timeslotArr, date)
    return timesloTemplate
  }

  const getBookingItemTimeslots = (date) => {
    let timeslotArr = []
    let full_day_seconds = 24 * 60 * 60 // 24 hours
    let session = 15 * 60 // 15 mins

    const start_of_day = date
    let total_timeslot_per_day = full_day_seconds / session

    _.times(total_timeslot_per_day, (item) => {
      // first time slot
      if (_.isEmpty(timeslotArr)) {
        const bookingItem = bookingInfo[start_of_day.unix()]
        return timeslotArr.push({
          id: item + 1,
          start_time: start_of_day.unix(),
          section_duration: session,
          end_time: start_of_day.unix() + session - 1,
          pids: bookingItem ? bookingItem.pids : [],
          available: bookingItem ? bookingItem.available : 0,
          total: bookingItem ? bookingItem.total : 0,
        })
      }

      let last_time_slot = _.last(timeslotArr)
      const next_time_slot = last_time_slot.end_time + 1
      const bookingItem = bookingInfo[next_time_slot]

      timeslotArr.push({
        id: item + 1,
        start_time: next_time_slot,
        section_duration: session,
        end_time: next_time_slot + session - 1,
        pids: bookingItem ? bookingItem.pids : [],
        available: bookingItem ? bookingItem.available : 0,
        total: bookingItem ? bookingItem.total : 0,
      })
    })

    let updatedTimeslotArr = _.filter(timeslotArr, (item) => (item.end_time > moment().unix()))

    // fill the previous timeslots for UI purpose
    const firstTimeslot = _.first(updatedTimeslotArr)
    const previousArrLength = _.floor(moment.unix(firstTimeslot.start_time).get('minute') / 15, 0);
    if (previousArrLength === 0) return updatedTimeslotArr;

    const previousArr = _.slice(
      timeslotArr,
      firstTimeslot.id - previousArrLength - 1,
      firstTimeslot.id - 1
    );

    return [...previousArr, ...updatedTimeslotArr]
  }

  const getTimeslotTemplate = (timeslotArr, date) => {
    const afternoon = date.clone().add('12', 'hour').unix()

    const timeslot_template = {
      am: [],
      pm: []
    }
    _.map(timeslotArr, (item) => {
      if (item.start_time < afternoon) {
        return timeslot_template.am.push(item)
      }

      return timeslot_template.pm.push(item)
    })

    return timeslot_template
  }

  const getTimeslots = (item) => {
    if (item.available === 0 || loading) return;
    dispatch(CommonActions.setSelectedTimeSlot(item))
    dispatch(CommonActions.setSelectedPID(item.pidDetail))
    dispatch(CommonActions.setBookingStep('select-parking-slot'))
  }

  const getStatus = (item) => {
    if (item.available === 0 || item.total === 0) {
      return COLORS.dark
    }

    // available 90%
    if (_.round(_.divide(item.available, item.total), 2) >= 0.9) {
      return COLORS.primary
    }

    return COLORS.tretiary
  }

  const renderTimeslots = () => {
    let timeslots = []
    if (!timeslotList) return null
    if (selectedType === 'AM') {
      timeslots = timeslotList.am
    }

    if (selectedType === 'PM') {
      timeslots = timeslotList.pm
    }

    const elements = _.map(timeslots, (item) => {
      return (
        <Col span={6} key={item.id}>
          <Row justify="center">
            <Col
              style={{
                ...styles.timeslot,
                backgroundColor: getStatus(item),
              }}
              span={23}
              onClick={() => getTimeslots(item)}
            >
              {moment.unix(item.start_time).format('h:mm')}
            </Col>
          </Row>
        </Col>
      )
    })
    return elements
  }
  return (
    <Row align="middle" gutter={[0, 5]}>{renderTimeslots()}</Row>
  )
}

const StatusIndicator = () => {
  const { t } = useTranslation()
  return (
    <Row justify="space-between" align="middle" gutter={[0, 0]}>
      <Col span={8}>
        <Row align="middle" justify="center">
          <Col
            span={23}
            style={{
              ...FONTS.tag,
              height: 30,
              backgroundColor: COLORS.primary,
              color: COLORS.white,
              borderRadius: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('available')}
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row align="middle" justify="center">
          <Col
            span={23}
            style={{
              ...FONTS.tag,
              height: 30,
              backgroundColor: COLORS.dark,
              color: COLORS.white,
              borderRadius: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('full')}
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row align="middle" justify="center">
          <Col
            span={23}
            style={{
              ...FONTS.tag,
              height: 30,
              backgroundColor: COLORS.tretiary,
              color: COLORS.white,
              borderRadius: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('almost_full')}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const styles = {
  timeButton: {
    ...FONTS.body,
    height: 44,
    width: '50%',
    border: 'none',
    borderRadius: 10,
    backgroundColor: COLORS.gray,
    color: COLORS.primary,
  },
  timeslot: {
    ...FONTS.tag,
    backgroundColor: COLORS.primary,
    height: 40,
    borderRadius: 10,
    color: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}


export default BookingTimeSlot;

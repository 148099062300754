import React, { useEffect, useState, useRef } from 'react';
import {
  Row, Col, Button, Input, notification, Progress
} from 'antd';
import {
  CloseCircleFilled
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { setChargerStatus, setChargerStep, setSelectedPID } from '../../redux/actions/common'

import * as Service from '../../core/Service';
import AppLayout from '../AppLayout'
import StatusBar from './StatusBar'
import { WalkinChargingDetail } from '../ChargingDetail'
import { DefaultStyles } from '../../constants';

const ChargingStatus = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const origin = useSelector((state) => state.app.origin)
  const charger = useSelector((state) => state.charger)
  const [waiting, setWaiting] = useState(true);
  const [targetEnergy, setTargetEnergy] = useState(undefined);
  const { selectedCar, userChargeRecord } = charger;
  const dispatch = useDispatch()
  const { energy_to_charge, total_charged_energy } = userChargeRecord
  const intervalRef = useRef()

  useEffect(() => {
    // each request 10s
    setLoading(true)
    getChargingStatus()
    intervalRef.current = setInterval(() => {
      getChargingStatus()
    }, 1000 * 10)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])


  const getChargingStatus = async () => {
    try {
      const resp = await Service.call('get', '/api/web/user/charging?type=check');
      if (resp.status <= 0) {
        if (resp.errorCode !== 0) {
          dispatch(setChargerStep('charger-no-response'))
          return
        }
        // notification.info({
        //   message: `${resp.errorMessage} [${resp.errorCode}]`
        // })
        // return history.replace('/')
      }

      const { canStop, targetEnergy } = resp.data
      if (!_.isUndefined(targetEnergy)) {
        setTargetEnergy(targetEnergy)
      }

      if (_.isEmpty(resp.data)) {
        clearInterval(intervalRef.current)
        dispatch(setChargerStep('charging-stop-success'))
        return
      }

      if (canStop) {
        setWaiting(false)
        dispatch(setChargerStatus({
          selectedPID: resp.data.pid,
          selectedPlan: resp.data.plan,
          selectedCarPark: resp.data.car_park,
          selectedCoupon: resp.data.coupon,
          userChargeRecord: resp.data,
          selectedDiscount: resp.data.discount,
          startTime: resp.data.adjust_start_time,
          chargedTime: resp.data.chargedTime
        }))
    }

      if (resp.data?.pid?.OCPP_charger_status === 'Finishing') {
        setWaiting(false)
        if (resp.data?.status === 'Terminated') {
          dispatch(setChargerStep('charger-no-response'))
          return
        }
        dispatch(setChargerStep('charging-stop-success'))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AppLayout
      fullWidth
      headerOptions={{
        title: t('charging_status'),
        rightComponent: !waiting && 
        <CloseCircleFilled
          onClick={() => {
            history.replace('/')
          }}
          style={{ fontSize: 32, textAlign: 'left' }}
        />
      }}
    >
      <Row justify="center" gutter={[0, 20]}>
        <Col span={24}>
          <div
            style={{
              maxWidth: '100vw',
              width: '100%',
              paddingTop: '66.667%',
              backgroundImage: `url(${origin}/${selectedCar?.model?.charging_img_path || 'assets/charging-car.svg'})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
            alt="charging car"
          />
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 20]}>
        <Col span={22}>
          <StatusBar targetEnergy={targetEnergy} value={total_charged_energy} total={energy_to_charge} />
        </Col>
        {waiting ? (
          <div style={DefaultStyles.waitingNote}>
            <Col style={{ paddingBottom: 40 }}>
              {t("waiting_target_energy_desc", { targetEnergy })}
            </Col>
            <Col>{t("customer_usage_message", { targetEnergy })}</Col>
          </div>
        )
        : (
        <>
          <Col span={22}>
            <WalkinChargingDetail />
          </Col>

          <Col span={22}>
            <Button
              style={DefaultStyles.dangerButtonOutline}
              onClick={() => dispatch(setChargerStep('confirm-stop'))}
            >
              <Row
                justify="center"
                align="middle"
                gutter={[10, 0]}
                style={{
                  ...DefaultStyles.dangerButtonOutlineText,
                  height: '100%'
                }}
              >
                <Col>
                  <img src="/assets/no-plug.svg" alt="stop" style={{ width: 30, height: 30 }} />
                </Col>
                <Col>
                  {t('stop_charging')}
                </Col>
              </Row>
            </Button>

          </Col>
        </>)}
      </Row>
    </AppLayout>
  )
}

export default ChargingStatus;

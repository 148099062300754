import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button
} from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppLayout from './AppLayout'
import { setErrorMessage } from '../redux/actions/common';
import { DefaultStyles } from '../constants';

const FailPage = ({
  title = '',
  sub_title = ''
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const app = useSelector((state) => state.app)
  const [subTitle, setSubTitle] = useState(t('please_try_another_parking_slot'))
  const [errorText, setErrorText] = useState(app.errorMessage)
  const history = useHistory()

  useEffect(() => {
    getErrorMessage()
  }, [app.errorMessage])

  useEffect(() => {
    getErrorCode()
  }, [app.errorCode])

  const getErrorMessage = () => {
    console.log('getting error >>>');
    let linbreakText = _.split(app.errorMessage || title, '\n');
    if (linbreakText.length > 1) {
      setSubTitle('')
    }
    const errorTextList = _.map(linbreakText, (item) => {
      return <div key={item} style={{ marginBottom: 5 }}>{item}</div>
    })

    setErrorText(errorTextList)
  }

  const getErrorCode = () => {
    if (_.includes([-95025, -95027, -99003, -97007, -99102, -99999], app.errorCode)) {
      return setSubTitle(t('please_contact_customer_support'))
    }

    // default message
    setSubTitle(t('please_try_another_parking_slot'))
  }

  return (
    <AppLayout
      headerOptions={{
        hide: true
      }}
      containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Row justify="center" align="middle">
        <Col span={24}>
          <Row justify="center" gutter={[0, 20]}>
            <Col span={4}>
              <img src="/assets/warning.svg" alt="Warning" style={styles.image} />
            </Col>
            <Col
              span={22}
              style={{
                ...DefaultStyles.subHeader,
                textAlign: 'center'
              }}
            >
              {errorText}
            </Col>
          </Row>
          <Row justify="center" gutter={[0, 20]}>
            <Col
              span={24}
              style={{
                ...DefaultStyles.body,
                textAlign: 'center'
              }}
            >
              {subTitle}
            </Col>
            <Col span={24}>
              <Button
                style={DefaultStyles.button}
                onClick={() => {
                  dispatch(setErrorMessage(''))
                  history.replace('/')
                }}
              >
                <span style={DefaultStyles.buttonText}>{t('back_to_home')}</span>
              </Button>
            </Col>

          </Row>
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
}


export default FailPage;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { setLicense, setMyCarStep } from '../../redux/actions/common'
import * as Service from '../../core/Service';
import AppLayout from '../AppLayout'
import { COLORS, DefaultStyles, FONTS } from '../../constants';

const LicenceForm = ({
  setCallRefresh
}) => {
  const { t } = useTranslation()
  const car = useSelector((state) => state.car);
  const { license } = car;
  const [value, setValue] = useState(_.toUpper(license))
  const dispatch = useDispatch()


  const checkValidLicense = (string) => {
    let reg = /^[A-HJ-NPR-Z0-9 ]*$/;
    return reg.test(string);
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('car_plate_number'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            setCallRefresh(true)
            dispatch(setMyCarStep('car-list'))
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row justify="center" gutter={[0, 20]}>
        <Col span={24}>
          <Input
            maxLength={40}
            value={value}
            onChange={(e) => setValue(_.toUpper(e.target.value))}
            placeholder={t('enter_car_plate_no')}
            size="large"
            prefix={
              <img alt="" src="/assets/car-plate.svg" style={styles.carplateLogo} />
            }
            style={{ ...styles.input }}
          />
          {/* {t('licence_rule')} */}
        </Col>
        <Col span={24}>
          <Button
            style={{ ...styles.button }}
            onClick={() => {
              if (_.isEmpty(value)) {
                return notification.warning({
                  message: t('please_input_car_plate_number')
                })
              }
              const carPlateNo = _.trim(value)
              // if (carPlateNo.length > 8) {
              //   return notification.warning({
              //     message: t('max_length_exceed', { label: t('car_plate_number'), max: 8 })
              //   })
              // }
              const regex = /^[a-z\d\-_\s]+$/i
              if (!regex.test(carPlateNo)) {
                return notification.warning({
                  message: t('alphanum_required')
                })
              }
              // if (!checkValidLicense(carPlateNo)) {
              //   return notification.warning({
              //     message: t('licence_rule')
              //   })
              // }
              dispatch(setLicense(carPlateNo))
              dispatch(setMyCarStep('select-brand'))
            }}
          >
            <span style={{ ...styles.buttonText }}>{t('next')}</span>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: '#1A468F',
    borderColor: 'transparent',
    height: 50
  },
  buttonText: {
    ...FONTS.subHeader,
    color: COLORS.white,
  },
  input: {
    ...FONTS.body,
    backgroundColor: COLORS.gray,
    width: '100%',
    height: 64,
    borderRadius: 10,
    border: 'none',
  },
  carplateLogo: {
    width: 44,
    height: 44,
    marginRight: 5
  }
}


export default LicenceForm;

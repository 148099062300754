import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { Row, Col, Divider } from 'antd'
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import StatusMessages, {useMessages} from './StatusMessages';
import { DefaultStyles } from '../../constants';

const Wallet = (props) => {
  const { t } = useTranslation()
  const {
    price,
    clientSecret,
    onSuccessCallBack,
    setLoading,
    displayItems,
    checkOrderStatus
  } = props;
  const config = useSelector((state) => state.app.config);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [messages, addMessage] = useMessages();

  useEffect(() => {
    createPayment()
  }, [stripe, elements, addMessage]);

  const createPayment = async () => {
    setLoading(true)
    if (!stripe || !elements) {
      setLoading(false)
      return;
    }

    const pr = stripe.paymentRequest({
      country: config.COUNTRY,
      currency: _.toLower(config.CURRENCY),
      displayItems,
      total: {
        label: `HALO ENERGY LIMITED`,
        amount: price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });


    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    setLoading(false)
    pr.on('paymentmethod', async (e) => {
      // addMessage('Client secret returned');

      if (!clientSecret) {
        setLoading(false)
        return;
      }

      const isCancelled = await checkOrderStatus(true)
      if (isCancelled) {
        e.complete('fail')
        setLoading(false)
        return;
      }

      const {
        error: stripeError,
        paymentIntent: payload,
      } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: e.paymentMethod.id,
      }, { handleActions: false });


      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        addMessage(stripeError.message);
        e.complete('fail')
        setLoading(false)
        return;
      }

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      // addMessage(`Payment ${payload.status}: ${payload.id}`);

      if (onSuccessCallBack) {
        e.complete('success')
        onSuccessCallBack(payload);
      }
    });
  }

  return (
    <Row style={{ margin: '30px 0px' }}>
      <Col span={24}>
        {paymentRequest && (
          <>
            <PaymentRequestButtonElement options={{paymentRequest}} />
            <Divider style={{
              ...DefaultStyles.subBody,
              color: '#8792A2'
            }}
            >
              {t('or_pay_with_card')}
            </Divider>
          </>
        )}
        <StatusMessages messages={messages} />
      </Col>

    </Row>
  );
};

export default withRouter(Wallet);

import Immutable from 'seamless-immutable';
import * as types from '../actions/types';

const initialState = Immutable({
  step: 'car-list',
  selectedBrand: {},
  selectedModel: {},
  license: "",
});

const appReducer = {
  car: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_MY_CAR_INIT: {
        return {
          ...state,
          selectedBrand: {},
          selectedModel: {},
          license: "",
        };
      }
      case types.SET_MY_CAR_STEP: {
        return {...state, step: action.data};
      }
      case types.SET_SELECTED_BRAND: {
        return {...state, selectedBrand: action.data};
      }
      case types.SET_SELECTED_MODEL: {
        return {...state, selectedModel: action.data};
      }
      case types.SET_LICENSE: {
        return {...state, license: action.data};
      }
      default:
        return state;
    }
  }
};

export default appReducer;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import { PlanCard } from '../charger/Plan';

import {
  setChargerInit,
  setModal, setMonthlyStep, setOrder, setSelectedCarPark, setSelectedPID, setSelectedPlan
} from '../../redux/actions/common';
import { COLORS, DefaultStyles } from '../../constants';
import { useTranslation } from 'react-i18next';

const MonthlyPlan = ({
  setLoading,
}) => {
  const { t, i18n } = useTranslation()
  const site_meta = useSelector((state) => state.app.site_meta)
  const charger = useSelector((state) => state.charger)
  const monthly = useSelector((state) => state.monthly)
  const { selectedPlan, selectedCarPark, selectedPID } = charger;
  const { selectedDate } = monthly;
  const dispatch = useDispatch();

  const [plan, setPlan] = useState({ id: 0 });
  const [planList, setPlanList] = useState([]);

  useEffect(() => {
    getCarPark()
  }, [selectedCarPark])

  const getCarPark = async() => {
    setLoading(true)
    let dataSource = []
    try {
      const resp = await Service.call('get', `/api/web/car_park/monthly/${selectedCarPark.id}/${selectedDate}`)

      if (resp.status <= 0) {
        setLoading(false)
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }
      dataSource = resp.data.plans
      dispatch(setSelectedPID(resp.data.pidDetail))
      setPlanList(dataSource)
      if (!_.isEmpty(dataSource)) {
        setPlan(_.first(dataSource).planDetail)
      }
      if (!_.isEmpty(selectedPlan)) {
        setPlan(selectedPlan)
      }

    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setPlanList(dataSource);
    }
    setLoading(false)
  }

  const bookCharger = () => {
    dispatch(setOrder({}))
    dispatch(setSelectedPlan(plan))
    dispatch(setMonthlyStep('payment-detail'))
  }

  const getMoreInfo = () => {
    dispatch(setModal({
      title: t('more_information'),
      visible: true,
      content: site_meta?.static_monthly_plan_more_content.meta_value,
    }))
  }


  return (
    <AppLayout
      headerOptions={{
        title: selectedCarPark.name,
        leftComponent: (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={() => {
              dispatch(setChargerInit())
              dispatch(setMonthlyStep('select-location'))
            }}
          />
        )
      }}
    >
      <Row justify="center" gutter={[20, 0]}>
        <Col span={24}>
          <p style={{ ...DefaultStyles.subBody, marginBottom: 15 }}>{t('start_date')}</p>
          <p style={DefaultStyles.header}>
            {moment.unix(selectedDate).format('YYYY-MM-DD')}
          </p>
        </Col>
      </Row>
      <Row justify="center" gutter={[20, 0]}>
        <Col span={24}>
          <p
            style={{ ...DefaultStyles.subBody, marginBottom: 15 }}
          >
            {t('parking_slot_automatic_arrangement')}
          </p>
          <div style={styles.slotInfoCard}>
            <span style={DefaultStyles.header}>
              {selectedPID.parking_lot}
            </span>
          </div>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={24}>
          <p style={{ ...DefaultStyles.subBody, marginBottom: 15 }}>{t('plan')}</p>
        </Col>
        <Col span={24} gutter={[0, 20]}>
          <Row>
            {_.map(_.orderBy(planList, ['price'], ['asc']), (item) => {
              return (
                <Col
                  span={24}
                  key={item.planDetail.id}
                  onClick={() => setPlan(item.planDetail)}
                >
                  <PlanCard
                    item={item.planDetail}
                    selectedPlan={plan}
                    lang={i18n.language}
                  />
                </Col>
              )
            })}
          </Row>

        </Col>
      </Row>
      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Link to="/subscription">
            <span style={DefaultStyles.subBody}>
              *{t('the_second_month_can_be_booked_in_15_days')}.
            </span>
            {/* <span
              role="button"
              tabIndex={0}
              style={{ color: COLORS.tretiary }}
              onMouseDown={getMoreInfo}
            >
              {t('more')}
            </span> */}
          </Link>
        </Col>
      </Row>
      <Button
        style={{ ...DefaultStyles.button, opacity: plan.id ? 1 : 0.8 }}
        disabled={!plan.id}
        onClick={bookCharger}
      >
        <span style={{ ...DefaultStyles.buttonText }}>{t('book_charger')}</span>
      </Button>
    </AppLayout>
  )
}

const styles = {
  slotInfoCard: {
    color: COLORS.primary,
    backgroundColor: COLORS.light,
    height: 60,
    borderRadius: 10,
    fontSize: 24,
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: "60px",
    marginBottom: 30
  },
}

export default MonthlyPlan;

import React, { useEffect } from 'react';
import {
  Button, Col, Input, Row, Select, Form, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  setAuth, setDefaultCar, setLanguage, setUser
} from '../redux/actions/common'
import * as Service from '../core/Service';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';

const { Option } = Select;

function Setting() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const config = useSelector((state) => state.app.config);

  const logout = async () => {
    await Service.call('post', '/api/auth/web/logout');
    dispatch(setUser({}));
    dispatch(setDefaultCar({ id: 0 }));
    dispatch(setAuth(false));
    dispatch(setLanguage('en'))
    localStorage.setItem('language', 'en')
    i18n.changeLanguage('en');
    history.push('/login')
  }

  const changeLang = async (lang) => {
    let editResult = await Service.call('put', '/api/web/user', {preferred_lang: lang});
  }

  const LanguageSelect = () => {
    let LanguageList = {
      en: "English",
      zh_hant: "中文（繁體）"
    }

    return (
      <Select
        bordered={false}
        value={i18n.language}
        style={{ width: 130, color: '#000' }}
        onChange={(value) => {
          i18n.changeLanguage(value)
          dispatch(setLanguage(value))
          storeLang(value)
          changeLang(value)
        }}
      >
        {_.map(LanguageList, (val, key) => {
          return (
            <Option key={key} value={key}>
              {val}
            </Option>
          );
        })}
      </Select>
    );
  };

  const storeLang = (value) => {
    // if (_.isEmpty(localStorage)) return;
    localStorage.setItem('language', value)
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('setting'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.push('/');
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row style={{ minHeight: '65vh' }}>
        <Col span={24}>

          <Link to="/about-us" style={{ color: "#3B3B3C" }}>

            <Row justify="space-between" gutter={[0, 20]}>
              <Col style={styles.label}>
                {t('general')}
              </Col>
              <Col>
                <img alt="" src="/assets/right-arrow.svg" />
              </Col>
            </Row>
          </Link>
          <Link to="/account" style={{ color: "#3B3B3C" }}>
            <Row justify="space-between" gutter={[0, 20]}>
              <Col style={styles.label}>
                {t('account')}
              </Col>
              <Col>
                <img alt="" src="/assets/right-arrow.svg" />
              </Col>
            </Row>
          </Link>

          <Link to="/enquired-list" style={{ color: "#3B3B3C" }}>
            <Row justify="space-between" gutter={[0, 20]}>
              <Col style={styles.label}>
                {t('enquired_list')}
              </Col>
              <Col>
                <img alt="" src="/assets/right-arrow.svg" />
              </Col>
            </Row>
          </Link>
          <Link to="/faq" style={{ color: "#3B3B3C" }}>
            <Row justify="space-between" gutter={[0, 20]}>
              <Col style={styles.label}>
                {t('faq')}
              </Col>
              <Col>
                <img alt="" src="/assets/right-arrow.svg" />
              </Col>
            </Row>
          </Link>
          <Link to="/terms-and-conditions" style={{ color: "#3B3B3C" }}>
            <Row justify="space-between" gutter={[0, 20]}>
              <Col style={styles.label}>
                {t('terms_and_conditions')}
              </Col>
              <Col>
                <img alt="" src="/assets/right-arrow.svg" />
              </Col>
            </Row>
          </Link>
          <Link to="/privacy" style={{ color: "#3B3B3C" }}>
            <Row justify="space-between" gutter={[0, 20]}>
              <Col style={styles.label}>
                {t('privacy')}
              </Col>
              <Col>
                <img alt="" src="/assets/right-arrow.svg" />
              </Col>
            </Row>
          </Link>
          <Row justify="space-between" gutter={[0, 20]} style={{ color: "#3B3B3C" }}>
            <Col style={styles.label}>
              {t('change_language')}
            </Col>
            <Col>
              <div style={{ textAlign: 'center' }}>{LanguageSelect()}</div>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[0, 20]} style={{ color: "#3B3B3C" }}>
            <Col style={styles.label}>
              {t('version')}
            </Col>
            <Col>
              {config.version}
            </Col>
          </Row>
        </Col>
      </Row>
      <Button
        onClick={logout}
        style={styles.button}
      >
        {t('logout')}
      </Button>
    </AppLayout>
  );
}

const styles = {
  button: {
    backgroundColor: COLORS.error,
    color: COLORS.white,
    width: '100%',
    height: 54,
    borderRadius: 10,
    border: 'none',
    fontWeight: '600',
    fontSize: 20
  },
  label: {
    ...FONTS.body,
    color: COLORS.dark
  }
}

export default Setting;

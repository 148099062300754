import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification, Divider, Spin
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  setSelectedDiscount
} from '../redux/actions/common'

import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from './AppLayout'
import { COLORS, DefaultStyles } from '../constants';
import PenaltyDetail from './PenaltyDetail';
import useFreeCheckout from '../hooks/useFreeCheckout';
import PaymentSelection from './PaymentSelection';

const PaymentDetail = ({
  // setLoading = false,
  chargingDetail = null,
  selectCoupon = () => {},
  onCheckout = () => {},
  goBack = () => {},
  submitLoading = false
}) => {
  const [loading, setLoading] = useState(submitLoading)
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState(null)
  const { t } = useTranslation()
  const charger = useSelector((state) => state.charger)
  const orders = useSelector((state) => state.orders)
  const app = useSelector((state) => state.app)
  const { order } = orders;
  const {
    selectedPlan, selectedCoupon, selectedPID, selectedDiscount, selectedCar
  } = charger
  const dispatch = useDispatch()

  const [penaltyList, setPenaltyList] = useState([])

  // handle free check hook
  useFreeCheckout(setLoading);

  useEffect(() => {
    getPenaltyList()
  }, [])

  const getPenaltyList = async () => {
    // setLoading(true)
    const resp = await Service.call('get', `/api/web/user/penalty`)
    // setLoading(false)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }
    setPenaltyList(resp.data)
  }

  const getFinalPrice = () => {
    let final_price = selectedPlan.price
    if (selectedDiscount !== 0) {
      final_price -= selectedDiscount
    }

    if (!_.isEmpty(selectedCoupon)) {
      if (final_price >= selectedCoupon.discount) {
        final_price -= selectedCoupon.discount
      } else {
        final_price = 0
      }
    }

    if (!_.isEmpty(penaltyList)) {
      final_price += _.sumBy(penaltyList, 'penalty_amount')
    }

    if (final_price < 4) return 0

    return final_price
  }

  const renderCouponDetail = () => {
    if (selectedCoupon.type !== 'topup') {
      return (
        <Row justify="space-between" style={DefaultStyles.couponText}>
          <Col>{selectedCoupon.code}</Col>
          <Col>
            -HK$
            {selectedCoupon.discount}
          </Col>
        </Row>
      )
    }

    // calculate the usage disocunt amount
    let usageAmount = selectedPlan.price
    if (selectedDiscount !== 0) {
      usageAmount -= selectedDiscount
    }

    // display the rest of the coupon
    if (usageAmount >= selectedCoupon.discount) {
      usageAmount = selectedCoupon.discount
    }

    // caluclate the remaining amount
    let remaining = selectedCoupon.discount - usageAmount
    if (usageAmount > selectedCoupon.discount || remaining < 0) {
      remaining = 0
    }
    return (
      <>
        <Row justify="space-between" style={{...DefaultStyles.couponText, fontWeight: '500', marginBottom: 10}}>
          <Col>{t('topup_coupon_amount')}</Col>
          <Col>
            HK$
            {selectedCoupon.discount}
          </Col>
        </Row>
        <Row justify="space-between" style={{...DefaultStyles.couponText, fontWeight: '500', marginBottom: 10}}>
          <Col>{t('usage_amount')}</Col>
          <Col>
            - HK$
            {usageAmount}
          </Col>
        </Row>
        <Divider style={{ borderTopColor: COLORS.primary }} />
        <Row justify="space-between" style={{...DefaultStyles.couponText, fontWeight: '500', marginBottom: 10}}>
          <Col>{t('coupon_balance')}</Col>
          <Col>
            HK$
            {remaining}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('payment'),
        leftComponent: <LeftCircleFilled
          onClick={goBack}
          style={DefaultStyles.leftArrow}
        />
      }}
      fullWidth
    >
      <Spin spinning={loading || submitLoading}>
      <div
        className="disable-scrollbar"
        style={{
          overflow: 'scroll', padding: '10px 3% 140px 3%'
        }}
      >
        <Row justify="center" gutter={[0, 20]}>
          {/* Charing Detail Card */}
          <Col span={24}>
            {chargingDetail}
          </Col>

          {/* Penalty Detail Card */}
          {!_.isEmpty(penaltyList)
          && (
          <Col span={24}>
            <PenaltyDetail penaltyList={penaltyList} />
          </Col>
          )}

          {(getFinalPrice() > 0 || !_.isEmpty(selectedCoupon)) && (
          <Col span={24}>
            <Button
              style={DefaultStyles.buttonOutline}
              type="text"
              onClick={selectCoupon}
            >
              <Row
                justify="center"
                align="middle"
                gutter={[10, 0]}
                style={{...DefaultStyles.buttonOutlineText, height: '100%'}}
              >
                <Col>
                  <img src="/assets/coupon.svg" alt="" style={{ width: 30, height: 30 }} />
                </Col>
                <Col>
                  {t('coupon_selection')}
                </Col>
              </Row>
            </Button>
          </Col>
          )}
        </Row>
        {!_.isEmpty(selectedCoupon)
          ? renderCouponDetail() : null}
        <PaymentSelection value={paymentMethod} onChange={setPaymentMethod} />
      </div>
      </Spin>
      <Row
        justify="center"
        style={DefaultStyles.paymentStickyCard}
      >
        <Col span={24}>
          <Row
            justify="space-between"
            align="middle"
            style={{...DefaultStyles.subHeader, minHeight: 60, marginBottom: 10 }}
          >
            <Col style={{ color: COLORS.darkGrey, fontWeight: '600' }}>{t('total')}</Col>
            <Col style={{ fontWeight: '600' }}>
              HK$
              {_.round(getFinalPrice(), 2).toFixed(2)}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Button
            style={{...DefaultStyles.button, ..._.isNil(paymentMethod) ? {backgroundColor: COLORS.secondary} : {}}}
            onClick={() => onCheckout(paymentMethod)}
            loading={loading}
            disabled={loading || _.isNil(paymentMethod)}
          >
            <span style={DefaultStyles.buttonText}>{t('next')}</span>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default PaymentDetail;

import React, { useEffect, useState } from 'react';
import {
  Modal,
  notification
} from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import {
  setBookingStep, setOrder, setSelectedCar, setSelectedCoupon, setSelectedParkingSlot, setSelectedDiscount
} from '../../redux/actions/common'
import { BookingChargingDetail } from '../ChargingDetail'
import PaymentDetail from '../PaymentDetail'

import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import orderChecking, { runSavedPaymentMethodStripe } from '../../core/api/order';

const BookingPaymentDetail = ({ setLoading }) => {
  const history = useHistory()
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false)
  const charger = useSelector((state) => state.charger)
  const booking = useSelector((state) => state.booking)
  const orders = useSelector((state) => state.orders)
  const { selectedParkingSlot } = booking
  const {
    selectedPlan, selectedCoupon, selectedCar, selectedCarPark
  } = charger
  const dispatch = useDispatch()


  useEffect(() => {
    if (!_.isEmpty(orders.order)) return;
    getDiscount()
  }, [selectedPlan, selectedCarPark])


  const getDiscount = async () => {
    setLoading(true)
    const resp = await Service.call('get', `/api/web/discount/${selectedPlan.id}/${selectedCarPark.id}`)
    setLoading(false)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    dispatch(setSelectedDiscount(resp.data.discount))
  }

  const onCheckout = async (paymentMethod) => {
    if (!_.includes(['credit_card'], paymentMethod) && _.startsWith("pm_", paymentMethod)) {
      return notification.error({
        message: t('invalid_payment_method'),
        duration: 0
      })
    }
    setSubmitLoading(true)
    let resp = await Service.call('post', `/api/web/order/order_info`, {
      promo_code_id: selectedCoupon.id,
      plan_id: selectedPlan.id,
      pid: selectedParkingSlot.pid,
      booking_items: selectedParkingSlot.booking_items
    });
    setSubmitLoading(false)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    dispatch(setOrder(resp.data))
    if (_.startsWith(paymentMethod, "pm_")) {
      setSubmitLoading(true)
      if (resp.data.final_price > 0) {
        const paymentResp = await runSavedPaymentMethodStripe({
          order_key: resp.data.order_key,
          paymentMethodID: paymentMethod,
        });
        setSubmitLoading(false)
        const {
          status: paymentRespStatus = 0,
          errorMessage = t('failed_to_connect_to_the_payment_channel_please_try_again_later'),
        } = paymentResp;
        if (paymentRespStatus <= 0) {
          Modal.error({
            title: t('payment_fail'),
            content: (
              <div>
                {errorMessage}
              </div>
            )
          });
          return;
        }
      }
      setSubmitLoading(true)
      const returnPath = await orderChecking(resp.data.order_key, selectedParkingSlot.pid);
      history.replace(returnPath);
      setSubmitLoading(false)
      return;
    }


    if (resp.data.final_price > 0) {
      return history.replace(`/charger/payment?order_key=${resp.data.order_key}`)
    }
  };


  const releaseSelectedTimeslots = async () => {
    const bookingIds = _.map(booking.selectedParkingSlot.booking_items, 'booking_id');
    const resp = await Service.call('post',
      `/api/web/booking_info/release/${booking.selectedParkingSlot.pid}`, {
        booking_items: bookingIds
      })
    dispatch(setSelectedCar({}))
    dispatch(setSelectedParkingSlot({}))
    dispatch(setBookingStep('select-parking-slot'))
  }


  return (
    <PaymentDetail
      setLoading={setLoading}
      chargingDetail={<BookingChargingDetail mode="payment" />}
      selectCoupon={() => dispatch(setBookingStep('select-coupon'))}
      onCheckout={onCheckout}
      goBack={async () => {
        dispatch(setSelectedCoupon({}))
        dispatch(setBookingStep('select-parking-slot'))
        await releaseSelectedTimeslots()
      }}
      submitLoading={submitLoading}
    />
  )
}

export default BookingPaymentDetail;

import React, { useEffect } from 'react';
import {
  Button, Col, Input, Row, Select, Form, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setAuth, setDefaultCar, setSiteMeta, setURLParams, setUser, setLanguage } from '../redux/actions/common'
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout'
import { COLORS, DefaultStyles, FONTS } from '../constants';

const { Option } = Select;

const Login = () => {
  const { t, i18n } = useTranslation();
  const app = useSelector((state) => state.app)
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const { config = {} } = app;
  const {
    COUNTRY = "",
  } = config;

  useEffect(() => {
    if (!_.isEmpty(app.user) && app.user.uid > 0) {
      history.push('/')
    }
  }, [])

  const onFinish = async (formData) => {
    let { phonePrefix, phone, password } = formData;
    const dataObj = {
      phone: _.join([phonePrefix, phone], '-'),
      password
    }
    let resp = await Service.call('post', `/api/auth/web`, dataObj);

    console.log("Resp >>>", resp);

    if (resp.errorMessage) {
      dispatch(setAuth(false));
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    resp = await Service.call('get', '/api/web/site_meta');
    if (resp && resp.status > 0) {
      dispatch(setSiteMeta(_.keyBy(resp.data, 'meta_key')));
    } 

    resp = await Service.call('get', `/api/web/user`);
    if (resp.errorMessage) {
      notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
      return dispatch(setAuth(false));
    }
    if (_.isEmpty(resp.data)) return dispatch(setAuth(false));
  
    i18n.changeLanguage(resp.data.preferred_lang);
    dispatch(setLanguage(resp.data.preferred_lang));
    localStorage.setItem('language', resp.data.preferred_lang);
    dispatch(setUser(resp.data));
    dispatch(setDefaultCar(resp?.data?.userEv ?? { id: 0 }));
    dispatch(setAuth(true));

    if (app.url_params.pid) {
      history.push(`/charger?pid=${app.url_params.pid}`)
      dispatch(setURLParams({}))
      return
    }
    history.replace('/')
  }

  let defaultPhonePrefix = "+852";
  if (COUNTRY === "AU") {
    defaultPhonePrefix = "+61";
  }

  const prefixSelector = (
    <Form.Item
      style={{ }}
      name="phonePrefix"
      initialValue={defaultPhonePrefix}
      noStyle
    >
      <Select size="large" disabled bordered={false} style={{ ...DefaultStyles.body, width: '100%' }}>
        <Option value={defaultPhonePrefix}>{defaultPhonePrefix}</Option>
      </Select>
    </Form.Item>
  );

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: 'Login'
      }}
    >
      <Row justify="center">
        <Col span={16} style={{ margin: '30px 0px 40px 0px' }}>
          <div style={{ height: 150 }}><img alt="" src="/assets/halo_logo.png" style={{ height: '100%', width: '100%', objectFit: 'contain'}} /></div>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          span={24}
        >
          <Form
            className="login-form"
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="phone"
              initialValue=""
              rules={[{ required: true, message: t('required') }]}
            >
              <Input
                style={DefaultStyles.formInput}
                size="large"
                prefix={(
                  <Row align="middle">
                    <Col>
                      <img alt="" src="/assets/id-card.svg" style={DefaultStyles.fieldIcon} />
                    </Col>
                    <Col>
                      {prefixSelector}
                    </Col>
                  </Row>
                    )}

                placeholder={t('phone')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('required') }]}
            >
              <Input.Password
                style={DefaultStyles.formInput}
                size="large"
                prefix={<img alt="" src="/assets/password.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('password')}
              />
            </Form.Item>
            <div style={{ margin: '30px 0px', textAlign: 'center' }}>
              <Link to="/forget-password">
                <span style={DefaultStyles.body}>
                  {t('forgot_password')}
                  ?
                </span>

              </Link>
            </div>
            <div style={{ margin: '30px 0px' }}>
              <Button
                style={DefaultStyles.button}
                type="primary"
                htmlType="submit"
              >
                <span style={DefaultStyles.buttonText}>{t('login')}</span>
              </Button>
            </div>
          </Form>
          <div style={{ textAlign: 'center' }}>
            <Link to="/registration">
              <span style={DefaultStyles.body}>
                {t('create_new_account')}
              </span>
            </Link>
          </div>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default Login;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { setSelectedBrand, setMyCarStep } from '../../redux/actions/common'
import * as Service from '../../core/Service';
import AppLayout from '../AppLayout'
import { COLORS, DefaultStyles } from '../../constants';

const BrandForm = ({
  setLoading
}) => {
  const { t } = useTranslation()
  const app = useSelector((state) => state.app)
  const car = useSelector((state) => state.car)
  const dispatch = useDispatch()
  const { selectedBrand } = car;
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getDataList()
  }, [])

  const getDataList = async() => {
    setLoading(true)
    let dataSource = []
    try {
      const resp = await Service.call('get', "/api/web/ev_brand");
      dataSource = _.orderBy(resp.data, ["sorting", "name"], ["asc", "asc"])
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(dataSource);
    }
    setLoading(false)
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('select_brand'),
        leftComponent: (
          <LeftCircleFilled
            onClick={() => dispatch(setMyCarStep('licence-form'))}
            style={DefaultStyles.leftArrow}
          />
        )
      }}
    >
      <Row justify="start" align="middle" gutter={[0, 20]}>
        {_.map(dataList, (item) => {
          return (
            <Col
              span={8}
              key={item.id}
              onClick={() => {
                dispatch(setSelectedBrand(item))
                dispatch(setMyCarStep('select-model'))
              }}
            >
              <Row justify="center">
                <Col span={22}>
                  <div style={{ ...styles.card, backgroundColor: item.id === selectedBrand.id ? '#D7D7D7' : '#F5F5F5' }}>
                    <img
                      alt={item.name}
                      style={styles.brandLogo}
                      src={item.path
                        ? `${app.origin}/${item.path}` : '/assets/default.png'}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          )
        })}
      </Row>
    </AppLayout>
  )
}

const styles = {
  card: {
    textAlign: 'center',
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    padding: 5
  },
  brandLogo: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '1/1'
  }
}


export default BrandForm;

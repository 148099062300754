import React, { useEffect, useState, useRef } from 'react';
import {
  Row, Col, Button, notification
} from 'antd';
import _ from 'lodash'
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import {
  setChargerStep, setErrorCode, setErrorMessage, setSelectedCarPark, setSelectedPID, setSelectedPlan, setUserChargeRecord
} from '../../redux/actions/common';
import { COLORS, DefaultStyles, FONTS } from '../../constants';
import CarItem from '../my-car/CarItem';

const PlanDetection = ({ setLoading, loading, setLoadingMessage }) => {
  const { t } = useTranslation()
  const started = useRef(false)
  const timerRef = useRef()
  const intervalRef = useRef()
  const history = useHistory()
  const dispatch = useDispatch()
  const charger = useSelector((state) => state.charger)
  const [countdown, setCountdown] = useState(10);
  const {
    selectedPID, selectedPlan, userChargeRecord, selectedCar
  } = charger
  const userChargeRecordRef = useRef()
  const fetchingRef = useRef(false);

  const getCategoryName = () => {
    if (selectedPID.plan_category === 'Monthly') return t('monthly_plan')
    if (selectedPID.plan_category === 'UltraFast') return t('ultra_fast')
    if (selectedPID.plan_category === 'DayNight') return t('day_night')
    if (selectedPID.plan_category === 'HourlyNight') return t('hourly_night')

    return ''
  }

  useEffect(() => {
    triggerTimer()
    return () => clearTimeout(timerRef.current)
  }, [countdown]);

  const triggerTimer = () => {
    timerRef.current = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);

    if (!countdown) {
      startCharge()
    }
  }

  useEffect(() => {
    // request by 3s
    intervalRef.current = setInterval(detectChargingStatus, 3000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  const startCharge = async () => {
    clearTimeout(timerRef.current);
    setLoading(true)
    setLoadingMessage(t('fetching_charger_information'))

    const resp = await Service.call('post', `/api/web/start_charge/${selectedPID.pid}`,
      {
        selectedCar,
        selectedPlan
      })

    if (resp.errorMessage) {
      setLoadingMessage('')
      setLoading(false)
      clearInterval(intervalRef.current)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      return
      // return notification.error({
      //   message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
      // });
    }
    dispatch(setSelectedPID(resp.data.pid))
    dispatch(setSelectedPlan(resp.data.plan))
    dispatch(setSelectedCarPark(resp.data.carPark))
    dispatch(setUserChargeRecord(resp.data.userCharge))
    started.current = true
    userChargeRecordRef.current = resp.data.userCharge
  }

  const detectChargingStatus = async () => {
    if (!started.current) return;
    if (_.isEmpty(userChargeRecordRef.current)) return;
    if (fetchingRef.current) return;
    fetchingRef.current = true
    const resp = await Service.call('get', `/api/web/pid/start/${userChargeRecordRef.current.pid}/${userChargeRecordRef.current.id}`)
    if (resp.errorMessage) {
      setLoading(false)
      setLoadingMessage(null)
      clearInterval(intervalRef.current)
      started.current = false
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      if (resp.errorCode === -93003) {
        dispatch(setChargerStep('charger-no-response'))
      } else {
        dispatch(setChargerStep('charger-fail'))
      }
      return
    }

    const pid = resp.data
    if (pid.redirect) {
      setLoadingMessage(null)
      setLoading(false)
      clearInterval(intervalRef.current)
      started.current = false
      return dispatch(setChargerStep('charging-status'))
    }
    fetchingRef.current = false
  }

  return (
    <AppLayout
      headerOptions={{
        title: '',
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.replace('/')
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row style={{ minHeight: '80vh' }} align="top" justify="center">
        <Col>
          <Row style={{ textAlign: 'center' }} gutter={[0, 0]}>
            <Col span={24} style={styles.header}>
              {`${getCategoryName()} ${t('confirmed')}`}
            </Col>
            <Col span={24} style={{ marginBottom: 20 }}>
              <CarItem
                onClickCar={() => {
                  dispatch(setSelectedPlan({}))
                  dispatch(setChargerStep('select-car'))
                }}
                item={selectedCar}
                mode="charger"
                containerStyle={{
                  backgroundColor: '#fff',
                  border: '2px solid #F0F0F0',
                  boxShadow: '0px 3px 5px rgb(0 0 0 / 10%)'
                }}
              />
            </Col>
            <Col span={24} style={styles.planCard}>
              <Row align="middle" style={{ padding: '5px 0px'}}>
                <Col span={24} style={styles.subHeader}>{selectedPID.pid}</Col>
                <Col span={24} style={styles.subHeader}>{selectedPlan.name}</Col>
                <Col
                  span={24}
                  style={{
                    ...styles.subBody,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <img src="/assets/battery.svg" alt="battery" style={{ width: 25, height: 25 }} />
                  {selectedPlan.charger_type}
                  {selectedPlan.kwh}
                  kWh
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ margin: '30px 0px'}}>
              <span style={{ ...styles.subBody, textAlign: 'center' }}>
                {(selectedPID.plan_category === 'HourlyNight' || selectedPID.plan_category === 'DayNight')
                 && (
                 <p>
                   *
                   {t('charger_expire_message')}
                 </p>
                 )}
                {t('auto_start_message')}
              </span>
            </Col>
            <Col span={24}>
              <Button
                disabled={loading || started.current || !countdown}
                style={{ ...styles.button }}
                onClick={startCharge}
              >
                <span style={DefaultStyles.buttonText}>{`${t('start_charging')} (${countdown})`}</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  header: {
    ...FONTS.header,
    color: COLORS.primary,
    textAlign: 'center',
    marginBottom: 30
  },
  subHeader: {
    ...FONTS.subHeader,
    color: COLORS.primary,
    textAlign: 'start'
  },
  body: {
    ...FONTS.body,
    color: COLORS.primary
  },
  subBody: {
    ...FONTS.body,
    color: COLORS.primary,
    textAlign: 'start'
  },
  planCard: {
    backgroundColor: COLORS.light,
    borderRadius: 10,
    padding: '10px 15px'
  },
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.primary,
    borderColor: 'transparent',
    height: 54
  },
  buttonText: {
    ...FONTS.subHeader,
    color: COLORS.white,
  }
}


export default PlanDetection;

import Immutable from 'seamless-immutable';
import * as types from '../actions/types';

const initialState = Immutable({
  step: 'my-booking',
  selectedBooking: {},
  selectedLocation: {},
  selectedTimeSlot: {},
  selectedParkingSlot: {},
});

const appReducer = {
  booking: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_BOOKING_INIT: {
        return {
          ...state,
          selectedBooking: {},
          selectedLocation: {},
          selectedTimeSlot: {},
          selectedParkingSlot: {},
        };
      }
      case types.SET_BOOKING_STEP: {
        return {...state, step: action.data};
      }
      case types.SET_SELECTED_BOOKING: {
        return {...state, selectedBooking: action.data};
      }
      case types.SET_SELECTED_LOCATION: {
        return {...state, selectedLocation: action.data};
      }
      case types.SET_SELECTED_PARKING_SLOT: {
        return {...state, selectedParkingSlot: action.data};
      }
      case types.SET_SELECTED_TIMESLOT: {
        return {...state, selectedTimeSlot: action.data};
      }
      default:
        return state;
    }
  }
};

export default appReducer;

import React from 'react';
import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";
import AppContainer from './AppContainer';
import './style.css';

const store = configureStore();

const App = (props) => {
  return (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
}

export default App;

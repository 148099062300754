import React, { useEffect } from 'react';
import {
  notification
} from 'antd';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import {
  setBookingStep,
  setChargerStep,
  setErrorCode,
  setErrorMessage,
  setMonthlyStep,
  setSelectedCarPark,
  setSelectedPID,
  setSelectedPlan,
  setUserChargeRecord
} from '../redux/actions/common'

import * as Service from '../core/Service';
import * as Main from '../core/Main';

const useFreeCheckout = (setLoading) => {
  const history = useHistory();
  const charger = useSelector((state) => state.charger)
  const orders = useSelector((state) => state.orders)
  const { order } = orders;
  const {
    selectedPlan, selectedCar
  } = charger
  const dispatch = useDispatch()


  useEffect(() => {
    if (!order) return;
    if (order.final_price !== 0) return;
    checkFreeOrderStatus();
  }, [order])


  const checkFreeOrderStatus = async () => {
    setLoading(true)
    await freeCheckout();
    const resp = await Service.call('get', `/api/web/order/order_info/${order.order_key}`)

    if (resp.errorMessage) {
      setLoading(false)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }

    switch (resp.data.order.status) {
      // keep track of order status -> success response
      case 'payment_hold':
      case 'payment_confirmed': {
        await success();
        break;
      }
      // keep track of order status -> fail/cancelled response
      // redirect to charger fail page
      case 'payment_failed':
      case 'cancelled': {
        fail();
        break;
      }
      default: break;
    }
  }

  const freeCheckout = async () => {
    const resp = await Service.call('put', '/api/web/payment/free', { order_key: order.order_key })
    if (resp.status <= 0) {
      setLoading(false)
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }
  }

  const success = async (payload) => {
    const resp = await Service.call('get', `/api/web/pid/${order.pid}`)
    if (resp.errorMessage) {
      setLoading(false)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }
    dispatch(setSelectedPID(resp.data))


    switch (resp.data.plan_category) {
      // walkin
      case "HourlyNight":
      case "DayNight": {
        await startCharge()
        break;
      }
      case "Monthly": {
        dispatch(setMonthlyStep('payment-success'))
        history.replace('/subscription')
        break;
      }
      case "UltraFast": {
        dispatch(setBookingStep('payment-success'))
        history.replace('/booking')
        break;
      }
      default: break;
    }
  }


  const startCharge = async () => {
    const dataObj = {
      selectedCar,
      selectedPlan,
      order_key: _.isEmpty(order) ? '' : order.order_key
    }
    const resp = await Service.call('post', `/api/web/start_charge/${order.pid}`, dataObj)
    if (resp.errorMessage) {
      setLoading(false)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }
    dispatch(setUserChargeRecord(resp.data.userCharge))
    dispatch(setSelectedPID(resp.data.pid))
    dispatch(setSelectedPlan(resp.data.plan))
    dispatch(setSelectedCarPark(resp.data.carPark))
    dispatch(setChargerStep('start-charge'))
    setLoading(false)
    return history.replace('/charger')
  }

  const fail = () => {
    setLoading(false)
    dispatch(setErrorMessage('payment failed/cancelled'))
    dispatch(setChargerStep('charger-fail'))
    return history.replace('/charger')
  }

  return null
}


export default useFreeCheckout;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, Row, Col
} from 'antd'
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { setModal } from '../redux/actions/common';

const AppModal = () => {
  const { t } = useTranslation()
  const modal = useSelector((state) => state.app.modal)
  const {
    visible, title, content, className
  } = modal
  const dispatch = useDispatch()
  return (
    <Modal
      className={`app-modal ${className}`}
      title=""
      closable={false}
      visible={visible}
      centered
      bodyStyle={styles.container}
      style={{ maxWidth: "95%" }}
      destroyOnClose
      onCancel={() => {
        dispatch(setModal({ visible: false }))
      }}
      footer={null}
    >
      <div>
        <h1 style={styles.title}>{title}</h1>
        {ReactHtmlParser(content)}
      </div>
      <Button
        style={{ ...styles.buttonOutline }}
        onClick={() => dispatch(setModal({
          visible: false,
          className: '',
          content: '',
          title: ''
        }))}
      >
        <span style={{ ...styles.buttonOutlineText }}>{t('close')}</span>
      </Button>
    </Modal>
  )
}

const styles = {
  container: {
    minHeight: 500,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: '600',
    color: '#1A468F',
    fontSize: 18,
    textAlign: 'center'
  },
  buttonOutline: {
    margin: '20px 0px',
    width: '100%',
    borderRadius: 10,
    color: '#1A468F',
    border: '2px solid #1A468F',
    height: 50
  },
  buttonOutlineText: {
    color: '#1A468F',
    fontSize: 18,
    fontWeight: '600'
  },
}

export default AppModal;

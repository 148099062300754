import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification
} from 'antd';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import {
  setChargedTime,
  setChargerStep, setErrorCode, setErrorMessage, setStartTime, setUserChargeRecord
} from '../../redux/actions/common';
import { DefaultStyles } from '../../constants';

let intervalId;
const ConfirmStop = ({
  setLoading,
  loading,
  setLoadingMessage
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const charger = useSelector((state) => state.charger)
  const { userChargeRecord } = charger
  const [isStop, setStop] = useState(false)


  useEffect(() => {
    if (isStop) {
      // request by 3s
      intervalId = setInterval(() => {
        detectChargingStatus()
      }, 3000)
    }

    return () => {
      if (!isStop) {
        clearInterval(intervalId)
      }
    }
  }, [isStop])


  const confirmStop = async () => {
    setLoading(true)
    setLoadingMessage(t('fetching_charger_information'))
    const resp = await Service.call('post', `/api/web/user_charge/stop/${userChargeRecord.id}`)
    if (resp.errorMessage) {
      setLoadingMessage('')
      setLoading(false)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      return
      // return notification.error({
      //   message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
      // });
    }
    dispatch(setUserChargeRecord(resp.data))
    dispatch(setStartTime(resp.data.adjust_start_time))
    dispatch(setChargedTime(resp.data.charged_time))
    setStop(true)
  }

  const dismiss = () => {
    dispatch(setChargerStep('charging-status'))
  }


  const detectChargingStatus = async () => {
    const resp = await Service.call('get', `/api/web/pid/stop/${userChargeRecord.pid.pid}/${userChargeRecord.charger_transaction_id}`)
    if (resp.errorMessage) {
      setLoading(false)
      setStop(false)
      setLoadingMessage(null)
      clearInterval(intervalId)
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    const pid = resp.data
    if (pid.OCPP_charger_status === 'Finishing') {
      setLoading(false)
      setStop(false)
      setLoadingMessage(null)
      dispatch(setChargerStep('charging-stop-success'))
    }
  }


  return (
    <AppLayout
      headerOptions={{
        hide: true
      }}
    >
      <Row style={{ minHeight: '80vh' }} justify="center" align="middle">
        <Col>
          <Row align="middle" justify="center" style={{ textAlign: 'center' }} gutter={[0, 30]}>
            <Col span={5}>
              <img src="/assets/warning.svg" style={{ width: '100%', objectFit: 'contain' }} alt="Warning" />
            </Col>
            <Col span={24}>
              <span style={DefaultStyles.header}>
                {t('stop_charging')}
              </span>
              <br />
              <span style={DefaultStyles.body}>
                {t('do_you_want_to_stop_charging')}
                ?
              </span>
            </Col>

            <Col span={24}>
              <Button
                disabled={loading}
                style={{
                  ...DefaultStyles.dangerButton,
                  marginBottom: 10
                }}
                onClick={confirmStop}
              >
                <span style={DefaultStyles.dangerButtonText}>{t('confirm_stop')}</span>
              </Button>
              <Button
                disabled={loading}
                style={DefaultStyles.button}
                onClick={dismiss}
              >
                <span style={DefaultStyles.buttonText}>
                  {t('dismiss')}
                </span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default ConfirmStop;

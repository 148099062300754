import React, { useEffect, useState } from 'react';
import {
  Col, Row, Collapse, List, Divider, Skeleton
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _, { last } from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import * as Service from '../core/Service';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';
import SkeletonScreen from '../components/SkeletonScreen';

const { Panel } = Collapse;

const EnquiredList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dataList, setDataList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getdataList()
  }, [])

  const getdataList = async () => {
    setLoading(true)
    const resp = await Service.call('get', '/api/web/contact_us_record')
    setDataList(_.orderBy(resp.data, ['ctime'], ['desc']))
    setLoading(false)
  }

  return (
    <SkeletonScreen loading={loading}>
      <AppLayout
        color="#fff"
        headerOptions={{
          title: t('enquired_list'),
          leftComponent: <LeftCircleFilled
            onClick={() => {
              history.goBack();
            }}
            style={DefaultStyles.leftArrow}
          />
        }}
      >

        <Row justify="center">
          <Col
            span={24}
          >
            <List
              bordered={false}
              dataSource={dataList}
              itemLayout="vertical"
              rowKey="contact_us_record_id"
              renderItem={(item) => {
                return (
                  (
                    <List.Item key={item.contact_us_record_id}>
                      <Row align="middle" justify="space-between">
                        <Col span={18} style={{ paddingRight: 20 }}>
                          <span style={{ ...DefaultStyles.subHeader}}>{item.name}</span>
                          <br />
                          <span style={{ ...DefaultStyles.subBody}}>{item.comment}</span>
                        </Col>
                        <Col span={6} style={{ ...styles.time_label}}>
                          {moment.unix(item.ctime).format('YYYY-MM-DD')}
                        </Col>
                      </Row>
                    </List.Item>
                  )
                )
              }}
            />
          </Col>
        </Row>
      </AppLayout>
    </SkeletonScreen>
  )
}


const styles = {
  title: {
    ...FONTS.subBody,
    color: COLORS.primary
  },
  content: {
    ...FONTS.subBody,
    color: COLORS.primary
  },
  time_label: {
    ...FONTS.subBody,
    color: COLORS.secondary,
    textAlign: 'end'
  },
}

export default EnquiredList;

import React, { useEffect } from 'react';
import {
  Button, Col, Input, Row, Select, Form, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';

const queryString = require('query-string');

const ResetPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onFinish = async (formData) => {
    const parsed = queryString.parse(location.search);

    const dataObj = {
      ...formData,
      phone: _.isUndefined(parsed.phone) ? '' : parsed.phone,
      code: _.isUndefined(parsed.code) ? '' : parsed.code
    }

    const resp = await Service.call(
      'put',
      '/api/web/user/reset_password',
      dataObj
    );

    if (resp.errorMessage) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      })
    }

    return history.push('/reset-password-success');
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('reset_password'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.goBack();
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row justify="center">
        <Col
          span={24}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="new_password"
              rules={[{ required: true, message: t('required') }]}
            >
              <Input.Password
                style={DefaultStyles.formInput}
                size="large"
                prefix={<img alt="password" src="/assets/password.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('password')}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              dependencies={['password']}
              hasFeedback
              validateFirst
              rules={[
                {
                  required: true,
                  message: t('please_confirm_your_password'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password
                style={DefaultStyles.formInput}
                size="large"
                prefix={<img alt="confirm password" src="/assets/password.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('confirm_password')}
              />
            </Form.Item>
            <div style={{ margin: '30px 0px' }}>
              <Button
                style={DefaultStyles.button}
                type="primary"
                htmlType="submit"
              >
                <span style={DefaultStyles.buttonText}>
                  {t('reset_password')}
                </span>
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default ResetPassword;

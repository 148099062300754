import React, { useEffect, useState } from 'react';
import {
  Col, Row
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppLayout from '../components/AppLayout';
import { PaymentHistoryDetail } from '../components/ChargingDetail';
import { DefaultStyles } from '../constants';

function PaymentHistory() {
  const { t } = useTranslation()
  const orderInfoDetail = useSelector((state) => state.charger.userChargeRecord)
  const history = useHistory()

  useEffect(() => {
    if (_.isEmpty(orderInfoDetail)) return history.replace('/payment-record')
  }, [orderInfoDetail])

  if (_.isEmpty(orderInfoDetail)) return null;

  const showPrice = () => {
    const {final_price, status, orderInfoDetail: orderInfo } = orderInfoDetail;
    const {original_price, discount} = orderInfo;
    switch (status) {
      case "payment_refund":
      case "payment_partial_refund":
        return _.round(original_price - discount, 2).toFixed(2);
      default:
        return _.round(final_price, 2).toFixed(2);
    }
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('payment_details'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.goBack();
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <PaymentHistoryDetail />
      <Row justify="space-between">
        <Col style={DefaultStyles.header}>
          {t('total')}
        </Col>
        <Col style={DefaultStyles.header}>
          HK$
          {showPrice()}
        </Col>
      </Row>
    </AppLayout>
  );
}

export default PaymentHistory;

import React, { useState, useEffect } from 'react';
import { Spin, message, ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  setConfig, setUser, setAuth, setSiteMeta, setURLParams, setDefaultCar, setLanguage, setOrigin
} from './redux/actions/common'
import * as Service from './core/Service';
import AppRoute from './AppRoute';
import enUS from 'antd/lib/locale/en_US';
import zhTW from 'antd/lib/locale/zh_TW';
import { COLORS } from './constants';

let configOrigin = '';
const queryString = require('query-string');

message.config({
  top: '75%',
  maxCount: 1,
});

const AppContainer = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (!window) return;
    const parsed = queryString.parse(window.location.search);
    let pathname = _.compact(_.split(window.location.pathname, '/'))
    if (_.includes(pathname, 'charge') && _.includes(pathname, '852') && parsed?.pid) {
      dispatch(setURLParams(parsed))
    }
  }, [])

  const init = async () => {
    setLoading(true);
    await getConfigList()
    await getSiteMeta()
    await getUser()

    setLoading(false);
  }

  const getConfigList = async () => {
    let resp = await Service.call('get', '/api/web/config');

    if (resp && resp.status > 0) {
      dispatch(setConfig(resp));
      dispatch(setOrigin(window.location.origin))
    } else {
      throw new Error('failed to get app config');
    }
  }

  const getSiteMeta = async () => {
    let resp = await Service.call('get', '/api/web/site_meta');
    if (resp && resp.status > 0) {
      dispatch(setSiteMeta(_.keyBy(resp.data, 'meta_key')));
    } else {
      throw new Error('failed to get app config');
    }
  }

  const getUser = async () => {
    const resp = await Service.call('get', `/api/web/user`);
    if (resp && resp.status > 0) {
      dispatch(setUser(resp.data));
      dispatch(setDefaultCar(resp?.data?.userEv ?? { id: 0 }));
      dispatch(setAuth(true));
      setLoading(false);
      return;
    }

    dispatch(setAuth(false));
  }

  return (
    <ConfigProvider locale={localStorage.getItem('language') === 'en' ? enUS : zhTW}>
      <div>
        <div style={loading ? styles.splash : {...styles.splash, ...styles.fadeOut}}>
          <img
            alt=""
            src="/assets/halo_logo.png"
            style={{ width: 300, height: "100%", objectFit: "contain" }}
          />
        </div>
        <div style={loading ? styles.main : {...styles.main, ...styles.fadeIn}}>
          {!loading && (
            <AppRoute />
          )}
        </div>
      </div>
    </ConfigProvider>
  )
}

const styles = {
  splash: {
    position: 'absolute',
    backgroundColor: COLORS.white,
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity 1s ease-out'
  },
  main: {
    opacity: 0
  },
  fadeIn: {
    opacity: 1,
    transition: 'opacity 1s ease-in'
  }
}

export default AppContainer;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification, Empty
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import CarParkItem from '../CarParkItem'

import { setBookingStep, setSelectedCarPark } from '../../redux/actions/common';
import LocationSelect from '../LocationSelect';
import { DefaultStyles } from '../../constants';


const BookingLocation = ({
  setLoading,
  loading
}) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch();

  const [originalCarParkList, setOriginalCarParkList] = useState([])
  const [carParkList, setCarParkList] = useState([])

  useEffect(() => {
    getCarPark()
  }, [])

  const getCarPark = async() => {
    setLoading(true)
    let dataSource = []
    try {
      const resp = await Service.call('get', '/api/web/car_park/booking')

      if (resp.status <= 0) {
        setLoading(false)
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }
      dataSource = resp.data
      setCarParkList(dataSource)
      setOriginalCarParkList(dataSource)
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setCarParkList(dataSource);
      setOriginalCarParkList(dataSource)
    }
    setLoading(false)
  }


  return (
    <AppLayout
      headerOptions={{
        title: t('location'),
        leftComponent: (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={() => {
              dispatch(setBookingStep('my-booking'))
            }}
          />
        )
      }}
    >
      <Row
        justify="space-between"
        gutter={[0, 0]}
        align="middle"
        style={{ marginBottom: 30}}
      >
        <Col span={12}>
          <LocationSelect
            originalCarParkList={originalCarParkList}
            setCarParkList={setCarParkList}
          />
        </Col>
        <Col span={11}>
          <Button
            onClick={() => {
              dispatch(setSelectedCarPark({}))
              dispatch(setBookingStep('booking-map-view'))
            }}
            style={DefaultStyles.button}
          >
            <Row
              align="middle"
              justify="space-between"
              style={{...DefaultStyles.buttonText, fontSize: 18, height: '100%'}}
            >
              <Col
                span={4}
              >
                <img src="/assets/map.svg" alt="Map Pin" style={{ width: 30, height: 30 }} />
              </Col>
              <Col
                span={18}
              >
                {t('map')}
              </Col>
            </Row>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {_.isEmpty(carParkList)
            ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('no_car_park')}
              />
            ) : _.map(carParkList, (item) => {
              return (
                <div
                  tabIndex={0}
                  role="button"
                  style={{ width: '100%', height: 'auto', textAlign: 'start' }}
                  key={item.id}
                  onMouseDown={() => {
                    if (loading) return;
                    dispatch(setSelectedCarPark(item))
                    dispatch(setBookingStep('select-time-slot'))
                  }}
                >
                  <CarParkItem
                    item={item}
                    lang={i18n.language}
                  />
                </div>
              )
            })}
        </Col>
      </Row>
    </AppLayout>
  )
}

export default BookingLocation;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification
} from 'antd';
import {
  CheckCircleFilled,
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector } from 'react-redux'
import * as Service from '../core/Service';
import AppLayout from './AppLayout'
import { COLORS, DefaultStyles, FONTS } from '../constants';
import styles from '../constants/styles'

const SuccessPage = ({
  title = '',
  subTitle = '',
  buttonTitle = '',
  buttonAction = () => {}
}) => {
  return (
    <AppLayout
      headerOptions={{
        hide: true
      }}
    >
      <Row style={{ minHeight: '80vh' }} align="middle">
        <Col span={24}>
          <Row style={{ textAlign: 'center' }} gutter={[0, 30]}>
            <Col span={24}>
              <CheckCircleFilled style={styles.circleIcon} />
            </Col>
            <Col span={24}>
              <span style={DefaultStyles.header}>
                {title}
              </span>
              <br />
              <span style={DefaultStyles.body}>
                {subTitle}
              </span>
            </Col>
            <Col span={24}>
              <Button
                style={DefaultStyles.button}
                onClick={buttonAction}
              >
                <span style={DefaultStyles.buttonText}>{buttonTitle}</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default SuccessPage;

import React, { useEffect, useState, useRef } from 'react';
import {
  Button, Col, Row, notification, Typography, Skeleton
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  setBookingInit, setBookingStep, setChargerInit, setChargerStatus, setChargerStep, setMonthlyInit, setMonthlyStep, setMyCarInit, setMyCarStep, setSelectedCar, setURLParams, setUserChargeRecord,
} from '../redux/actions/common'
import * as Service from '../core/Service';
import AppLayout from '../components/AppLayout';
import queryString from 'query-string';
import { StatusBar } from '../components/charger';
import {
  FONTS, COLORS, SIZES, DefaultStyles
} from '../constants'

const { Text } = Typography;

const Home = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { t } = useTranslation();
  const [userChargeDetail, setUserChargeDetail] = useState(null)
  const [init, setInit] = useState(true)
  const started = useRef(false)
  const intervalRef = useRef()
  const app = useSelector((state) => state.app)

  useEffect(() => {
    if (app.auth && app.url_params.pid) {
      history.replace(`/charger?pid=${app.url_params.pid}`)
      dispatch(setURLParams({}))
    }
  }, [app.auth])

  useEffect(() => {
    getChargingStatus()
    const query = queryString.parse(search)
  }, [])

  // Interval Looping
  useEffect(() => {
    // request by 10s
    intervalRef.current = setInterval(() => {
      // if (!started.current) return;
      getChargingStatus()
    }, 1000 * 10)
    return () => clearInterval(intervalRef.current)
  }, [])

  const getChargingStatus = async () => {
    try {
      const resp = await Service.call('get', '/api/web/user/charging');
      if (resp.status <= 0) {
        setUserChargeDetail({})
        // clearInterval(intervalRef.current)
        started.current = false
        return
      }
      if (!_.isEmpty(resp.data)) {
        setUserChargeDetail(resp.data)
        if (resp.data?.pid.OCPP_charger_status === 'Finishing' && resp.data?.status === 'Terminated') {
          const query = queryString.parse(search)
          if (_.isEmpty(query) || query?.redirect === '1') {
            dispatch(setChargerStep('charger-no-response'))
            history.push('/charger')
            return
          }
        }
        started.current = true
        return
      }
      setUserChargeDetail({})
    } catch (error) {
      console.error(error)
    } finally {
      setInit(false)
    }
  }

  const onClickChargingStatus = async () => {
    // redirect charging success page when charger is finishing
    if (userChargeDetail.pid.OCPP_charger_status === 'Finishing') {
      dispatch(setUserChargeRecord(userChargeDetail))
      dispatch(setChargerStep('charging-stop-success'))
      history.push('/charger')
      return;
    }

    if (userChargeDetail.pid.OCPP_charger_status === 'Charging') {
      // View Charging Status information when charger is charging
      dispatch(setChargerStatus({
        selectedPID: userChargeDetail.pid,
        selectedPlan: userChargeDetail.plan,
        selectedCarPark: userChargeDetail.car_park,
        selectedCoupon: userChargeDetail.coupon,
        userChargeRecord: userChargeDetail,
        selectedDiscount: userChargeDetail.discount,
        startTime: userChargeDetail.adjust_start_time,
        chargedTime: userChargeDetail.charged_time
      }))
      if (userChargeDetail.user_ev_id > 0) {
        const userEvResp = await Service.call('get', `/api/web/user_ev/${userChargeDetail.user_ev_id}`)
        dispatch(setSelectedCar({
          ...userEvResp.data,
          brand: userEvResp.data.ev_brand,
          model: userEvResp.data.ev_model,
        }))
      }
      dispatch(setChargerStep('charging-status'))
      history.push('/charger')
    }
  }

  const onClickTopup = () => history.push('/topup-record')
  const onClickHistory = () => history.push('/payment-record')
  const onClickNotice = () => history.push('/notice')

  const onClickScanAndCharge = () => {
    if (_.isNull(userChargeDetail)) {
      return
      // return notification.info({ message: 'Fetching data, please try again.'});
    }
    if (!_.isEmpty(userChargeDetail)) {
      return notification.info({ message: 'Multiple charging is not allow.'});
    }
    dispatch(setChargerInit())
    dispatch(setChargerStep('scan-charger'))
    history.push('/charger')
  }

  const onClickMyCar = () => {
    dispatch(setMyCarInit())
    dispatch(setChargerInit())
    dispatch(setMyCarStep('car-list'))
  }

  const onClickBooking = () => {
    dispatch(setBookingInit())
    dispatch(setChargerInit())
    dispatch(setBookingStep('my-booking'))
  }

  const onClickMonthly = () => {
    dispatch(setMonthlyInit())
    dispatch(setChargerInit())
    dispatch(setMonthlyStep('my-monthly-list'))
  }


  const handleImageError = (e) => {
    e.target.parentNode.style.display="none";
  };


  const getButton = () => {
    if (_.isNull(userChargeDetail)) return null;
    
    if (!_.isEmpty(userChargeDetail)) {
      // Finishing -> no button show
      if (userChargeDetail.pid.OCPP_charger_status === 'Finishing') {
        return (
            <div
              style={{
                ...styles.shortButton,
                color: COLORS.white
              }}
            >
              {userChargeDetail.status !== 'Terminated' ? t('please_unplug_the_charger') : t('charger_fail_desc')}
            </div>
        );
      }
      return (
        <Col span={24}>

        {userChargeDetail.canStop && (
          <Button
              style={DefaultStyles.dangerButtonOutline}
              onClick={() => {
                dispatch(setUserChargeRecord(userChargeDetail))
                dispatch(setChargerStatus({
                  selectedPID: userChargeDetail.pid,
                  selectedPlan: userChargeDetail.plan,
                  selectedCarPark: userChargeDetail.car_park,
                  selectedCoupon: userChargeDetail.coupon,
                  userChargeRecord: userChargeDetail,
                  selectedDiscount: userChargeDetail.discount,
                  startTime: userChargeDetail.adjust_start_time,
                  chargedTime: userChargeDetail.charged_time
                }))

                dispatch(setChargerStep('confirm-stop'))
                history.push('/charger')
              }}
            >
              <Row
                justify="center"
                align="middle"
                gutter={[10, 0]}
                style={{
                  ...DefaultStyles.dangerButtonOutlineText,
                  height: '100%'
                }}
              >
                <Col>
                  <img src="/assets/no-plug.svg" alt="stop" style={{ width: 30, height: 30 }} />
                </Col>
                <Col>
                  {t('stop_charging')}
                </Col>
              </Row>
            </Button>
          )}
        </Col>
      )
    }

    return (
      <Col span={24}>

        <Button
          style={{
            ...styles.longButton,
          }}
          onClick={onClickScanAndCharge}
        >
          <Row align="middle" justify="space-between">
            <Col span={14} style={{ textAlign: 'start' }}>
              <Text style={styles.headerText}>{t('scan_and_charge')}</Text>
            </Col>
            <Col span={6}>
              <img alt="" src="/assets/scan.svg" style={styles.qrCodeImage} />
            </Col>
          </Row>
        </Button>
      </Col>
    )
  }

  if (init) {
    return (
      <AppLayout
        color="#fff"
        headerOptions={{
          hide: true
        }}
      >
        <Row justify="space-between" style={{ margin: '30px 0px' }}>
          <Col span={10}>
            <Skeleton.Button
              active
              style={{
                aspectRatio: '3/1', width: '100%', height: '100%', borderRadius: 10
              }}
            />
          </Col>
          <Col span={9}>
            <Row gutter={[10, 0]} justify="end" align="middle" style={{ height: '100%' }}>
              <Col>
                <Skeleton.Avatar active />
              </Col>
              <Col>
                <Skeleton.Avatar active />
              </Col>
              <Col>
                <Skeleton.Avatar active />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Skeleton.Button active style={{ width: '100%', borderRadius: 10, minHeight: '14vh' }} />
          </Col>
        </Row>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Skeleton.Button active style={{ width: '100%', borderRadius: 10, minHeight: '14vh' }} />
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="space-between">
          <Col span={12}>
            <Row justify="start">
              <Col span={24}>
                <Skeleton.Button
                  active
                  style={{
                    width: '100%', height: 110, borderRadius: 10
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify="start">
              <Col span={24}>
                <Skeleton.Button
                  active
                  style={{
                    width: '100%', height: 110, borderRadius: 10
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify="start">
              <Col span={24}>
                <Skeleton.Button
                  active
                  style={{
                    width: '100%', height: 110, borderRadius: 10
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify="start">
              <Col span={24}>
                <Skeleton.Button
                  active
                  style={{
                    width: '100%', height: 110, borderRadius: 10
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </AppLayout>
    )
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        hide: true
      }}
    >
      <Row justify="space-between" style={{ margin: '30px 0px', alignItems: 'center'}}>
        <Col span={10}><img alt="" src="/assets/halo_logo.png" style={styles.logo} /></Col>
        <Col span={9} style={styles.imageLogo}>
          {app.default_car?.brand?.promotion_logo_path && (
                <Col>
                  <img
                    alt="promotion_logo"
                    src={`${app.origin}/${app.default_car?.brand?.promotion_logo_path}`}
                    style={{ width: '100%', maxHeight: "80px", padding: "5px" }}
                    onError={handleImageError}
                  />
                </Col>
          )}
          <Row gutter={[10, 0]} justify="end" align="middle" style={{ height: '100%' }}>
            <Col>
              <Button
                type="text"
                shape="circle"
                style={styles.buttonIcon}
                onClick={onClickTopup}
                icon={(
                  <img
                    alt="topup"
                    src="/assets/topup.png"
                    style={{...styles.actionIcon, padding: 5}}
                  />
                )}
              />
            </Col>
            <Col>
              <Button
                type="text"
                shape="circle"
                style={styles.buttonIcon}
                onClick={onClickHistory}
                icon={(
                  <img
                    alt="history"
                    src="/assets/history.svg"
                    style={styles.actionIcon}
                  />
                )}
              />
            </Col>
            <Col>
              <Button
                type="text"
                shape="circle"
                style={styles.buttonIcon}
                onClick={onClickNotice}
                icon={(
                  <img
                    alt="notification"
                    src="/assets/notification.svg"
                    style={styles.actionIcon}
                  />
                )}
              />
            </Col>
          </Row>
            {/* {app.default_car?.brand?.path && (
              <Row>
               <Col>
                  <img
                    alt="brand_logo"
                    src={`${app.origin}/${app.default_car?.brand?.path}`}
                    style={{width: "100%", maxHeight: "80px", padding: "5px" }}
                    onError={handleImageError}
                  />
                </Col>
              </Row>
            )} */}
        </Col>
      </Row>
      {!_.isEmpty(userChargeDetail)
        && userChargeDetail.status !== 'Terminated' && (
        <Row
          style={DefaultStyles.chargingStatusCard}
          align="middle"
          gutter={[0, 10]}
          onClick={onClickChargingStatus}
        >
          <Col
            span={24}
            style={styles.chargingText}
          >
            {t('charging_status')}
          </Col>
          <Col span={24}>
            <StatusBar 
              targetEnergy={userChargeDetail.targetEnergy}
              canStop={userChargeDetail?.canStop}
              status={userChargeDetail?.pid.OCPP_charger_status}
              value={userChargeDetail?.total_charged_energy ?? 0}
              total={userChargeDetail?.energy_to_charge?? 0}
            />
          </Col>
        </Row>
        )}
      <Row gutter={[0, 24]}>
        {
          getButton()
        }
        <Col span={24}>
          <Link to="/my-car">
            <Button
              onClick={onClickMyCar}
              style={styles.longButton}
            >
              <Row align="middle" justify="space-between">
                <Col span={14} style={{ textAlign: 'start' }}>
                  <Text style={styles.headerText}>{t('my_car')}</Text>
                </Col>
                <Col span={7}>
                  <div alt="my-car" style={{...styles.carImage, backgroundImage: app.default_car.id !== 0 && `url(${app.origin}/${app.default_car?.model?.path || '/assets/car.png' })` }} />
                </Col>
              </Row>
            </Button>
          </Link>
        </Col>
      </Row>
      <Row gutter={[0, 12]}>
        <Col span={12}>
          <Row justify="start">
            <Col span={23}>
              <Link to="/booking">
                <Button
                  style={styles.shortButton}
                  onClick={onClickBooking}
                >
                  <Row justify="center" gutter={[0, 0]}>
                    <Col span={10} style={{ marginBottom: 10 }}>
                      <img alt="booking" src="/assets/calendar.svg" style={styles.featureIcon} />
                    </Col>
                    <Col span={24}>
                      <Text style={styles.shortButtonText}>
                        {t('booking')}
                      </Text>
                    </Col>
                  </Row>

                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col span={23}>
              <Link to="/subscription">
                <Button
                  style={styles.shortButton}
                  onClick={onClickMonthly}
                >
                  <Row justify="center" gutter={[0, 0]}>
                    <Col span={10} style={{ marginBottom: 10 }}>
                      <img alt="monthly" src="/assets/pass.svg" style={styles.featureIcon} />
                    </Col>
                    <Col span={24}>
                      <Text style={styles.shortButtonText}>
                        {t('monthly')}
                      </Text>
                    </Col>
                  </Row>
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="start">
            <Col span={23}>
              <Link to="/map">
                <Button style={styles.shortButton}>
                  <Row justify="center" gutter={[0, 0]}>
                    <Col span={10} style={{ marginBottom: 10 }}>
                      <div
                        alt="map"
                        style={{
                          ...styles.featureIcon, ...DefaultStyles.image, backgroundImage: 'url(assets/map.png)', paddingTop: '100%'
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <Text style={styles.shortButtonText}>
                        {t('map')}
                      </Text>
                    </Col>
                  </Row>
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col span={23}>
              <Link to="/setting">
                <Button style={styles.shortButton}>
                  <Row justify="center" gutter={[0, 0]}>
                    <Col span={10} style={{ marginBottom: 10 }}>
                      <img alt="setting" src="/assets/setting.svg" style={styles.featureIcon} />
                    </Col>
                    <Col span={24}>
                      <Text style={styles.shortButtonText}>
                        {t('setting')}
                      </Text>
                    </Col>
                  </Row>
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 24]}>
        <Col>
          <Link to="/contact-us">
            <span style={styles.needHelp}>
              {t('need_help')}
              ?
            </span>
          </Link>
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  logo: {
    width: '100%',
    // height: '100%',
    // height: 40,
    aspectRatio: '2/1',
    // margin: '0px 5px',
    objectFit: 'contain'
  },
  buttonIcon: {
    width: 34,
    height: 34,
    backgroundColor: COLORS.primary,
    borderRadius: SIZES.circle,
  },
  actionIcon: {
    width: '100%',
    height: '100%',
    padding: 3,
    objectFit: 'contain'
    // padding: SIZES.padding / 2
    // margin: '0px 5px'
  },
  longButton: {
    backgroundColor: COLORS.primary,
    width: "100%",
    height: '100%',
    borderRadius: 10,
    border: 'none',
    padding: '15px',
  },
  shortButton: {
    backgroundColor: COLORS.primary,
    width: '100%',
    height: '100%',
    borderRadius: 10,
    border: 'none',
    padding: 10,
  },
  featureIcon: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '1 / 1'
  },
  qrCodeImage: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '1 / 1'
  },
  carImage: {
    objectFit: 'contain',
    ...DefaultStyles.image,
    paddingTop: '100%'
  },
  headerText: {
    ...FONTS.header,
    color: COLORS.white
  },
  shortButtonText: {
    ...FONTS.body,
    color: COLORS.white
  },
  needHelp: {
    ...FONTS.subHeader,
    color: COLORS.primary
  },
  chargingText: {
    ...FONTS.h5,
    color: COLORS.primary,
    textAlign: 'center'
  },
  imageLogo:{
    display: "flex",
    justifyItems: "center",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
  }
}

export default Home;

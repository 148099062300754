import { combineReducers } from 'redux';
import appReducer from './app';
import chargerReducer from './charger';
import carReducer from './car';
import orderReducer from './orders';
import bookingReducer from './booking';
import monthlyReducer from './monthly';

export default combineReducers({
  ...appReducer,
  ...chargerReducer,
  ...carReducer,
  ...orderReducer,
  ...bookingReducer,
  ...monthlyReducer
});

import React, { useEffect, useState } from 'react';
import {
  Skeleton, Spin, Row, Col
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import {
  MyCarList, LicenceForm, BrandForm, ModelForm, SaveCarOverview
} from '../components/my-car'
import SuccessPage from '../components/SuccessPage'
import { setMyCarInit, setMyCarStep } from '../redux/actions/common';
import SkeletonScreen from '../components/SkeletonScreen';

const MyCar = ({
  mode = 'car-list' // mode -> car-list, charger, booking, monthly
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const car = useSelector((state) => state.car)
  const { step } = car
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [callRefresh, setCallRefresh] = useState(true);

  const workFlow = () => {
    switch (step) {
      case 'car-list':
        return (
          <MyCarList
            setLoading={setLoading}
            callRefresh={callRefresh}
            setCallRefresh={setCallRefresh}
            mode={mode}
          />
        )
      case 'licence-form':
        return (
          <LicenceForm
            setCallRefresh={setCallRefresh}
          />
        )
      case 'select-brand':
        return (
          <BrandForm
            setLoading={setLoading}
          />
        )
      case 'select-model':
        return (
          <ModelForm
            setLoading={setLoading}
          />
        )
      case 'save-car-overview':
        return (
          <SaveCarOverview mode={mode} />
        )
      case 'add-car-success':
        return (
          <SuccessPage
            title={t('new_car_added_successfully')}
            subTitle={t('click_the_button_below_back_to_my_car')}
            buttonTitle={t('back_to_my_car')}
            buttonAction={() => {
              dispatch(setMyCarInit())
              dispatch(setMyCarStep('car-list'))
              setCallRefresh(true)
            }}
          />
        )
      default: return history.replace('/')
    }
  }

  return (
    <SkeletonScreen loading={loading}>
      {workFlow()}
    </SkeletonScreen>
  )
}


export default MyCar;

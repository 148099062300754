import React, { useEffect, useState } from 'react';
import {
  Modal,
  notification
} from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import { setChargerStep, setOrder, setSelectedDiscount } from '../../redux/actions/common'
import { WalkinChargingDetail } from '../ChargingDetail'

import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import PaymentDetail from '../PaymentDetail'
import orderChecking, { runSavedPaymentMethodStripe } from '../../core/api/order';

const ChargerPaymentDetail = ({ setLoading }) => {
  const history = useHistory()
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false)
  const charger = useSelector((state) => state.charger)
  const orders = useSelector((state) => state.orders)
  const {
    selectedPlan, selectedCoupon, selectedCar, selectedPID
  } = charger
  const dispatch = useDispatch()


  useEffect(() => {
    if (!_.isEmpty(orders.order)) return;
    getDiscount()
  }, [selectedPlan, selectedPID])


  const getDiscount = async () => {
    setLoading(true)
    const resp = await Service.call('get', `/api/web/discount/${selectedPlan.id}/${selectedPID.car_park_id}`)
    setLoading(false)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    dispatch(setSelectedDiscount(resp.data.discount))
  }

  const onCheckout = async (paymentMethod) => {
    if (!_.includes(['credit_card'], paymentMethod) && _.startsWith("pm_", paymentMethod)) {
      return notification.error({
        message: t('invalid_payment_method'),
        duration: 0
      })
    }
    setSubmitLoading(true)
    let resp = await Service.call('post', `/api/web/order/order_info`, {
      promo_code_id: selectedCoupon.id,
      plan_id: selectedPlan.id,
      user_ev_id: selectedCar.id,
      pid: selectedPID.pid
    });
    setSubmitLoading(false)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    dispatch(setOrder(resp.data))
    // Trigger free payment by useFreeCheckout
    if (resp.data.final_price <= 0) {
      return;
    }

    if (_.startsWith(paymentMethod, "pm_")) {
      setSubmitLoading(true)
      const paymentResp = await runSavedPaymentMethodStripe({
        order_key: resp.data.order_key,
        paymentMethodID: paymentMethod,
      });
      setSubmitLoading(false)
      const {
        status: paymentRespStatus = 0,
        errorMessage = t('failed_to_connect_to_the_payment_channel_please_try_again_later'),
      } = paymentResp;
      if (paymentRespStatus <= 0) {
        Modal.error({
          title: t('payment_fail'),
          content: (
            <div>
              {errorMessage}
            </div>
          )
        });
        return;
      }
      setSubmitLoading(true)
      const returnPath = await orderChecking(resp.data.order_key, selectedPID.pid);
      history.replace(returnPath);
      setSubmitLoading(false)
      return;
    }

    if (resp.data.final_price > 0) {
      return history.replace(`/charger/payment?order_key=${resp.data.order_key}`)
    }
  };

  return (
    <PaymentDetail
      setLoading={setLoading}
      chargingDetail={<WalkinChargingDetail mode="payment" />}
      selectCoupon={() => dispatch(setChargerStep('select-coupon'))}
      onCheckout={onCheckout}
      goBack={() => {
        dispatch(setChargerStep('select-plan'))
      }}
      submitLoading={submitLoading}
    />
  )
}

export default ChargerPaymentDetail;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, notification
} from 'antd';
import { useHistory, Link } from 'react-router-dom';
import {
  CloseCircleFilled,
} from '@ant-design/icons';
import _ from 'lodash'

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import { setChargerStep, setSelectedPlan } from '../../redux/actions/common';
import { COLORS, DefaultStyles } from '../../constants';

const ChargerNotice = ({
  navigateCallback
}) => {
  const { t } = useTranslation()
  const charger = useSelector((state) => state.charger)
  const { selectedPID, step } = charger
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    // request by 3s
    let intervalId = setInterval(() => {
      checkPIDStatus()
    }, 3000)

    return () => clearInterval(intervalId)
  }, [])

  const checkPIDStatus = async () => {
    if (_.isEmpty(selectedPID)) return notification.warning({ message: 'PID not found.' })
    const resp = await Service.call('get', `/api/web/start_charge/pid/check_available/${selectedPID.pid}`)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    const { pid, PLAN_CATEGORY, plan } = resp.data
    if (pid.OCPP_charger_status === 'Available') return;
    if (pid.OCPP_charger_status !== 'Preparing') return dispatch(setChargerStep('scan-charger'));


    navigateCallback(pid, plan, PLAN_CATEGORY)
  }

  return (
    <AppLayout
      fullWidth
      headerOptions={{
        title: '',
        rightComponent: <CloseCircleFilled
          onClick={() => {
            dispatch(setChargerStep('scan-charger'))
            history.push('/charger')
          }}
          style={{ fontSize: 32, textAlign: 'right' }}
        />
      }}
    >
      <Row justify="center" gutter={[0, 20]}>
        <Col
          span={24}
          style={{...DefaultStyles.header, color: COLORS.error, textAlign: 'center' }}
        >
          {t('please_connect_the_charger')}
        </Col>
        <Col
          span={24}
          style={{
            textAlign: 'center', color: '#1A468F', fontSize: 14, fontWeight: '500'
          }}
        >
          <Link to="/contact-us">
            <span style={DefaultStyles.subBody}>{`${t('need_help')}?`}</span>
          </Link>
        </Col>
        <Col span={24} style={{ marginTop: 60 }}>
          <img src="/assets/user-charge.svg" alt="please charge" style={{ width: '100%' }} />
        </Col>
      </Row>
    </AppLayout>
  )
}

export default ChargerNotice;

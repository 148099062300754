import React, { useState } from 'react'
import { Row, Col, Tag, Button, Popconfirm, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  CarFilled, MinusCircleFilled
} from '@ant-design/icons';
import { COLORS, DefaultStyles } from '../../constants';
import * as Service from '../../core/Service'
import * as Main from '../../core/Main'
import { setDefaultCar, setLoading } from '../../redux/actions/common';
import { useTranslation } from 'react-i18next';

const CarItem = ({
  item,
  onClickCar = () => {},
  mode,
  containerStyle = {},
  allowEdit = false, // optional
  removeCallback = () => {} // optional
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const app = useSelector((state) => state.app)
  const charger = useSelector((state) => state.charger)
  const [showAction, toggleAction] = useState(false)


  const onClickCarAction = async () => {
    if (allowEdit) return;
    if (mode === 'car-list') {
      toggleAction(!showAction)
      await Service.call('put', '/api/web/user/user_ev', { user_ev_id: item.id })
      dispatch(setDefaultCar(item))
      return;
    }
    onClickCar(item)
  }

  const DefaultIcon = () => {
    if (item.id !== app.default_car?.id) return null
    return (
      <Row
        style={{
          position: 'absolute', top: -14, left: '5%', fontSize: 24, color: COLORS.green
        }}
        align="middle"
        gutter={[5, 0]}
      >
        <Col style={{
          ...DefaultStyles.subBody,
        }}
        >
          <Tag icon={<CarFilled />} color={COLORS.green} style={{ borderRadius: 5 }}>
            {t('default')}
          </Tag>
        </Col>
      </Row>
    )
  }
  

  return (
    <Row
      className={allowEdit ? item.id % 2 ? 'jiggle' : 'jiggle-reverse' : ''}
      align="middle"
      justify="space-between"
      gutter={[0, 0]}
      style={{
        ...styles.card,
        ...containerStyle,
        // border: getBorder(),
        // backgroundColor: getBackgroundColor(item.id),
      }}
      onClick={onClickCarAction}
    >
      {allowEdit && <EditPanel id={item.id} removeCallback={removeCallback} />}
      {DefaultIcon()}
      <Col span={4} style={{ textAlign: 'center' }}>
        <img
          alt={item.brand.name}
          style={styles.brandLogo}
          src={item.brand.path ? `${app.origin}/${item.brand.path}` : '/assets/default.png'}
        />
      </Col>
      <Col span={9}>
        <Row style={{ textAlign: 'left' }}>
          <Col span={24} style={DefaultStyles.subBody}>{item.model.name}</Col>
          <Col span={24} style={DefaultStyles.subHeader}>{item.license_plate_number}</Col>
          <Col span={24} style={DefaultStyles.subBody}>
            <Row>
              <img alt="" src="/assets/battery.svg" />
              {item.model.battery_size}
              {' '}
              kWh
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={9} style={{ textAlign: 'center' }}>
        <img
          alt={item.model.name}
          style={styles.carImage}
          src={item.model.path ? `${app.origin}/${item.model.path}` : '/assets/default.png'}
        />
      </Col>
    </Row>
  )
}

const EditPanel = ({
  id,
  removeCallback
}) => {
  const { t } = useTranslation()
  return (
    <Popconfirm
      title={t('confirm_remove')}
      placement="right"
      onConfirm={async () => {
        const resp = await Service.call('delete', `/api/web/user_ev/${id}`)
        if (resp.status <= 0) {
          return notification.error({
            message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
            duration: 0
          });
        }
        notification.success({
          message: t('car_removed')
        });
        
        removeCallback()
      }}
      cancelText={t('cancel')}
      okText={t('confirm')}
    >
      <MinusCircleFilled 
        style={{
          position: 'absolute',
          background: '#fff',
          top: -10,
          left: -10,
          borderRadius: 10,
          fontSize: 24,
          color: 'red',
          zIndex: 99,
          transition: 'all 0.2s ease-in-out',
        }} 
      />
    </Popconfirm>
  )
}

const styles = {
  card: {
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    marginBottom: 15,
    minHeight: 110,
    padding: 15,
    position: 'relative',
    transition: 'all 0.3s ease-in-out'
  },
  buttonIcon: {
    fontSize: 20,
    color: COLORS.white,
    fontWeight: 'bold'
  },
  brandLogo: {
    width: '70%',
    objectFit: 'contain',
    aspectRatio: '1/1'
  },
  carImage: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '16/9'
  },
}


export default CarItem;

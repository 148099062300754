import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BookingMapView,
} from "./booking"
import SkeletonScreen from "./SkeletonScreen";

const Booking = () => {
  const [loading, setLoading] = useState(false)

  return (
    <SkeletonScreen loading={loading}>
      <BookingMapView backHome homepageMap setLoading={setLoading} />
    </SkeletonScreen>
  )
}

export default Booking;

import React, { useEffect, useState, useCallback } from 'react';
import {
  Row, Col, notification, DatePicker, Empty,
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import CarParkItem from '../CarParkItem';
import LocationSelect from '../LocationSelect';

import {
  setMonthlySelectedDate,
  setMonthlyStep,
  setSelectedCarPark
} from '../../redux/actions/common';
import { COLORS, DefaultStyles } from '../../constants';


const MonthlyLocation = ({
  setLoading,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const monthly = useSelector((state) => state.monthly)

  const [originalCarParkList, setOriginalCarParkList] = useState([])
  const [carParkList, setCarParkList] = useState([])
  const [selectedDate, setSelectedDate] = useState(monthly.selectedDate)

  useEffect(() => {
    if (monthly.selectedDate) {
      setSelectedDate(monthly.selectedDate)
    } else {
      setSelectedDate(moment().startOf('day').unix())
    }
  }, [monthly.selectedDate])

  useEffect(() => {
    if (selectedDate) {
      getCarParkList()
    }
  }, [selectedDate])

  const getCarParkList = async() => {
    let dataSource = []
    try {
      setLoading(true)
      const resp = await Service.call('get', `/api/web/car_park/monthly/${selectedDate}`)

      if (resp.status <= 0) {
        setLoading(false)
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }
      dataSource = _.orderBy(resp.data, ['availablePids'], ['desc'])
      setCarParkList(dataSource)
      setOriginalCarParkList(dataSource)
      setLoading(false)
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setCarParkList(dataSource);
      setOriginalCarParkList(dataSource)
    }
  }


  return (
    <AppLayout
      headerOptions={{
        title: t('location'),
        leftComponent: (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={() => {
              dispatch(setMonthlyStep('my-monthly-list'))
            }}
          />
        )
      }}
    >
      <Row justify="center" gutter={[0, 20]} align="middle" style={{ marginBottom: 30}}>
        <Col span={24}>
          <LocationSelect
            className="location-picker"
            originalCarParkList={originalCarParkList}
            setCarParkList={setCarParkList}
          />
        </Col>
        <Col span={24}>
          <DatePicker
            className="location-calendar"
            allowClear={false}
            size="large"
            inputReadOnly
            placeholder={t('select_date')}
            style={{
              width: '100%',
              height: 54,
              border: `2px solid ${COLORS.primary}`,
              borderRadius: 10,
              padding: 12.5
            }}
            onChange={(value) => setSelectedDate(value.startOf('day').unix())}
            value={moment.unix(selectedDate)}
            disabledDate={(current) => {
              return current < moment().startOf('day') || current > moment().add(30, 'days').startOf('day')
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {_.isEmpty(carParkList) ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('no_car_park_available_for_this_date')}
            />
          )
            : _.map(carParkList, (item) => {
              return (
                <Row
                  key={item.id}
                  style={{
                    width: '100%', height: 'auto', textAlign: 'start'
                  }}
                  onClick={() => {
                    if (!item.availablePids) return;
                    dispatch(setSelectedCarPark(item))
                    dispatch(setMonthlySelectedDate(selectedDate))
                    dispatch(setMonthlyStep('select-plan'))
                  }}
                  align="middle"
                  gutter={[0, 20]}
                >
                  <Col span={24}>
                    <CarParkItem item={item} showAvailability />
                  </Col>
                </Row>
              )
            })}
        </Col>
      </Row>
    </AppLayout>
  )
}

export default MonthlyLocation;

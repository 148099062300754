import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Switch, Route
} from "react-router-dom";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';
import Login from './pages/Login';
import Registration from './pages/Registration';
import RegistrationSuccess from './pages/RegistrationSuccess';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordSuccess from './pages/ResetPasswordSuccess';
import Home from './pages/Home';
import MyCar from './pages/MyCar';
import Charger from './pages/Charger';
import Booking from './pages/Booking';
import Monthly from './pages/Monthly';
import Setting from './pages/Setting';
import Notice from './pages/Notice';
import AboutUs from './pages/AboutUs';
import PaymentRecord from './pages/PaymentRecord';
import PaymentHistory from './pages/PaymentHistory';
import Account from './pages/Account';
import AccountForm from './pages/AccountForm';
import ChangePassword from './pages/ChangePassword';
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';
import FAQ from './pages/FAQ';
import EnquiredList from './pages/EnquiredList';
import ContactUs from './pages/ContactUs';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import NotFound from './components/NotFound';
import { ChargerPayment } from './components/charger';
import PaymentCompleted from './components/PaymentCompleted';
import TopupList from './pages/TopupList';
import TopupCouponHistory from './pages/TopupCouponHistory';
import HomeMapView from './components/HomeMapView';

class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted path="/login" component={Login} exact />
          <PublicRoute restricted path="/registration" component={Registration} exact />
          <PublicRoute restricted path="/registration-success" component={RegistrationSuccess} exact />
          <PublicRoute restricted path="/forget-password" component={ForgetPassword} exact />
          <PublicRoute restricted path="/reset-password" component={ResetPassword} exact />
          <PublicRoute restricted path="/reset-password-success" component={ResetPasswordSuccess} exact />
          <PrivateRoute restricted path="/" component={Home} exact />
          <PrivateRoute restricted path="/my-car" component={MyCar} exact />
          <PrivateRoute restricted path="/charger" component={Charger} exact />
          <PrivateRoute restricted path="/subscription" component={Monthly} exact />
          <PrivateRoute restricted path="/booking" component={Booking} exact />
          <PrivateRoute restricted path="/setting" component={Setting} exact />
          <PrivateRoute restricted path="/faq" component={FAQ} exact />
          <PrivateRoute restricted path="/enquired-list" component={EnquiredList} exact />
          <PrivateRoute restricted path="/contact-us" component={ContactUs} exact />
          <PrivateRoute restricted path="/about-us" component={AboutUs} exact />
          <PrivateRoute restricted path="/terms-and-conditions" component={TermsAndConditions} exact />
          <PrivateRoute restricted path="/privacy" component={Privacy} exact />
          <PrivateRoute restricted path="/account" component={Account} exact />
          <PrivateRoute restricted path="/account/update" component={AccountForm} exact />
          <PrivateRoute restricted path="/account/change-password" component={ChangePassword} exact />
          <PrivateRoute restricted path="/charger/payment" component={ChargerPayment} exact />
          <PrivateRoute restricted path="/payment-record" component={PaymentRecord} exact />
          <PrivateRoute restricted path="/payment-history" component={PaymentHistory} exact />
          <PrivateRoute restricted path="/notice" component={Notice} exact />
          <PrivateRoute restricted path="/payment_completed" component={PaymentCompleted} exact />
          <PrivateRoute restricted path="/topup-record" component={TopupList} exact />
          <PrivateRoute restricted path="/topup-history" component={TopupCouponHistory} exact />
          <PrivateRoute restricted path="/map" component={HomeMapView} exact />
          <Route restricted path="/404" component={NotFound} exact />
          <Redirect exact from="/*" to="/" />
        </Switch>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);

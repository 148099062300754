import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import {
  setChargerStep, setMyCarStep, setSelectedCar, setDefaultCar
} from '../../redux/actions/common';
import { COLORS, DefaultStyles, FONTS } from '../../constants';
import CarItem from './CarItem';

const SaveCarOverview = ({
  mode
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const app = useSelector((state) => state.app)
  const car = useSelector((state) => state.car)
  const charger = useSelector((state) => state.charger)
  const { selectedBrand, selectedModel, license } = car;
  const { selectedPID } = charger;
  const [loading, setLoading] = useState(false)

  const saveCar = async () => {
    setLoading(true)
    const resp = await Service.call('post', '/api/web/user_ev', {
      brand_id: selectedBrand.id,
      model_id: selectedModel.id,
      license_plate_number: license,
      isAu: true
    })

    setLoading(false)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    if (resp.data.is_default_car) {
      dispatch(setDefaultCar({
        ...resp.data,
        brand: selectedBrand,
        model: selectedModel
      }))
    }

    switch (mode) {
      case "car-list": {
        dispatch(setMyCarStep('add-car-success'))
        break;
      }
      case 'charger': {
        dispatch(setSelectedCar({
          ...resp.data,
          brand: selectedBrand,
          model: selectedModel
        }))
        dispatch(setMyCarStep('car-list'))
        dispatch(setChargerStep('select-plan'))
        break;
      }
      case 'booking':
      case 'monthly': {
        dispatch(setSelectedCar({
          ...resp.data,
          brand: selectedBrand,
          model: selectedModel
        }))
        dispatch(setMyCarStep('car-list'))
        dispatch(setChargerStep('detect-plan'))
        break;
      }
      default: break;
    }
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('overview'),
        leftComponent: (
          <LeftCircleFilled
            onClick={() => dispatch(setMyCarStep('select-model'))}
            style={DefaultStyles.leftArrow}
          />
        )
      }}
    >
      <CarItem
        item={{
          model: selectedModel,
          brand: selectedBrand,
          license_plate_number: license
        }}
        containerStyle={{
          backgroundColor: '#fff',
          border: '2px solid #F5F5F5'
        }}
      />
      <Row>
        <Col span={24}>
          <Button
            disabled={loading}
            style={{ ...styles.button }}
            onClick={saveCar}
          >
            <span style={{ ...styles.buttonText }}>
              {_.isEmpty(selectedPID) ? t('save_to_my_car') : t('save_and_select')}
            </span>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  header: {
    ...FONTS.header,
    color: COLORS.white,
    wordBreak: 'break-word',
    width: '100%'
  },
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: '#1A468F',
    borderColor: 'transparent',
    height: 50
  },
  buttonText: {
    ...FONTS.subHeader,
    color: COLORS.white
  },
  input: {
    height: 50,
    borderRadius: 10
  },
  brandCard: {
    textAlign: 'center',
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    width: 100,
    height: 100,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modelCard: {
    textAlign: 'center',
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    // width: '100%',
    height: 250,
    marginBottom: 10,
  },
  platNumberText: {
    ...FONTS.header,
    color: COLORS.dark,
    textAlign: 'center'
  },
  brandLogo: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '1/1'
  },
  carImage: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '16/9'
  },
}


export default SaveCarOverview;

import _ from 'lodash';
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import moment from 'moment';
import i18n from 'i18next'
import { notification } from 'antd';
import * as Service from './Service';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { ActionCreators } from '../redux/actions';
import { getStore } from '../redux/store/configureStore';

// export function getUser() {
//   return getStore().getState().app.user;
// }

// export function getCompany() {
//   return getStore().getState().app.company;
// }

// export function signOut() {
//   localStorage.setItem('user');
//   localStorage.setItem('auth', JSON.stringify(false));
//   localStorage.removeItem('company');
// }
export function convertAllCompanyUserAccessLogToDisplayData(dataList) {
  try {
    dataList.map((item) => {
      if (!_.isUndefined(item.ctime)) item.ctime = moment.unix(item.ctime).format('YYYY-MM-DD HH:mm:ss');
      if (!_.isUndefined(item.access_time)) item.access_time = moment.unix(item.access_time).format('YYYY-MM-DD HH:mm:ss');
      if (!_.isNil(item.utime)) delete item.utime;
      if (!_.isNil(item.passcode)) delete item.passcode;
      if (!_.isNil(item.passcode_type)) delete item.passcode_type;
      if (!_.isNil(item.passcode_time_start)) delete item.passcode_time_start;
      if (!_.isNil(item.passcode_time_end)) delete item.passcode_time_end;
      if (!_.isNil(item.access_token)) delete item.access_token;
      if (!_.isNil(item._id)) delete item._id;
      if (!_.isNil(item.version)) delete item.version;
      if (!_.isNil(item.browser)) delete item.browser;
      if (!_.isNil(item.signature)) delete item.signature;
      if (!_.isNil(item.os)) delete item.os;
      if (!_.isNil(item.platform)) delete item.platform;
      if (!_.isNil(item.client_ip)) delete item.client_ip;
      if (!_.isNil(item.controller_access_log_id)) delete item.controller_access_log_id;
      if (!_.isNil(item.controller_device_id)) delete item.controller_device_id;
      if (!_.isNil(item.controller_ip_internet)) delete item.controller_ip_internet;
      if (!_.isNil(item.controller_ip_local)) delete item.controller_ip_local;
      if (!_.isNil(item.controller_name)) delete item.controller_name;
      if (item.is_active === 1) {
        item.is_active = "actice"
      } else {
        item.is_active = "inactive"
      }
    })
    return dataList;
  } catch (error) {
    console.log(error)
  }
}

export async function exportToCSV(dataList, fileName) {
  const header = [
    [
      'Create Time',
      'Access Time',
      'Status',
      'Usage Count',
      'Active Status',
      'Direction',
      'Action',
      'Reader',
      'Last Name',
      'First Name',
      'User ID',
      'Door Name',
    ]
  ]
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  let ws = XLSX.utils.book_new()
  XLSX.utils.sheet_add_aoa(ws, header)
  XLSX.utils.sheet_add_json(ws, dataList, { skipHeader: true, origin: "A2" });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export function mergeByKey(arr, subArr, key) {
  return _.each(arr, (item) => {
    _.find(subArr, (subItem) => {
      return item[key] === subItem[key] ? _.assign(item, subItem) : null;
    })
  })
}

export function getErrorCodeMsg(errorCode, errorMessage, showErrorCode = true) {
  let code = `errorcode|${errorCode}`;
  let str = i18n.t(code);
  if (str === code || _.isEmpty(str)) {
    if (showErrorCode) {
      return `${errorMessage} [${errorCode}]`;
    }
    return `${errorMessage}`;
  }
  if (showErrorCode) {
    return `${str} [${errorCode}]`;
  }
  return `${str}`;
}

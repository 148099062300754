import React, { useEffect, useState, useRef } from 'react';
import {
  Modal, notification, Button, Spin, Row, Col, Skeleton
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import StripeCheckoutForm from "../StripeCheckoutForm";
import FreeCheckoutForm from "../FreeCheckoutForm";
import {
  setBookingStep,
  setChargerStep,
  setErrorCode,
  setErrorMessage,
  setMonthlyStep,
  setOrder,
  setSelectedCar,
  setSelectedCarPark,
  setSelectedPID,
  setSelectedPlan,
  setUserChargeRecord
} from '../../redux/actions/common';
import AppLayout from '../AppLayout';
import { DefaultStyles } from '../../constants';
import SkeletonScreen from '../SkeletonScreen';

const queryString = require('query-string');

const ChargerPayment = (props) => {
  const { t } = useTranslation()
  const intervalRef = useRef();
  const started = useRef(false);
  const [loading, setLoading] = useState(false)
  const [goBackLoading, setGoBackLoading] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const orders = useSelector((state) => state.orders);
  const charger = useSelector((state) => state.charger);
  const {
    selectedPID, selectedCar, selectedPlan
  } = charger;
  const { order } = orders;

  useEffect(() => {
    // keep polling API per second
    intervalRef.current = setInterval(() => {
      checkOrderStatus(started.current)
    }, 1000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  useEffect(() => {
    getOrderDetail()
  }, [])

  const getOrderDetail = async () => {
    setLoading(true)
    const parsed = queryString.parse(location.search);
    if (!parsed.order_key) return history.replace('/')
    const resp = await Service.call('get', `/api/web/order/order_info/${parsed.order_key}`)
    if (resp.status <= 0) {
      setLoading(false)
      return
      // return notification.info({
      //   message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
      // });
    }

    dispatch(setOrder(resp.data.order))
    // get Plan
    const planResp = await Service.call('get', `/api/web/plan/${resp.data.order.plan_id}`)
    dispatch(setSelectedPlan(planResp.data))

    setLoading(false)
  }


  const checkOrderStatus = async (start) => {
    if (!start) return;
    const resp = await Service.call('get', `/api/web/order/order_info/${order.order_key}`)

    if (resp.errorMessage) {
      started.current = false
      clearInterval(intervalRef.current)
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }

    switch (resp.data.order.status) {
      // keep track of order status -> success response
      case 'payment_hold':
      case 'payment_confirmed': {
        await success();
        return;
      }
      // keep track of order status -> fail/cancelled response
      // redirect to charger fail page
      case 'cancelled': {
        dispatch(setErrorMessage(t('order_cancelled')))
        fail();
        return true;
      }
      case 'payment_failed': {
        dispatch(setErrorMessage(t('payment_fail')))
        fail();
        return true;
      }
      default:
    }
  }

  const success = async (payload) => {
    if (!started.current) return;
    started.current = false;
    clearInterval(intervalRef.current)
    const resp = await Service.call('get', `/api/web/pid/${order.pid}`)
    if (resp.errorMessage) {
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }
    dispatch(setSelectedPID(resp.data))


    switch (resp.data.plan_category) {
      // walkin
      case "HourlyNight":
      case "DayNight": {
        await startCharge()
        break;
      }
      case "Monthly": {
        dispatch(setMonthlyStep('payment-success'))
        history.replace('/subscription')
        break;
      }
      case "UltraFast": {
        dispatch(setBookingStep('payment-success'))
        history.replace('/booking')
        break;
      }
      default: break;
    }
  }

  const startCharge = async () => {
    const dataObj = {
      selectedCar,
      selectedPlan,
      order_key: _.isEmpty(order) ? '' : order.order_key
    }
    const resp = await Service.call('post', `/api/web/start_charge/${order.pid}`, dataObj)
    if (resp.errorMessage) {
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      history.replace('/charger')
      return
    }
    dispatch(setUserChargeRecord(resp.data.userCharge))
    dispatch(setSelectedPID(resp.data.pid))
    dispatch(setSelectedPlan(resp.data.plan))
    dispatch(setSelectedCarPark(resp.data.carPark))
    dispatch(setChargerStep('start-charge'))
    return history.replace('/charger')
  }

  const fail = () => {
    clearInterval(intervalRef.current)
    dispatch(setErrorCode(-99999))
    dispatch(setChargerStep('charger-fail'))
    return history.replace('/charger')
  }

  // if (loading) {
  //   return (
  //     <AppLayout
  //       headerOptions={{
  //         title: 'Payment'
  //       }}
  //     >
  //       <Spin
  //         spinning
  //         style={{
  //           minHeight: '80vh',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           display: 'flex',
  //         }}
  //       />
  //     </AppLayout>
  //   )
  // }

  const renderPaymentMethod = () => {
    if (_.isEmpty(order)) {
      return (
        <Row style={{ height: '70vh' }} align="middle" justify="center">
          <Col span={24}>
            <h2 style={{ textAlign: 'center' }}>{t('missing_order_key')}</h2>
            <Button
              type="white"
              htmlType="submit"
              size="large"
              onClick={() => history.replace('/')}
              style={{ ...DefaultStyles.button }}
            >
              <span style={{ ...DefaultStyles.buttonText }}>{t('back_to_home')}</span>
            </Button>
          </Col>
        </Row>
      )
    }

    // min charge - 4 Dollors
    if (order.final_price >= 4) {
      return (
        <StripeCheckoutForm
          setLoading={setLoading}
          order={order}
          price={order.final_price}
          displayItems={[
            {
              label: _.toString(selectedPlan.name),
              amount: order.final_price * 100
            }
          ]}
          checkOrderStatus={checkOrderStatus}
          onSuccessCallBack={() => {
            started.current = true
            setLoading(false)
          }}
        />
      )
    }
  }

  const goBack = async () => {
    if (goBackLoading) return;
    switch (charger.selectedPID.plan_category) {
      case "HourlyNight":
      case "DayNight": {
        // dispatch(setChargerInit())
        setGoBackLoading(true)
        await Service.call('put', `/api/web/order/order_info/cancel/${order.order_key}`)
        const resp = await Service.call('get', `/api/web/user_ev/${order.user_ev_id}`)

        setGoBackLoading(false)
        dispatch(setSelectedCar({
          ...resp.data,
          brand: resp.data.ev_brand,
          model: resp.data.ev_model,
        }))
        dispatch(setChargerStep('payment-detail'))
        history.push('/charger')
        break;
      }
      case "Monthly": {
        // dispatch(setMonthlyInit())
        setGoBackLoading(true)
        await Service.call('put', `/api/web/order/order_info/cancel/${order.order_key}`)
        setGoBackLoading(false)
        dispatch(setMonthlyStep('payment-detail'))
        history.push('/subscription');
        break;
      }
      case "UltraFast": {
        // dispatch(setBookingInit())
        setGoBackLoading(true)
        await Service.call('put', `/api/web/order/order_info/cancel/${order.order_key}`)
        setGoBackLoading(false)
        dispatch(setBookingStep('payment-detail'))
        history.push('/booking');
        break;
      }
      default: break;
    }
  }

  return (
    <SkeletonScreen loading={loading}>
      <AppLayout
        headerOptions={{
          leftComponent: !_.isEmpty(charger.selectedPID) && order.status === 'placed' && (
          <LeftCircleFilled
            onClick={goBack}
            style={DefaultStyles.leftArrow}
          />
          ),
          title: t('payment')
        }}
      >
        {
          renderPaymentMethod()
        }
      </AppLayout>
    </SkeletonScreen>
  );
}

export default ChargerPayment;

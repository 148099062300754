import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { setSelectedModel, setMyCarStep } from '../../redux/actions/common'
import * as Service from '../../core/Service';
import AppLayout from '../AppLayout'
import { COLORS, DefaultStyles, FONTS } from '../../constants';

const ModelForm = ({
  setLoading
}) => {
  const { t } = useTranslation()
  const app = useSelector((state) => state.app)
  const car = useSelector((state) => state.car)
  const dispatch = useDispatch()
  const { selectedModel, selectedBrand } = car;
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getDataList()
  }, [])

  const getDataList = async() => {
    setLoading(true)
    let dataSource = []
    try {
      const resp = await Service.call('get', `/api/web/ev_model/brand/${selectedBrand.id}`);
      dataSource = _.orderBy(resp.data, ["status", 'utime'], ["asc", 'desc'])
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(dataSource);
    }
    setLoading(false)
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('select_model'),
        leftComponent: (
          <LeftCircleFilled
            onClick={() => dispatch(setMyCarStep('select-brand'))}
            style={DefaultStyles.leftArrow}
          />
        )
      }}
    >
      <Row justify="space-between" align="middle" style={{ padding: 5 }}>
        <Col span={24}>
          {_.map(dataList, (item) => {
            return (
              <Row
                align="middle"
                justify="space-between"
                gutter={[0, 0]}
                style={{
                  ...styles.card,
                  backgroundColor: '#fff',
                  border: item.id === app.default_car.id ? `2px solid ${COLORS.primary}` : '2px solid #F5F5F5'
                }}
                onClick={() => {
                  dispatch(setSelectedModel(item))
                  dispatch(setMyCarStep('save-car-overview'))
                }}
              >
                <Col span={4} style={{ textAlign: 'center' }}>
                  <img
                    alt={selectedBrand.name}
                    style={styles.brandLogo}
                    src={selectedBrand.path ? `${app.origin}/${selectedBrand.path}` : '/assets/default.png'}
                  />
                </Col>
                <Col span={9}>
                  <Row style={{ textAlign: 'left' }}>
                    <Col span={24} style={DefaultStyles.subHeader}>{item.name}</Col>
                    <Col span={24} style={DefaultStyles.subBody}>
                      <Row>
                        <img alt="" src="/assets/battery.svg" />
                        {item.battery_size}
                        {' '}
                        kWh
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={9} style={{ textAlign: 'center' }}>
                  <img
                    alt={item.name}
                    style={styles.carImage}
                    src={item.path ? `${app.origin}/${item.path}` : '/assets/default.png'}
                  />
                </Col>
              </Row>
            )
          })}
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  card: {
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    marginBottom: 15,
    minHeight: 110,
    padding: 15,
    position: 'relative',
    transition: 'all 0.3s ease-in-out'
  },
  buttonIcon: {
    fontSize: 20,
    color: COLORS.white,
    fontWeight: 'bold'
  },
  brandLogo: {
    width: '70%',
    objectFit: 'contain',
    aspectRatio: '1/1'
  },
  carImage: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '16/9'
  },
}


export default ModelForm;

import React, { useState, useEffect } from 'react';
import {
  Button, Col, Input, Row, Select, Form, notification, message
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';

const { TextArea } = Input;

const ContactUs = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.app.user);

  useEffect(() => {
    form.setFieldsValue({
      name: user.name,
      email: user.email,
    })
  }, [])

  const onFinish = async (formData) => {
    const dataObj = { ...formData, mobile: user.phone }
    setLoading(true)
    const resp = await Service.call('post', '/api/web/contact_us_record', dataObj)
    setLoading(false)
    if (resp.errorCode) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    notification.success({
      message: t('submit_success'),
    });

    form.resetFields()
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('contact_us'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.goBack();
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row justify="center">
        <Col
          span={24}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              rules={[{ required: true, message: t('required') }]}
            >
              <Input
                style={styles.textInput}
                size="large"
                placeholder={t('name')}
              />
            </Form.Item>
            <Form.Item
              name="email"
              validateFirst
              rules={[{
                required: true,
                message: t('required')
              },
              {
                type: 'email',
                message: t('incorrect_email_format')
              }]}
            >
              <Input
                style={styles.textInput}
                size="large"
                placeholder={t('email')}
              />
            </Form.Item>
            <Form.Item
              name="comment"
              rules={[{ required: true, message: t('required') }]}
            >
              <TextArea
                style={{ ...styles.textInput, paddingTop: 10, resize: 'none' }}
                placeholder={t('comment')}
                rows={10}
                autoSize={{ minRows: 10 }}
              />
            </Form.Item>
            <div style={{ margin: '30px 0px' }}>
              <Button
                loading={loading}
                style={styles.button}
                type="primary"
                htmlType="submit"
              >
                {t('send')}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  textInput: {
    ...FONTS.body,
    backgroundColor: COLORS.gray,
    color: COLORS.dark,
    width: '100%',
    height: 64,
    borderRadius: 10,
    border: 'none',
  },
  button: {
    backgroundColor: COLORS.primary,
    ...FONTS.subHeader,
    width: '100%',
    height: 54,
    borderRadius: 10,
    border: 'none',
  }
};

export default ContactUs;

import _ from 'lodash';
import * as types from './types';
// import AsyncStorage from '@react-native-community/async-storage';
// import * as Main from '../../core/Main';

export function setConfig(appConfig) {
  return {
    type: types.SET_CONFIG,
    data: appConfig,
  };
}

export function setUser(user) {
  return {
    type: types.SET_USER,
    data: user
  };
}

export function setSiteMeta(site_meta) {
  return {
    type: types.SET_SITE_META,
    data: site_meta
  };
}

export function setAuth(state) {
  return {
    type: types.SET_AUTH,
    data: state
  };
}

export function setModal(state) {
  return {
    type: types.SET_MODAL,
    data: state
  };
}

export function setOrigin(admin) {
  return {
    type: types.SET_ORIGIN,
    data: admin
  };
}

export function setLoading(loading) {
  return {
    type: types.SET_LOADING,
    data: loading
  };
}

export function setLanguage(lang) {
  return {
    type: types.SET_LANGUAGE,
    data: lang
  }
}

export function setLogoutWay(logoutWay) {
  return {
    type: types.SET_LOGOUTWAY,
    data: logoutWay
  };
}

export function setLocation(location) {
  return {
    type: types.SET_LOCATION,
    data: location
  };
}

export function setMyCarInit() {
  return {
    type: types.SET_MY_CAR_INIT,
    data: {}
  };
}

export function setSelectedCar(car) {
  return {
    type: types.SET_SELECTED_CAR,
    data: car
  };
}

export function setChargerInit() {
  return {
    type: types.SET_CHARGER_INIT,
    data: {}
  };
}

export function setChargerStep(step) {
  return {
    type: types.SET_CHARGER_STEP,
    data: step
  };
}

export function setSelectedPID(pid) {
  return {
    type: types.SET_SELECTED_PID,
    data: pid
  };
}

export function setSelectedPlan(plan) {
  return {
    type: types.SET_SELECTED_PLAN,
    data: plan
  };
}

export function setSelectedCarPark(carPark) {
  return {
    type: types.SET_SELECTED_CAR_PARK,
    data: carPark
  };
}

export function setSelectedCoupon(coupon) {
  return {
    type: types.SET_SELECTED_COUPON,
    data: coupon
  };
}

export function setSelectedDiscount(discount) {
  return {
    type: types.SET_SELECTED_DISCOUNT,
    data: discount
  };
}


export function setChargerStatus(charger_status = {
  selectedPID: {},
  selectedPlan: {},
  selectedCarPark: {},
  selectedCoupon: {},
  userChargeRecord: {},
  selectedDiscount: 0,
  startTime: 0,
  charged_time: null,
  refundStatus: 0,
  refundTime: 0,
}) {
  return {
    type: types.SET_CHARGER_STATUS,
    data: charger_status
  };
}

export function setUserChargeRecord(user_charge) {
  return {
    type: types.SET_USER_CHARGE_RECORD,
    data: user_charge
  };
}

export function setStartTime(start_time) {
  return {
    type: types.SET_START_TIME,
    data: start_time
  };
}

export function setChargedTime(charged_time) {
  return {
    type: types.SET_CHARGED_TIME,
    data: charged_time
  };
}

export function setMyCarStep(step) {
  return {
    type: types.SET_MY_CAR_STEP,
    data: step
  };
}


export function setSelectedBrand(brand) {
  return {
    type: types.SET_SELECTED_BRAND,
    data: brand
  };
}

export function setSelectedModel(model) {
  return {
    type: types.SET_SELECTED_MODEL,
    data: model
  };
}

export function setLicense(license) {
  return {
    type: types.SET_LICENSE,
    data: license
  };
}

export function setOrder(order) {
  return {
    type: types.SET_ORDER,
    data: order
  };
}

export function setBookingInit(data) {
  return {
    type: types.SET_BOOKING_INIT,
    data
  };
}

export function setBookingStep(data) {
  return {
    type: types.SET_BOOKING_STEP,
    data
  };
}

export function setSelectedBooking(data) {
  return {
    type: types.SET_SELECTED_BOOKING,
    data
  };
}

export function setSelectedParkingSlot(data) {
  return {
    type: types.SET_SELECTED_PARKING_SLOT,
    data
  };
}

export function setSelectedLocation(data) {
  return {
    type: types.SET_SELECTED_LOCATION,
    data
  };
}

export function setSelectedTimeSlot(data) {
  return {
    type: types.SET_SELECTED_TIMESLOT,
    data
  };
}

export function setMonthlyInit(data) {
  return {
    type: types.SET_MONTHLY_INIT,
    data
  };
}

export function setMonthlyStep(data) {
  return {
    type: types.SET_MONTHLY_STEP,
    data
  };
}

export function setSelectedSubscription(data) {
  return {
    type: types.SET_SELECTED_SUBSCRIPTION,
    data
  };
}

export function setMonthlySelectedDate(data) {
  return {
    type: types.SET_MONTHLY_SELECTED_DATE,
    data
  };
}

export function setErrorMessage(data) {
  return {
    type: types.SET_ERROR_MESSAGE,
    data
  };
}

export function setErrorCode(data) {
  return {
    type: types.SET_ERROR_CODE,
    data
  };
}

export function setURLParams(data) {
  return {
    type: types.SET_URL_PARAMS,
    data
  };
}

export function setDefaultCar(data) {
  return {
    type: types.SET_DEFAULT_CAR,
    data
  };
}

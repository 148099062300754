import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, notification, message, Typography
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import QrReader from 'react-qr-reader'
import { useTranslation } from 'react-i18next';
import {
  setChargerStep, setErrorCode, setErrorMessage, setOrder, setSelectedPID
} from '../../redux/actions/common'
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';

import { COLORS, FONTS } from '../../constants';

const queryString = require('query-string');

const ChargerScan = ({
  setLoading,
  navigateCallback
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const config = useSelector((state) => state.app.config);

  useEffect(() => {
    getChargingStatus()
  }, [])

  useEffect(() => {
    checkPid()
  }, [queryString.parse(location.search).pid])


  const getChargingStatus = async () => {
    try {
      setLoading(true)
      const resp = await Service.call('get', '/api/web/user/charging');
      setLoading(false)
      if (_.isEmpty(resp.data)) return;

      if (_.includes(['Charging', 'Finishing'], resp.data.pid.OCPP_charger_status)) {
        history.replace('/')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkPid = () => {
    const parsed = queryString.parse(location.search);
    if (!parsed.pid) return;
    getPID(parsed.pid)
  }

  const handleScan = (data) => {
    if (data) {
      const url = new URL(data)
      const parsed = queryString.parse(url.search);

      if (_.isEmpty(parsed) || _.isEmpty(parsed.pid)) {
        return message.warning('Invalid QR Code')
      }

      getPID(parsed.pid)
    }
  }

  const handleError = (err) => {
    console.error(err)
  }

  const getPID = async (PID) => {
    if (_.isEmpty(PID)) {
      return notification.warning({
        message: 'Invalid QR Code.'
      })
    }

    setLoading(true)
    const resp = await Service.call('get', `/api/web/start_charge/pid/check_available/${PID}`)
    if (resp.status <= 0) {
      setLoading(false);
      dispatch(setErrorMessage(Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false)))
      dispatch(setErrorCode(resp.errorCode))
      dispatch(setChargerStep('charger-fail'))
      return
      // return notification.error({
      //   message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
      // });
    }
    await Service.call('post', '/api/web/user_agent_log', {
      web_version: config.version,
      user_agent: navigator.userAgent
    })
    setLoading(false)

    const {
      pid, PLAN_CATEGORY, plan, orderInfo
    } = resp.data
    dispatch(setSelectedPID(pid))
    if (!_.isUndefined(orderInfo)) {
      dispatch(setOrder(orderInfo))
    }
    if (pid.OCPP_charger_status !== 'Available' && pid.OCPP_charger_status !== 'Preparing') return;
    if (pid.OCPP_charger_status === "Available") {
      return dispatch(setChargerStep('charger-notice'))
    }

    navigateCallback(pid, plan, PLAN_CATEGORY)
  }

  return (
    <>
      <div
        // style={{
        //   position: 'absolute',
        //   left: 0,
        //   top: 0,
        //   zIndex: 2,
        //   width: '100vw',
        //   height: '100vh',
        //   touchAction: 'none',
        //   backgroundColor: 'transparent'

        // }}
        style={{
          height: '120vh',
          transition: 'all 0.2s ease-in',
          width: '100%',
          touchAction: 'none'
        }}
      >
        <Mask />
        <QrReader
          delay={3000}
          onError={handleError}
          onScan={handleScan}
          className="charger-qr-code-scan"
        />
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
            width: '100vw',
            backgroundColor: 'transparent'
          }}
        >
          <Row
            align="middle"
            justify="center"
            style={{
              minHeight: 90,
              // marginBottom: 30
            }}
          >
            <Col span={22}>
              <Row justify="center" align="middle">
                <Col span={4} style={{ textAlign: 'left'}}>
                  <LeftCircleFilled
                    onClick={() => {
                      history.push('/')
                    }}
                    style={{ fontSize: 32, textAlign: 'left', color: COLORS.white }}
                  />
                </Col>
                <Col span={16} style={{ textAlign: 'center'}}>
                  <Typography.Title style={{ ...styles.title }}>{t('scan_qr_code')}</Typography.Title>
                </Col>
                <Col span={4} style={{ textAlign: 'right'}} />
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{
          position: 'absolute',
          top: 90 + (window.innerHeight - 250) / 2 - window.innerHeight * 0.1 + 200,
          right: 0,
          zIndex: 2
        }}
        >
          <Row justify="center" style={{ width: '100vw' }}>
            <Col span={24}>
              <Row justify="center">
                <Col span={24} />
                <Col
                  span={24}
                  style={{
                    fontSize: 12, color: '#fff', fontWeight: '600', textAlign: 'center'
                  }}
                >
                  {t('hold_the_qr_code_into_the_frame_above')}
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: 12, color: '#fff', fontWeight: '600', textAlign: 'center', margin: '30px 0px'
                  }}
                >
                  {t('or')}
                </Col>
                <Col
                  xs={9}
                  sm={8}
                >
                  <Button
                    style={{ ...styles.button }}
                    onClick={() => {
                      dispatch(setChargerStep('select-pid'))
                    }}
                  >
                    <span style={{ ...styles.buttonText }}>{t('input_pid')}</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

const Mask = () => {
  return (
    <svg
      height="120vh"
      width={window.innerWidth}
      pointerEvents="none"
      style={{
        position: 'absolute', top: 0, left: 0, zIndex: 1
      }}
    >
      <g>
        {/* Constructing Mask */}
        <defs>
          <mask id="clip">
            {/* BG */}
            <rect fill="white" width="100%" height="100%" />
            {/* Center */}
            <rect
              x={(window.innerWidth - 250) / 2}
              y={(window.innerHeight - 250) / 2 - window.innerHeight * 0.1}
              width="250"
              height="250"
              rx={0}
              fill="black"
            />
          </mask>
        </defs>
        <rect
          width="100%"
          height="100%"
          fill="rgba(0,0,0,0.7)"
          mask="url(#clip)"
        />
        <rect
          x={(window.innerWidth - 250) / 2}
          y={(window.innerHeight - 250) / 2 - window.innerHeight * 0.1}
          width="250"
          height="250"
          // rx={25}
          stroke="#FFC709"
          strokeWidth="10"
          fill="rgba(0,0,0,0)"
        />
      </g>
    </svg>
  )
}

const styles = {
  title: {
    margin: 0,
    fontSize: 26,
    fontWeight: 600,
    color: '#fff'
  },
  button: {
    minWidth: 130,
    width: '100%',
    borderRadius: 10,
    backgroundColor: 'transparent',
    border: '1px solid #FFFFFF',
    height: 44
  },
  buttonText: {
    ...FONTS.body,
    color: COLORS.white,
  },
  input: {
    height: 50,
    borderRadius: 10
  }
}


export default ChargerScan;

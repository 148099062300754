import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SuccessPage from '../components/SuccessPage';

function RegistrationSuccess() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <SuccessPage
      title={t('register_success')}
      subTitle={t('click_back_to_login')}
      buttonTitle={t('back_to_login')}
      buttonAction={() => history.push('login')}
    />
  );
}

export default RegistrationSuccess;

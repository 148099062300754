import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import { ActionCreators } from './redux/actions';

// export default function PrivateRoute(props) {
//   const app = useSelector((state) => state.app);

//   return (
//     <Route
//       render={() => {
//         return (
//           app.auth === true
//             ? <Component />
//             : <Redirect to="/login" />
//         )}}
//     />
//   );
// }

class PrivateRoute extends Component {
  render() {
    // const {component: Component, app, ...rest} = this.props;
    const {
      component: AppComponent,
      privilege,
      app,
    } = this.props;

    if (app.auth === null) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return (
        <div style={{position: 'absolute', top: '50%', left: '50%'}}>
          <Spin indicator={antIcon} />
        </div>
      )
    }

    return (
      <Route
        render={(props) => {
          return (
            app.auth
              ? <AppComponent {...props} />
              : <Redirect to={app.url_params && !_.isEmpty(app.url_params) ? `/login/?pid=${app.url_params.pid}` : "/login"} />
          )
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);

import Immutable from 'seamless-immutable';
import * as types from '../actions/types';

const initialState = Immutable({
  order: {}
});

const appReducer = {
  orders: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_ORDER: {
        return {...state, order: action.data };
      }
      default:
        return state;
    }
  }
};

export default appReducer;

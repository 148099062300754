import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button
} from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppLayout from './AppLayout'
import { setErrorMessage } from '../redux/actions/common';
import { COLORS, DefaultStyles } from '../constants';

const NoResponsePage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <AppLayout
      headerOptions={{
        hide: true
      }}
      containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      isCenter
    >
      <Row justify="center" align="middle">
        <Col span={24}>
          <Row justify="center" gutter={[0, 20]}>
            {/* <Col span={4}>
              <img src="/assets/warning.png" alt="Warning" style={styles.image} />
            </Col> */}
            <Col
              span={15}
              style={{
                ...DefaultStyles.subHeader,
                textAlign: 'center',
                color: COLORS.red
              }}
            >
              <img src="/assets/disconnect.png" alt="Warning" style={styles.image} />
            </Col>
          </Row>
          <Row justify="center" gutter={[0, 20]}>
            <Col
              span={24}
              style={{
                ...DefaultStyles.header,
                textAlign: 'center',
                color: COLORS.red
              }}
            >
              {t('charger_disconnect')}
            </Col>
            <Col
              span={24}
              style={{
                ...DefaultStyles.body,
                textAlign: 'center',
                color: COLORS.darkGrey
              }}
            >
              <Row justify="center" style={{ textAlign: 'center', marginBottom: 20 }}>
                {t('disconnect_desc_1')}
              </Row>
              <Row justify="center">
                {t('disconnect_desc_2')}
              </Row>
            </Col>
            <Col span={24}>
              <Button
                style={DefaultStyles.button}
                onClick={() => {
                  dispatch(setErrorMessage(''))
                  history.replace('/?redirect=0')
                }}
              >
                <span style={DefaultStyles.buttonText}>{t('back_to_home')}</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
}


export default NoResponsePage;

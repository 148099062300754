import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, notification, Typography
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import {
  setChargerStep, setOrder, setSelectedCar, setSelectedCarPark, setSelectedPlan
} from '../../redux/actions/common'

import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import CarParkItem from '../CarParkItem';
import { COLORS, DefaultStyles, FONTS } from '../../constants';
import CarItem from '../my-car/CarItem';

const {Title, Paragraph} = Typography

const Plan = ({
  setLoading
}) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const lang = i18n.language;
  const charger = useSelector((state) => state.charger)
  const app = useSelector((state) => state.app)
  const {
    selectedPID, selectedPlan, selectedCarPark, selectedCar
  } = charger
  const [planList, setPlanList] = useState([]);
  const [plan, setPlan] = useState(selectedPlan);
  const [available, setAvailable] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    getPID()
  }, [selectedPID])

  const getPID = async () => {
    setLoading(true)
    const resp = await Service.call('get', `/api/web/lookup/pid_plan_lookup/${selectedPID.pid}`)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    setPlanList(resp.data.planList)
    setAvailable(resp.data.available)
    if (!_.isEmpty(resp.data.planList)) {
      setPlan(_.first(resp.data.planList))
    }
    dispatch(setSelectedCarPark(resp.data.carPark))
    setLoading(false)
  }

  const next = () => {
    dispatch(setOrder({}))
    dispatch(setSelectedPlan(plan))
    dispatch(setChargerStep('payment-detail'))
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('plan'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            dispatch(setSelectedPlan({}))
            dispatch(setSelectedCar({}))
            dispatch(setChargerStep('scan-charger'))
            history.push('/charger')
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <CarParkItem item={selectedCarPark} hideAddress />
      <CarItem
        onClickCar={() => {
          dispatch(setSelectedPlan({}))
          dispatch(setChargerStep('select-car'))
        }}
        item={selectedCar}
        mode="charger"
        containerStyle={{
          backgroundColor: '#fff',
          border: '2px solid #F0F0F0',
          boxShadow: '0px 3px 5px rgb(0 0 0 / 10%)'
        }}
      />
      <div style={{marginTop: 20}}>
        <Row gutter={[20, 0]} align="middle">
          <Col span={4}>
            <Title level={4}>
              <img
                alt="penalty_warning"
                style={styles.warningLogo}
                src="/assets/penalty_warning.png"
              />
            </Title>
          </Col>
          <Col span={20}>
            <Row>
              <Col style={DefaultStyles.subHeader}>{t('penalty_title')}</Col>
            </Row>
            <Row>
              <Col span={24} style={DefaultStyles.subBody}>
                {t('penalty_description', {penalty_amount: plan.penalty_amount, penalty_time: plan.penalty_time})}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ minHeight: '30vh', marginTop: 40 }}>
        {available && (
        <Row gutter={[0, 10]}>
          <Col span={24} style={styles.subBody}>{t('charging_plan')}</Col>
          {_.map(_.orderBy(planList, ['price'], ['asc']), (item) => {
            return (
              <Col
                key={item.id}
                span={24}
                onClick={() => setPlan(item)}
              >
                <PlanCard item={item} selectedPlan={plan} lang={lang} />
              </Col>
            )
          })}
        </Row>
        )}
      </div>
      <Row justify="center" style={{ padding: '30px 0px'}}>
        <Col span={24}>
          <Button
            disabled={_.isEmpty(plan)}
            style={{ ...styles.button, opacity: _.isEmpty(plan) ? 0.6 : 1 }}
            onClick={next}
          >
            <span style={{ ...styles.buttonText }}>{t('next')}</span>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  )
}

const PlanCard = ({ item, selectedPlan = {}, lang = 'en' }) => {
  return (
    <div
      style={{
        borderRadius: 10,
        marginBottom: 10,
        minHeight: 100,
        color: COLORS.primary,
        padding: '10px 15px',
        backgroundColor: selectedPlan.id === item.id ? COLORS.light : COLORS.white,
        border: selectedPlan.id === item.id ? `1px solid ${COLORS.light}` : `1px solid ${COLORS.primary}`,
      }}
    >
      <Row
        align="middle"
        justify="center"
      >
        <Col span={16}>
          <Row gutter={[0, 5]}>
            <Col span={24} style={styles.subHeader}>{lang !== 'en' && item.name_chinese ? item.name_chinese : item.name}</Col>
            <Col span={24} style={styles.subBody}>
              {`${moment(item.start_time, 'HH:mm:ss').format('HH:mm A')} - ${moment(item.end_time, 'HH:mm:ss').format('HH:mm A')}`}
            </Col>
            <Col
              span={24}
              style={{
                ...styles.subBody,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img src="/assets/battery.svg" alt="battery" style={{ width: 25, height: 25 }} />
              {item.charger_type}
              {item.kwh}
              kWh
            </Col>
          </Row>
        </Col>
        <Col
          span={8}
          style={{
            ...styles.planPrice,
            textAlign: 'end'
          }}
        >
          AU$
          {item.price}
        </Col>
      </Row>
    </div>
  )
}

const styles = {
  banner: {
    ...FONTS.banner,
    color: COLORS.primary
  },
  subHeader: {
    ...FONTS.subHeader,
    color: COLORS.primary
  },
  subBody: {
    ...FONTS.subBody,
    color: COLORS.primary
  },
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.primary,
    borderColor: 'transparent',
    height: 54
  },
  buttonText: {
    ...FONTS.subHeader,
    color: COLORS.white,
  },
  warningLogo: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '1/1'
  },
  status: {
    ...FONTS.tag,
    color: COLORS.white,
    textAlign: 'center',
    borderRadius: 5,
    padding: 5
  },
  planPrice: {
    ...FONTS.planPrice,
    color: COLORS.primary
  }
}


export default Plan;
export { PlanCard };

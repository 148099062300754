import React, { useEffect, useState } from 'react';
import {
  Button, Col, Input, Row, List
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment/min/moment-with-locales'
import * as Service from '../core/Service';
import AppLayout from '../components/AppLayout';
import { FONTS, COLORS, DefaultStyles } from '../constants'
import SkeletonScreen from '../components/SkeletonScreen';

const Notice = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [notificationList, setNotificationList] = useState([])

  useEffect(() => {
    getNoticification();
  }, [])

  const getNoticification = async () => {
    setLoading(true)
    const resp = await Service.call('get', '/api/web/notification');
    setLoading(false)
    setNotificationList(resp.data)
  }

  const getShowContent = (showContent) => {
    let urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;
    let urls = showContent.match(urlRegex);
    if (_.isNull(urls)) {
      return <div>{showContent}</div>;
    }
    const newText = showContent.split(' ').map((word) => {
      let wordComponent = '';
      urls.forEach((entity) => {
        if (word === entity) {
          let finalUrl = word;
          if (!_.startsWith(word, 'https')) {
            finalUrl = `https://${finalUrl}`;
          }
          wordComponent = (
            <>
              <a href={finalUrl} target="_blank" rel="noreferrer">
                {word}
              </a>{' '}
            </>
          );
        }
      });
      if (wordComponent) {
        return wordComponent;
      }
      return `${word} `;
    });
    return <span>{newText}</span>;
  };

  return (
    <SkeletonScreen loading={loading}>
      <AppLayout
        color="#fff"
        headerOptions={{
          title: t('notice'),
          leftComponent: <LeftCircleFilled
            onClick={() => {
              history.push('/');
            }}
            style={DefaultStyles.leftArrow}
          />
        }}
      >
        <Row>
          <Col span={24}>
            <List
              itemLayout="vertical"
              dataSource={notificationList}
              rowKey="subscritpion_id"
              renderItem={(item) => (
                <List.Item key={item.notification_id}>
                  <Row align="middle" justify="space-between">
                    <Col span={20} style={{ paddingRight: 20 }}>
                      <div style={{ ...styles.title}}>{item.subtitle}</div>
                      {_.map(_.split(item.content, '\n'), (wording, index) => {
                        if (_.includes(wording, '|')) {
                          const carParkName = _.first(_.split(wording, '|'))
                          const message = _.last(_.split(wording, '|'))
                          return (
                            <div key={index} style={{ ...styles.content}}>
                              <span style={{fontWeight: 'bold'}}>{carParkName}</span>
                              {' '}
                              {message}
                            </div>
                          )
                        }
                        return (
                          <div key={index} style={{ ...styles.content}}>
                            {getShowContent(wording)}
                          </div>
                        )
                      })}
                    </Col>
                    <Col span={4} style={{ ...styles.time_label}}>
                      {moment.unix(item.display_time).locale(i18n.language === 'en' ? 'en' : 'zh-hk').fromNow('dd')}
                      {t('ago')}
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </AppLayout>
    </SkeletonScreen>
  );
}

const styles = {
  title: {
    ...FONTS.subBody,
    color: COLORS.primary,
    fontWeight: 'bold'
  },
  content: {
    ...FONTS.subBody,
    color: COLORS.primary
  },
  time_label: {
    ...FONTS.subBody,
    color: COLORS.secondary,
    textAlign: 'end'
  },
}

export default Notice;

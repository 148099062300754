import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button
} from 'antd';
import {
  PlusOutlined,
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {
  setSelectedCar,
  setChargerStep,
  setMyCarStep,
  setBookingStep,
  setChargerInit,
  setMonthlyStep,
  setSelectedParkingSlot,
  setMyCarInit,
  setDefaultCar
} from '../../redux/actions/common'
import * as Service from '../../core/Service';
import AppLayout from '../AppLayout'
import { COLORS, DefaultStyles } from '../../constants';
import CarItem from './CarItem';
import { useTranslation } from 'react-i18next';

const MyCarList = ({
  setLoading,
  setCallRefresh,
  callRefresh,
  mode = 'car-list' // mode -> car-list, charger, booking, monthly
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const app = useSelector((state) => state.app)
  const charger = useSelector((state) => state.charger)
  const booking = useSelector((state) => state.booking)
  const [dataList, setDataList] = useState([])
  const [allowEdit, setAllowEdit] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    if (callRefresh) {
      setLoading(true)
      getDataList()
    }
  }, [callRefresh])

  useEffect(() => {
    autoPick()
  }, [dataList])

  const getDataList = async() => {
    let dataSource = []
    try {
      const resp = await Service.call('get', "/api/web/user_ev");
      dataSource = resp.data
      const {
        brandList,
        modelList,
        userEVList
      } = resp.data;

      dataSource = _.map(userEVList, (item) => {
        return ({
          ...item,
          brand: _.find(brandList, { id: item.brand_id }),
          model: _.find(modelList, { id: item.model_id })
        })
      })

      dataSource = _.orderBy(dataSource, ["status", 'utime'], ["asc", 'desc'])
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(dataSource);
    }
    setLoading(false)
    setCallRefresh(false)
  }

  const autoPick = () => {
    // ignore auto pick in my car page
    if (mode === 'car-list') return;
    // no car list
    if (_.isEmpty(dataList)) return;
    // pick already
    if (!_.isEmpty(charger.selectedCar)) return;
    // select the only one car
    if (dataList.length === 1) {
      dispatch(setSelectedCar(_.first(dataList)))
      return navigate()
    }
    // has default car
    if (app.default_car?.id !== 0) {
      let defaultCar = _.find(dataList, { id: app.default_car?.id })
      if (!defaultCar) return;
      dispatch(setSelectedCar(defaultCar))
      return navigate()
    }
  }

  const navigate = () => {
    if (mode === 'charger' && _.isEmpty(charger.selectedPlan)) {
      dispatch(setChargerStep('select-plan'))
      return
    }
    
    if (!_.isEmpty(charger.selectedPlan)) {
      if (mode === 'booking' || mode === 'monthly' || mode === 'charger') {
        dispatch(setChargerStep('detect-plan'))
        return
      }
    }
  }

  const pickCar = (item) => {
    if (mode === 'charger') {
      dispatch(setSelectedCar(item))
      dispatch(setChargerStep('select-plan'))
      return
    }

    if (mode === 'monthly' || mode === 'booking') {
      dispatch(setSelectedCar(item))
      dispatch(setChargerStep('detect-plan'))
    }
  }

  const goBack = () => {
    if (mode !== 'car-list') return;
    // my car page go back home
    return history.replace('/')
  }

  const addCar = () => {
    dispatch(setMyCarInit({}))
    dispatch(setMyCarStep('licence-form'))
  }

  const getBackgroundColor = (id) => {
    // car list page
    if (mode === 'car-list') {
      // has default car
      if (app.default_car?.id === id) {
        return '#fff';
      }

      // do not have default car
      return '#F5F5F5'
    }

    // select car page
    if (charger.selectedCar?.id === id) return '#E5E5E5'

    // default background color
    return '#F5F5F5'
  }

  const onEdit = () => {
    setAllowEdit(!allowEdit)
  }

  return (
    <AppLayout
      headerOptions={{
        layout: {
          left: 5,
          middle: 14,
          right: 5
        },
        title: _.includes(['charger', 'booking'], mode) ? t('select_car') : t('my_car'),
        leftComponent: _.includes(['car-list'], mode) && (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={goBack}
          />
        ),
        rightComponent: _.includes(['car-list'], mode) && (
          <div
            style={{
              // ...DefaultStyles.buttonOutline,
              padding: '0px 10px',
              height: '100%'
            }}
            onClick={onEdit}
            type="text"
          >
            <span style={{
              ...DefaultStyles.body,
              fontWeight: 'bold',
            }}>{allowEdit ? 'Finish' : 'Edit' }</span>
          </div>
        )
      }}
    >
      <Row justify="center">
        <Col span={24}>
          {_.map(dataList, (item) => {
            return (
              <CarItem
                allowEdit={allowEdit}
                removeCallback={() => {
                  if (app.default_car.id === item.id) {
                    dispatch(setDefaultCar({ id: 0 }))
                  }
                  getDataList()
                }}
                onClickCar={pickCar}
                key={item.id}
                item={item}
                mode={mode}
                containerStyle={{
                  backgroundColor: getBackgroundColor(item.id),
                  border: item.id === app.default_car.id ?  `2px solid ${COLORS.primary}` :  '2px solid #F5F5F5'
                }}
              />
            )
          })}
        </Col>
        <Col span={24} style={{ marginTop: 10 }}>
          <Button
            style={DefaultStyles.button}
            icon={<PlusOutlined style={{ ...styles.buttonIcon}} />}
            onClick={addCar}
          >
            <span style={DefaultStyles.buttonText}>{t('add_new_car')}</span>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  )
}

const styles = {
  card: {
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    marginBottom: 10,
    minHeight: 80,
    padding: 15,
  },
  buttonIcon: {
    fontSize: 20,
    color: COLORS.white,
    fontWeight: 'bold'
  },
  brandLogo: {
    width: '70%',
    objectFit: 'contain',
    aspectRatio: '1/1'
  },
  carImage: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '16/9'
  },
}


export default MyCarList;

import React from 'react'
import { Row, Col, Progress } from 'antd'
import _ from 'lodash'
import { COLORS, FONTS } from '../../constants'

const StatusBar = ({
  canStop = true, status = 'Charging', value = 0, total = 0, mode = "", targetEnergy
}) => {
  let current = value === null || value === undefined || value === 0 ? 0 : _.round(value, 1)

  const percentage = _.isNaN(_.round(current / total, 1)) ? 0 : _.round(current / total, 1) * 100

  return (
    <Row justify="center" style={{ marginBottom: 24 }}>
      <Col span={24}>
        <Row justify="space-between" align="middle" gutter={[0, 10]}>
          <Col span={3}>
            {
              current >= total || mode === "stop" || status === 'Finishing' ? (
                <img
                  className=""
                  src="/assets/flash_green.svg"
                  style={{ aspectRatio: '1/1', objectFit: 'contain', width: '80%' }}
                  alt="charging car"
                />
              ) : (
                <img
                  className="blink-animation"
                  src="/assets/flash_blue.svg"
                  style={{ aspectRatio: '1/1', objectFit: 'contain', width: '80%' }}
                  alt="charging car"
                />
              )
            }
          </Col>
          <Col span={18} style={{ position: 'relative' }}>
            <Progress
              className="charging-progress"
              strokeColor={{
                '0%': '#1A468F',
                '100%': '#FFC709',
              }}
              percent={_.round(percentage)}
              strokeWidth={30}
            />
            {(!canStop || current < targetEnergy) && (
            <div
              style={{
                width: 5, height: '100%', backgroundColor: 'red', position: 'absolute', top: 0, left: `${_.round(targetEnergy / total, 1) * 100}%`
              }}
            />
            )}
          </Col>
          <Col span={3} style={styles.percentText}>
            {`${_.round(percentage)}%`}
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={styles.chargingText}
          >
            {current}
            kWh /
            {' '}
            {total}
            kWh
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const styles = {
  chargingText: {
    ...FONTS.h5,
    color: COLORS.primary,
    textAlign: 'center'
  },
  percentText: {
    ...FONTS.subHeader,
    color: COLORS.primary,
    textAlign: 'center'
  }
}

export default StatusBar;

import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import GoogleMapReact from "google-map-react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Marker, { CurrentMarker } from "./Marker";
import MapStyles from "./MapStyles";
import SlidingUpPanel from "./SlidingUpPanel";
import { setSelectedCarPark } from "../../redux/actions/common";

// for car park
const CarParkMapView = ({ carPark, redirect }) => {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const charger = useSelector((state) => state.charger);
  const { selectedCarPark } = charger;
  const [center, setCenter] = useState({ lat: 22.302711, lng: 114.177216 });
  const [zoom, setZoom] = useState(15);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [geolocation, setGeolocation] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const location = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      setCenter(location);
      setGeolocation(location);
    });
  }, [navigator.geolocation]);

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    setMap(map);
    setMaps(maps);
  };

  return (
    // Important! Always set the container height explicitly
    <div
      style={{
        height: _.isEmpty(selectedCarPark) ? "100vh" : "80vh",
        transition: "all 0.2s ease-in",
        width: "100%",
        touchAction: "none",
      }}
    >
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: app.config.GOOGLE_API_KEY }}
        center={center}
        zoom={zoom}
        options={{
          zoomControl: false,
          fullscreenControl: false,
          styles: MapStyles,
        }}
        onDragEnd={(map) => setCenter(map.center.toJSON())}
        onClick={() => dispatch(setSelectedCarPark({}))}
        onZoomAnimationEnd={(value) => setZoom(value)}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {geolocation.lat !== 0 && geolocation.lng !== 0 && (
          <CurrentMarker
            lat={geolocation.lat}
            lng={geolocation.lng}
            title="My Location"
          />
        )}

        {_.map(carPark, (item) => {
          return (
            <Marker
              setZoom={setZoom}
              setCenter={setCenter}
              key={item.id}
              lat={item.lat}
              lng={item.lng}
              title={item.name}
              item={item}
              zoom={zoom}
            />
          );
        })}
      </GoogleMapReact>
      <SlidingUpPanel
        redirect={redirect}
        setCenter={setCenter}
        defaultGeolocation={center}
        geolocation={geolocation}
      />
    </div>
  );
};

export default CarParkMapView;

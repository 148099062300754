import React, { useEffect, useState, useCallback } from 'react';
import { Spin, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import MyCar from './MyCar'
import {
  ChargerScan,
  PIDForm,
  ChargerNotice,
  Plan,
  ChargerCoupon,
  ChargingStatus,
  PlanDetection,
  ConfirmStop,
  ChargingStopSuccess,
  ChargerPaymentDetail,
  ChargerStart
} from '../components/charger'
import FailPage from '../components/FailPage';
import NoResponsePage from '../components/NoResponsePage';
import { DefaultStyles } from '../constants';
import { setChargerStep, setSelectedPlan } from '../redux/actions/common';
import SkeletonScreen from '../components/SkeletonScreen';

const Charger = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(null)
  const charger = useSelector((state) => state.charger)
  const {
    step, selectedPID
  } = charger;
  const history = useHistory()
  const dispatch = useDispatch()

  const getCarMode = useCallback(() => {
    const { plan_category } = selectedPID
    // Walkin Plan
    if (plan_category === 'HourlyNight' || plan_category === 'DayNight') return 'charger'

    // Monthly Plan
    if (selectedPID.plan_category === 'Monthly') return 'monthly'
    if (selectedPID.plan_category === 'UltraFast') return 'booking'
  }, [selectedPID]);

  const navigateCallback = useCallback((pid, plan, PLAN_CATEGORY) => {
    switch (pid.plan_category_id) {
      // Booking Plan
      // Monthly Subscription Plan
      // Walkin Plan
      case PLAN_CATEGORY.ULTRA_FAST:
      case PLAN_CATEGORY.MONTHLY:
      case PLAN_CATEGORY.DAY_NIGHT:
      case PLAN_CATEGORY.HOURLY_NIGHT: {
        dispatch(setSelectedPlan(plan))
        dispatch(setChargerStep('select-car'))
        break;
      }
      default: {
        notification.info({message: t('pid_is_not_available_if_you_have_any_questions_please_contact_us')})
        break;
      }
    }
  }, [])

  const workFlow = () => {
    switch (step) {
      case 'scan-charger':
        return (
          <ChargerScan
            setLoading={setLoading}
            navigateCallback={navigateCallback}
          />
        )
      case 'select-pid':
        return (
          <PIDForm
            loading={loading}
            setLoading={setLoading}
            navigateCallback={navigateCallback}
          />
        )
      case 'charger-notice':
        return (
          <ChargerNotice
            navigateCallback={navigateCallback}
          />
        )
      case 'detect-plan':
        return (
          <PlanDetection
            loading={loading}
            setLoading={setLoading}
            setLoadingMessage={setLoadingMessage}
          />
        )
      case 'select-car':
        return (
          <MyCar mode={getCarMode()} />
        )
      case 'select-plan':
        return (
          <Plan setLoading={setLoading} />
        )
      case 'payment-detail':
        return (
          <ChargerPaymentDetail
            loading={loading}
            setLoading={setLoading}
          />
        )
      case 'select-coupon':
        return (
          <ChargerCoupon setLoading={setLoading} />
        )
      case 'start-charge':
        return (
          <ChargerStart
            loading={loading}
            setLoading={setLoading}
            setLoadingMessage={setLoadingMessage}
          />
        )
      case 'charging-status':
        return (
          <ChargingStatus setLoading={setLoading} />
        )
      case 'confirm-stop':
        return (
          <ConfirmStop
            loading={loading}
            setLoading={setLoading}
            setLoadingMessage={setLoadingMessage}
          />
        )
      case 'charging-stop-success':
        return (
          <ChargingStopSuccess />
        )
      case 'charger-fail':
        return (
          <FailPage />
        )
      case 'charger-no-response':
        return (
          <NoResponsePage />
        )
      default: return history.replace('/')
    }
  }

  return (
    <SkeletonScreen loading={loading && !loadingMessage}>
      <Spin
        spinning={loading}
        tip={loadingMessage}
        style={DefaultStyles.loadingOverlay}
      >
        {workFlow()}
      </Spin>
    </SkeletonScreen>
  )
}


export default Charger;

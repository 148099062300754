import React, { useEffect, useState } from "react";
import {
  Row, Col, Button, Input, notification, Collapse
} from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { COLORS, DefaultStyles, FONTS } from "../constants";
import Arrow from "../assets/chervon-down.svg";
import stylesModule from "./map.module.css";

const CarParkItemMap = ({
  item,
  showAvailability = false,
  showAvailabilityAcAndDc = false,
  hideAddress = false,
  customizeObj = {},
}) => {
  const [activeKey, setActiveKey] = useState([]);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const app = useSelector((state) => state.app);
  console.log("lang >>>", lang);
  const onPanelChange = (key) => {
    setActiveKey(key);
  };
  const isPanelOpen = (panelKey) => {
    return activeKey.includes(panelKey);
  };

  const defaultSetting = {
    reserveBtn: {
      onClick: () => {
        console.log("reserveBtn clicked");
      },
    },
    locationPin: {
      onclick: () => {
        console.log("locationPin clicked");
      },
    },
  };

  const settingObj = _.merge(defaultSetting, customizeObj);
  const { reserveBtn } = settingObj;

  if (_.isEmpty(item)) return null;
  let remark = item.remark;
  const languageCode = lang === "en" ? "en" : "zh";
  const regexPattern = new RegExp(`<car_park_desc_${languageCode}:\\s*(.*?)>`);
  const match = item.remark.match(regexPattern);
  if (match)remark = match[1];
  if (showAvailability) {
    return (
      <Row align="middle" style={{ marginBottom: 20 }}>
        <Col span={8} style={{ textAlign: "center" }}>
          <div
            alt={
              lang !== "en" && item.name_chinese ? item.name_chinese : item.name
            }
            style={{
              ...styles.photo,
              backgroundImage: `url(${
                item.path ? `${app.origin}/${item.path}` : "/assets/default.png"
              })`,
            }}
          />
        </Col>
        <Col span={16} style={{ padding: "0px 20px 0px 10px" }}>
          <Row justify="space-between">
            <Col style={{ ...DefaultStyles.subHeader }}>
              {lang !== "en" && item.name_chinese
                ? item.name_chinese
                : item.name}
            </Col>
            <Col span={8}>
              <div
                style={{
                  ...styles.status,
                  backgroundColor: item.availablePids
                    ? COLORS.primary
                    : COLORS.error,
                }}
              >
                {item.availablePids ? "AVAILABLE" : "FULL"}
              </div>
            </Col>
          </Row>
          <Col span={20} style={{ marginTop: 10 }}>
            <Row>
              <Col style={DefaultStyles.subBody}>
                {lang !== "en" && item.address_chinese
                  ? item.address_chinese
                  : item.address}
              </Col>
            </Row>
            {!_.isUndefined(item.availablePIDs) && (
              <Row>
                <Col style={DefaultStyles.subBody}>
                  {`${t("available_charger")}: ${item.availablePIDs}`}
                </Col>
              </Row>
            )}
            {!_.isUndefined(item.pids) && (
              <Row>
                <Col style={DefaultStyles.subBody}>
                  {`${t("total_subscription_charger")}: ${item.pids.length}`}
                </Col>
              </Row>
            )}
            {!_.isUndefined(item.totalPIDs) && (
              <Row>
                <Col style={DefaultStyles.subBody}>
                  {`${t("total_charger")}: ${item.totalPIDs}`}
                </Col>
              </Row>
            )}
          </Col>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row justify="center" align="middle" style={{ marginBottom: 24 }}>
        <Col span={8} style={{ textAlign: "center" }}>
          <div
            alt={item.name}
            style={{
              ...styles.photo,
              backgroundImage: `url(${
                item.path ? `${app.origin}/${item.path}` : "/assets/default.png"
              })`,
            }}
          />
        </Col>
        <Col
          span={16}
          style={{
            padding: "0px 10px",
            wordBreak: "break-word",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Row style={{ width: "100%"}}>
            <Col span={24} style={{ ...DefaultStyles.subHeader }}>
              {lang !== "en" && item.name_chinese
                ? item.name_chinese
                : item.name}
            </Col>
          </Row>
          {!hideAddress && (
            <Row style={{ width: "100%"}}>
              <Col
                span={24}
                style={{ ...DefaultStyles.subHeader, fontSize: "14px" }}
              >
                {lang !== "en" && item.address_chinese
                  ? item.address_chinese
                  : item.address}
              </Col>
            </Row>
          )}
          {!_.isUndefined(item.availablePIDs) && !showAvailabilityAcAndDc && (
            <Row span={24}>
              <Col style={DefaultStyles.subBody}>
                {`${t("available_charger")}: ${item.availablePIDs}`}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        {showAvailabilityAcAndDc && (
          <>
            <Col span={12} style={{ ...DefaultStyles.subBody }}>
              {`${t("available_ac_chargers")}:`}
              <br />
              {`${item.availableAcPIDs}`}
            </Col>

            <Col span={12} style={{ ...DefaultStyles.subBody }}>
              {`${t("available_dc_chargers")}:`}
              <br />
              {`${item.availableDcPIDs}`}
            </Col>
          </>
        )}
      </Row>
      <Row gutter={[12, 0]}>
        {!_.isUndefined(item.bookingPIDs) && (
        <Col span={12} style={DefaultStyles.subBody}>
          {`${t("total_booking_charger")}:`}
          <br />
          {`${item.bookingPIDs}`}
        </Col>
        )}
        {!_.isUndefined(item.totalPIDs) && (
        <Col span={12} style={DefaultStyles.subBody}>
          {`${t("total_charger")}:`}
          <br />
          {`${item.totalPIDs}`}
        </Col>
        )}
      </Row>
      <hr style={{ border: "1 solid grey", margin: "24px 0px" }} />
      <div className={stylesModule.mapCollapseWrapper}>
        <Collapse
          onChange={onPanelChange}
          className={stylesModule.mapCollapseWrapper}
          style={{
            backgroundColor: "transparent",
            border: "none",
            borderBottom: "none",
          }}
        >
          <Collapse.Panel
            showArrow={false}
            header={(
              <div style={{ width: "100%", textAlign: "center" }}>
                <span className={stylesModule.headerBtnTxt}>
                  {isPanelOpen("1") ? t("show_less") : t("show_more")}
                </span>
                <img
                  style={{
                    transform: isPanelOpen("1")
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.2s ease-in-out",
                  }}
                  alt="arrow"
                  src="/assets/chervon-down.svg"
                />
              </div>
            )}
            key="1"
          >
            <div style={{maxHeight: '200px', overflowY: 'auto'}}>
              <div className={stylesModule.collapseBtnWrapper}>
                <Button
                  className={stylesModule.collapseBtn}
                  onClick={reserveBtn.onClick}
                  disabled={_.isEmpty(item.pids)}
                  style={{
                    opacity: !_.isEmpty(item.pids)
                      ? 1
                      : 0.6
                  }}
                >
                  <div className={stylesModule.collapseBtnTxt}>
                    <img
                      style={{
                        color: "white",
                      }}
                      alt="timer"
                      src="/assets/reserveTimer.svg"
                    />
                    {t("reserve")}
                  </div>
                </Button>
              </div>
              <div className={stylesModule.showMoreContentWrapper}>
                <div style={{marginTop: "10px"}} />
                {/* <div>Remarks</div> */}
                <div className={stylesModule.showMoreContentBodyText}>
                  {remark}
                </div>
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  );
};

const styles = {
  photo: {
    borderRadius: 10,
    ...DefaultStyles.image,
    backgroundSize: "contain",
    paddingTop: "100%",
  },
  status: {
    ...FONTS.tag,
    color: COLORS.white,
    textAlign: "center",
    borderRadius: 5,
    padding: 5,
  },
};

export default CarParkItemMap;

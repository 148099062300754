import React, { useEffect, useState } from 'react';
import {
  notification
} from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import {
  setMonthlyStep,
  setOrder,
  setSelectedDiscount,
} from '../../redux/actions/common'
import { SubscriptionChargingDetail } from '../ChargingDetail'
import PaymentDetail from '../PaymentDetail'

import * as Service from '../../core/Service';
import * as Main from '../../core/Main';

const BookingPaymentDetail = ({ setLoading }) => {
  const history = useHistory()
  const [submitLoading, setSubmitLoading] = useState(false)
  const charger = useSelector((state) => state.charger)
  const monthly = useSelector((state) => state.monthly)
  const orders = useSelector((state) => state.orders)
  const {
    selectedPlan, selectedCoupon, selectedCar, selectedPID, selectedCarPark
  } = charger
  const { selectedDate } = monthly
  const dispatch = useDispatch()

  useEffect(() => {
    if (!_.isEmpty(orders.order)) return;
    getDiscount()
  }, [selectedPlan, selectedCarPark])


  const getDiscount = async () => {
    setLoading(true)
    const resp = await Service.call('get', `/api/web/discount/${selectedPlan.id}/${selectedCarPark.id}`)
    setLoading(false)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    dispatch(setSelectedDiscount(resp.data.discount))
  }

  const onCheckout = async () => {
    setSubmitLoading(true)
    let resp = await Service.call('post', `/api/web/order/order_info`, {
      promo_code_id: selectedCoupon.id,
      plan_id: selectedPlan.id,
      user_ev_id: selectedCar.id,
      pid: selectedPID.pid,
      monthly_selected_date: selectedDate
    });
    setSubmitLoading(false)
    if (resp.status <= 0) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    dispatch(setOrder(resp.data))
    if (resp.data.final_price > 0) {
      return history.replace(`/charger/payment?order_key=${resp.data.order_key}`)
    }
  };

  return (
    <PaymentDetail
      setLoading={setLoading}
      chargingDetail={<SubscriptionChargingDetail mode="payment" />}
      selectCoupon={() => dispatch(setMonthlyStep('select-coupon'))}
      onCheckout={onCheckout}
      goBack={() => {
        dispatch(setMonthlyStep('select-plan'))
      }}
      submitLoading={submitLoading}
    />
  )
}

export default BookingPaymentDetail;

import React, { useEffect, useState } from 'react';
import {
  Col, Row, Empty
} from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';
import SkeletonScreen from '../components/SkeletonScreen';

const TopupList = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const [coupon, setCoupon] = useState({})

  useEffect(() => {
    getTopupList()
  }, [])

  const getTopupList = async () => {
    let dataSource = {}
    try {
      const resp = await Service.call('get', '/api/web/promo_code/type/topup')
      if (resp.status > 0) {
        dataSource = _.orderBy(resp.data, 'utime', 'desc')
      }
    } finally {
      setCoupon(dataSource)
      setLoading(false)
    }
  }

  const couponItem = (item) => {
    return (
      <Row
        key={item.id}
        style={styles.card}
        align="middle"
        justify="space-between"
        onClick={() => {
          if (item.type === 'topup') {
            history.push('topup-history', { id: item.id })
          }
        }}
      >
        <Col span={14}>
          <div style={DefaultStyles.header}>{item.name}</div>
          <div style={DefaultStyles.h5}>{`${t('start_date')}: ${moment.unix(item.start_date).format('YYYY-MM-DD')}`}</div>
          <div style={DefaultStyles.h5}>{`${t('end_date')}: ${moment.unix(item.end_date).format('YYYY-MM-DD')}`}</div>
          <div style={DefaultStyles.h5}>{`${t('code')}: ${item.code}`}</div>
        </Col>
        <Col
          span={10}
          style={{
            ...DefaultStyles.header,
            textAlign: 'end',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: '600'
          }}
        >
          AU$
          {_.round(item.discount, 2).toFixed(2)}
        </Col>
      </Row>
    )
  }

  return (
    <SkeletonScreen loading={loading}>
      <AppLayout
        color="#fff"
        headerOptions={{
          title: t('coupon'),
          leftComponent: <LeftCircleFilled
            onClick={() => {
              history.push('/');
            }}
            style={DefaultStyles.leftArrow}
          />
        }}
      >
        {!_.isEmpty(coupon)
          ? (
            <>
              {!_.isEmpty(_.filter(coupon, { type: 'topup' })) && (
              <Row style={{...FONTS.subHeader, marginBottom: 10 }}>
                {i18n.language === 'en' ? `${t('topup')} ${t('coupon')}` : `${t('topup')}${t('coupon')}`}
              </Row>
              )}
              {_.map(_.filter(coupon, { type: 'topup' }), (item) => {
                return couponItem(item)
              })}
              {!_.isEmpty(_.filter(coupon, { type: 'select' })) && (
              <Row style={{...FONTS.subHeader, margin: '10px 0' }}>
                {t('coupon')}
              </Row>
              )}
              {_.map(_.filter(coupon, { type: 'select' }), (item) => {
                return couponItem(item)
              })}
            </>
          ) : (
            <Row justify="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('topup_not_found')} />
            </Row>
          )}
      </AppLayout>
    </SkeletonScreen>
  );
}

const styles = {
  card: {
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    padding: 15,
    marginBottom: 10,
    minHeight: 100
  }
}

export default TopupList;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, notification
} from 'antd';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { setSelectedCoupon } from '../redux/actions/common'

import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { COLORS, DefaultStyles, FONTS } from '../constants';

const Coupon = ({ setLoading, applyCouponCallback }) => {
  const { t } = useTranslation()
  const app = useSelector((state) => state.app)
  const charger = useSelector((state) => state.charger)
  const { selectedCoupon, selectedCarPark } = charger;
  const [coupon, setCoupon] = useState(selectedCoupon)
  const [inputCoupon, setInputCoupon] = useState('')
  const dispatch = useDispatch()
  const { id: car_park_id } = selectedCarPark
  const [coupons, setCoupons] = useState([])
  const [topupCoupons, setTopupCoupons] = useState([])


  useEffect(() => {
    getCouponList()
  }, [])

  const getCouponList = async () => {
    setLoading(true)
    const resp = await Service.call('get', `/api/web/user/promo_code?car_park_id=${car_park_id}`)
    if (resp.status <= 0) {
      setLoading(false)
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }
    const topup = _.remove(resp.data.promoCodeList, ({ type }) => type === 'topup')
    setTopupCoupons(topup)
    setCoupons(resp.data.promoCodeList)
    setLoading(false)
  }

  useEffect(() => {
    if (!_.isEmpty(inputCoupon)) {
      setCoupon({})
    } else {
      setCoupon(selectedCoupon)
    }
  }, [inputCoupon])


  const applyCoupon = async () => {
    let couponObj = {};
    let code = ''

    if (!_.isEmpty(coupon)) {
      couponObj = coupon
    }

    if (!_.isEmpty(inputCoupon)) {
      code = encodeURIComponent(inputCoupon)
    }

    if (_.isEmpty(couponObj) && !_.isEmpty(code)) {
      const resp = await Service.call("get", `/api/web/promo_code/${code}?car_park_id=${car_park_id}`)
      if (resp.status <= 0) {
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }

      // if (resp.data.user_group_id !== 0) {
      //   if (!_.includes(app.user.userGroup, resp.data.user_group_id)) {
      //     return notification.error({
      //       message: `Promo code is not applicable.`,
      //     });
      //   }
      // }

      couponObj = resp.data
    }

    if (!_.isEmpty(couponObj)) {
      dispatch(setSelectedCoupon(couponObj))
    }
    applyCouponCallback()
  }

  return (
    <>
      {!_.isEmpty(topupCoupons) && (
      <Row justify="start">
        <Col
          style={{
            ...FONTS.subHeader,
            color: COLORS.primary,
            textAlign: 'center',
            margin: '10px 0px 15px 0px'
          }}
        >
          {t('use_topup_coupon')}
        </Col>
      </Row>
      )}
      <Row gutter={[0, 20]}>
        {_.map(topupCoupons, (item) => {
          return (
            <Col
              span={24}
              key={item.id}
            >
              <Button
                type="text"
                style={{
                  ...DefaultStyles.buttonOutline,
                  textAlign: 'start',
                  backgroundColor: item.id === coupon.id ? COLORS.light : COLORS.white,
                  border: item.id === coupon.id ? `2px solid ${COLORS.light}` : `2px solid ${COLORS.primary}`
                }}
                onClick={() => {
                  setCoupon(item)
                  setInputCoupon("")
                }}
                block
              >
                <Row align="middle" gutter={[10, 0]} style={{...DefaultStyles.buttonOutlineText, height: '100%'}}>
                  <Col><img src="/assets/coupon.svg" alt="" style={{ width: 30, height: 30 }} /></Col>
                  <Col
                    span={20}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {item.name}
                  </Col>
                </Row>
              </Button>
            </Col>
          )
        })}
      </Row>
      {!_.isEmpty(topupCoupons) && (
      <Row justify="center">
        <Col
          span={24}
          style={{
            ...FONTS.subBody,
            color: COLORS.primary,
            textAlign: 'center',
            margin: '10px 0px 30px 0px'
          }}
        >
          {t('or')}
        </Col>
      </Row>
      )}
      <Row gutter={[0, 20]}>
        {_.map(coupons, (item) => {
          return (
            <Col
              span={24}
              key={item.id}
            >
              <Button
                type="text"
                style={{
                  ...DefaultStyles.buttonOutline,
                  textAlign: 'start',
                  backgroundColor: item.id === coupon.id ? COLORS.light : COLORS.white,
                  border: item.id === coupon.id ? `2px solid ${COLORS.light}` : `2px solid ${COLORS.primary}`
                }}
                onClick={() => {
                  setCoupon(item)
                  setInputCoupon("")
                }}
                block
              >
                <Row align="middle" gutter={[10, 0]} style={{...DefaultStyles.buttonOutlineText, height: '100%'}}>
                  <Col><img src="/assets/coupon.svg" alt="" style={{ width: 30, height: 30 }} /></Col>
                  <Col
                    span={20}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {item.code}
                  </Col>
                </Row>
              </Button>
            </Col>
          )
        })}
      </Row>
      {!_.isEmpty(coupons)
        ? (
          <Row justify="center">
            <Col
              span={24}
              style={{
                ...FONTS.subBody,
                color: COLORS.primary,
                textAlign: 'center',
                margin: '10px 0px 30px 0px'
              }}
            >
              {t('or')}
            </Col>
          </Row>
        )
        : null}
      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Input
            style={DefaultStyles.formInput}
            size="large"
            value={inputCoupon}
            onChange={(e) => {
              setInputCoupon(e.target.value)
            }}
            prefix={<img alt="coupon" src="/assets/coupon-circle.svg" style={DefaultStyles.fieldIcon} />}
            placeholder={t('input_coupon_code')}
          />
        </Col>
        <Col span={24}>
          <Button
            style={DefaultStyles.button}
            onClick={applyCoupon}
          >
            <span style={DefaultStyles.buttonText}>{t('use_coupon')}</span>
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default Coupon;

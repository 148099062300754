import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import AppLayout from '../AppLayout'
import { BookingChargingDetail } from '../ChargingDetail'
import { setBookingStep } from '../../redux/actions/common';
import CarParkItem from '../CarParkItem';
import { DefaultStyles } from '../../constants';

const BookingDetail = (props) => {
  const { t } = useTranslation()
  const booking = useSelector((state) => state.booking)
  const { selectedBooking } = booking;
  const dispatch = useDispatch();

  return (
    <AppLayout
      headerOptions={{
        title: 'Booking Details',
        leftComponent: (
          <LeftCircleFilled
            style={{ fontSize: 32, textAlign: 'left' }}
            onClick={() => {
              dispatch(setBookingStep('my-booking'))
            }}
          />
        )
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <CarParkItem
          item={selectedBooking.carParkDetail}
        />
      </div>
      <BookingChargingDetail />
      <Row justify="space-between">
        <Col style={DefaultStyles.subHeader}>{t('total')}</Col>
        <Col style={DefaultStyles.subHeader}>
          HK$
          {selectedBooking.orderInfoDetail.final_price.toFixed(2)}
        </Col>
      </Row>
    </AppLayout>
  )
}

export default BookingDetail;

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification, Empty
} from 'antd';
import {
  PlusOutlined,
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import { setBookingStep, setSelectedBooking } from '../../redux/actions/common';
import { COLORS, DefaultStyles } from '../../constants';

const BookingList = ({
  setLoading,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [dataList, setDataList] = useState({})
  const dispatch = useDispatch();

  useEffect(() => {
    getDataList()
  }, [])

  const getDataList = async() => {
    setLoading(true)
    let dataSource = []
    try {
      const resp = await Service.call('get', '/api/web/user/booking_info')

      if (resp.status <= 0) {
        setLoading(false)
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }
      dataSource = _.sortBy(resp.data, ({ order_items }) => {
        return _.minBy(order_items, 'start_time').start_time
      })
      setDataList(dataSource)
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(dataSource);
    }
    setLoading(false)
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('my_booking'),
        leftComponent: (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={() => {
              return history.push('/')
            }}
          />
        )
      }}
    >
      <Row justify="center">
        <Col span={24} style={{ marginBottom: 20 }}>
          {_.isEmpty(
            dataList
          ) ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('no_booking')}
            />
            )
            : _.map(dataList, (item) => {
              return (
                <BookingItem
                  key={item.order_info_id}
                  item={item}
                />
              )
            })}
        </Col>
        <Col span={24}>
          <Button
            style={DefaultStyles.button}
            icon={<PlusOutlined style={{ ...styles.buttonIcon}} />}
            onClick={() => {
              dispatch(setBookingStep('select-location'))
            }}
          >
            <span style={DefaultStyles.buttonText}>{t('add_new')}</span>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  )
}

const BookingItem = ({ item }) => {
  const { t } = useTranslation()
  let booking_item = _.first(item.order_items)
  const dispatch = useDispatch()

  if (!booking_item) return null;
  return (
    <Row
      style={DefaultStyles.bookingItemCard}
      align="middle"
      justify="space-between"
      onClick={() => {
        dispatch(setSelectedBooking(booking_item))
        dispatch(setBookingStep('booking-detail'))
      }}
    >
      <Col span={16}>
        <Row gutter={[0, 0]}>
          <Col span={24} style={DefaultStyles.subHeader}>{booking_item.pidDetail.pid}</Col>
          <Col span={24} style={DefaultStyles.body}>{booking_item.planDetail.name}</Col>
          <Col span={24} style={DefaultStyles.subBody}>{booking_item.carParkDetail.name}</Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row style={{ textAlign: 'start' }} gutter={[0, 0]}>
          <Col span={24} style={DefaultStyles.subBody}>{t('start_date')}</Col>
          <Col span={24} style={DefaultStyles.body}>
            {moment.unix(booking_item.bookingDetail.start_time).format('YYYY-MM-DD')}
          </Col>
          <Col span={24} style={DefaultStyles.body}>
            {moment.unix(booking_item.bookingDetail.start_time).format('hh:mm a')}
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <span
              style={{
                ...DefaultStyles.subBody,
                backgroundColor: COLORS.light,
                padding: '10px 15px',
                borderRadius: 10,
                marginRight: 10
              }}
            >
              {booking_item.pidDetail.parking_lot}
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const styles = {
  buttonIcon: {
    fontSize: 18,
    color: '#fff'
  }
}


export default BookingList;

import React from 'react';
import {
  Col, Row
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import AppLayout from '../components/AppLayout';
import { DefaultStyles } from '../constants';

function AboutUs() {
  const { t, i18n } = useTranslation()
  const site_meta = useSelector((state) => state.app.site_meta)
  const history = useHistory();

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('about_us'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.push('/setting');
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row>
        <Col>
          {ReactHtmlParser(site_meta[`${i18n.language === 'en' ? 'static_about_us_content' : 'static_about_us_content_chi'}`].meta_value)}
        </Col>
      </Row>
    </AppLayout>
  );
}

export default AboutUs;

import React, { useEffect, useState } from 'react';
import {
  Row, Col
} from 'antd';
import {
  CheckCircleFilled,
} from '@ant-design/icons';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import CalendarButton from './CalendarButton'

import AppLayout from './AppLayout'
import { DefaultStyles } from '../constants';
import styles from '../constants/styles';

const PaymentSuccess = ({
  chargingDetail = null,
  button = null,
  type = ''
}) => {
  const { t } = useTranslation()
  return (
    <AppLayout
      headerOptions={{
        hide: true
      }}
    >
      <Row style={{ minHeight: '80vh' }} align="middle">
        <Col span={24}>
          <Row justify="center" gutter={[0, 20]} style={{ textAlign: 'center', marginTop: 30 }}>
            <Col span={24}>
              <CheckCircleFilled style={styles.circleIcon} />
            </Col>
            <Col span={24}>
              <span style={DefaultStyles.header}>
                {t('your_payment_is_successful')}
              </span>
            </Col>
          </Row>

          <Row justify="center" gutter={[0, 20]}>
            <Col span={24}>
              {chargingDetail}
            </Col>
            <Col span={24}>
              <CalendarButton type={type} />
            </Col>
            <Col span={24}>
              {button}
            </Col>
          </Row>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default PaymentSuccess;

import React, { useEffect, useState } from 'react';
import {
  Col, Row, Divider, Empty
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppLayout from '../components/AppLayout';
import { DefaultStyles, COLORS } from '../constants';
import * as Service from '../core/Service';
import SkeletonScreen from '../components/SkeletonScreen';

const TopupCouponHistory = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [coupon, setCoupon] = useState({})
  const [historyList, setHistoryList] = useState([])

  useEffect(() => {
    if (_.isUndefined(location.state)) return history.replace('/topup-record');
    const { id } = location.state
    getTopupCoupon(id)
  }, [])

  const getTopupCoupon = async (id) => {
    let dataSource = {}
    let orderList = []
    try {
      const resp = await Service.call('get', `/api/web/promo_code/topup/${id}`)
      if (resp.status > 0) {
        const {promoCode, order} = resp.data
        dataSource = promoCode
        orderList = _.orderBy(order, ['date_completed'], ['desc'])
      }
    } finally {
      setCoupon(dataSource)
      setHistoryList(orderList)
      setLoading(false)
    }
  }

  if (loading) {
    return <SkeletonScreen loading />
  }
  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: coupon.name,
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.goBack();
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
      // fullWidth
    >
      {!_.isEmpty(coupon) ? (
        <>
          <Row justify="center">
            <Col span={12}>
              {/* <div style={{...DefaultStyles.image, paddingTop: '100%', backgroundImage: 'url(assets/credit-card.svg'}} /> */}
              <img alt="booking" src="/assets/credit_card.svg" style={styles.featureIcon} />
            </Col>
          </Row>
          <div style={{ margin: '3%'}}>
            <Row justify="space-between">
              <Col style={DefaultStyles.body}>
                {t('start_date')}
              </Col>
              <Col style={{...DefaultStyles.body}}>
                {moment.unix(coupon.start_date).format('YYYY-MM-DD')}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col style={DefaultStyles.body}>
                {t('end_date')}
              </Col>
              <Col style={{...DefaultStyles.body}}>
                {moment.unix(coupon.end_date).format('YYYY-MM-DD')}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col style={DefaultStyles.body}>
                {t('code')}
              </Col>
              <Col style={{...DefaultStyles.body}}>
                {coupon.code}
              </Col>
            </Row>
          </div>
          <Divider />
          <Row justify="space-around">
            <Col style={DefaultStyles.header}>
              {t('coupon_balance')}
            </Col>
            <Col style={{...DefaultStyles.header}}>
              HK$
              {coupon.discount.toFixed(2)}
            </Col>
          </Row>
          <OrderHistoryList historyList={historyList} />
        </>
      )
        : (
          <Row justify="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('topup_not_found')} />
          </Row>
        )}
    </AppLayout>
  );
}

const OrderHistoryList = ({ historyList }) => {
  const { t } = useTranslation()
  if (_.isEmpty(historyList)) return null
  return (
    <>
      <Row>
        <Divider />
        <Col
          span={24}
          style={{
            ...DefaultStyles.subHeader,
            textAlign: 'center'
          }}
        >
          {t('usage_history')}
        </Col>
      </Row>
      {_.map(historyList, (item) => {
        return (
          <Row
            style={styles.card}
            align="middle"
            justify="center"
            key={item.ptx_id}
          >
            <Col span={21}>
              <Row gutter={[0, 10]} justify="center">
                <Col span={24}>
                  <Row justify="space-between" gutter={[0, 12]}>
                    <Col span={12}>
                      <div style={DefaultStyles.chargingDetailLabel}>
                        {t('payment_id')}
                      </div>
                      <div style={DefaultStyles.body}>
                        {item.ptx_id}
                      </div>
                    </Col>
                    <Col span={10}>
                      <div style={DefaultStyles.chargingDetailLabel}>
                        {t('date')}
                      </div>
                      <div style={DefaultStyles.body}>
                        {moment.unix(item.date_completed).format('YYYY-MM-DD')}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div style={DefaultStyles.chargingDetailLabel}>
                        {t('pid')}
                      </div>
                      <div style={DefaultStyles.body}>{item.pid}</div>
                    </Col>
                    <Col span={10}>
                      <div style={DefaultStyles.chargingDetailLabel}>
                        {t('plan_name')}
                      </div>
                      <div style={DefaultStyles.body}>{item.plan}</div>
                    </Col>
                  </Row>
                </Col>
                <Divider />
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col span={12}>
                      <div style={DefaultStyles.subHeader}>
                        {t('usage_amount')}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div
                        style={{...DefaultStyles.subHeader, textAlign: 'center'}}
                      >
                        {`$${(item.promo_code_discount.toFixed(2))}`}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      })}
    </>
  )
}

const styles = {
  card: {
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 10,
    marginBottom: 10,
    marginTop: 20
  },
  featureIcon: {
    width: '100%',
    objectFit: 'contain',
    aspectRatio: '1/1'
  },
}

export default TopupCouponHistory;

import React, { useEffect, useState } from 'react';
import {
  Col, Row, DatePicker, notification, Spin, Tag
} from 'antd';
import {
  LeftCircleFilled, RightOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setChargerStatus } from '../redux/actions/common'
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles } from '../constants';
import SkeletonScreen from '../components/SkeletonScreen';

function PaymentRecord() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(moment())
  const [paymentHistoryList, setPaymentHistoryList] = useState([]);
  const [filteredPaymentHistoryList, setFilteredpaymentHistoryList] = useState([]);

  useEffect(() => {
    getCarPark()
  }, [])

  useEffect(() => {
    const updateFilteredList = () => {
      if (!selectedMonth) {
        setFilteredpaymentHistoryList(paymentHistoryList)
        return
      }

      const filteredList = _.filter(paymentHistoryList, (record) => {
        const recordTime = moment.unix(record.date_completed)
        return recordTime.year() === selectedMonth.year() && recordTime.month() === selectedMonth.month()
      })
      setFilteredpaymentHistoryList(_.compact(filteredList))
    }

    updateFilteredList();
  }, [selectedMonth, paymentHistoryList])

  const getCarPark = async() => {
    setLoading(true)
    let dataSource = []
    try {
      const resp = await Service.call('get', '/api/web/user/order')

      if (resp.status <= 0) {
        setLoading(false)
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }

      dataSource = resp.data
      console.log("dataSource >>>", dataSource);
      setPaymentHistoryList(_.orderBy(dataSource, ['ctime'], 'desc'))
    } catch (error) {
      console.error('error >>> ', error)
    }
    setLoading(false)
  }

  const showPrice = (payment_record) => {
    const {final_price, status, orderInfoDetail } = payment_record;
    const {original_price, discount} = orderInfoDetail;
    switch (status) {
      case "payment_refund":
      case "payment_partial_refund":
        let planPrice = original_price - discount;
        return _.round(planPrice, 2).toFixed(2);
      default:
        return _.round(final_price, 2).toFixed(2);
    }
  }

  return (
    <SkeletonScreen loading={loading}>
      <AppLayout
        color="#fff"
        headerOptions={{
          title: t('payment_record'),
          leftComponent: <LeftCircleFilled
            onClick={() => {
              history.push('/');
            }}
            style={DefaultStyles.leftArrow}
          />
        }}
      >
        <Row justify="center" gutter={[0, 30]}>
          <Col span={24}>
            <DatePicker
              inputReadOnly
              size="large"
              placeholder={t('select_year')}
              style={{
                width: '100%',
                height: 54,
                border: `2px solid ${COLORS.primary}`,
                borderRadius: 10,
                padding: 12.5
              }}
              onChange={(value) => setSelectedMonth(value)}
              value={selectedMonth}
              picker="month"
              disabledDate={(current) => {
                return current > moment()
              }}
            />
          </Col>
        </Row>
        {_.map(filteredPaymentHistoryList, (item) => {
          return (
            <Row
              style={styles.card}
              align="middle"
              justify="space-between"
              onClick={() => {
                dispatch(setChargerStatus({
                  selectedPID: item.pidDetail,
                  selectedPlan: item.planDetail,
                  selectedCarPark: item.carParkDetail,
                  selectedCoupon: item.orderInfoDetail.promo_code_discount,
                  userChargeRecord: item,
                  selectedDiscount: item.discount,
                  startTime: item.startTime,
                  chargedTime: item.chargedTime,
                  refundStatus: item.refund_status,
                  refundTime: item.ptx_complete_time
                }))
                history.push('payment-history')
              }}
              key={item.order_info_id}
            >
              <Col span={13}>
                <div style={DefaultStyles.header}>{moment.unix(item.date_completed).format("YYYY-MM-DD")}</div>
                <div style={DefaultStyles.body}>{i18n.language !== 'en' && item.planDetail?.name_chinese ? item.planDetail?.name_chinese : item.planDetail?.name}</div>
                <div style={DefaultStyles.subBody}>{i18n.language !== 'en' && item.carParkDetail?.name_chinese ? item.carParkDetail?.name_chinese : item.carParkDetail?.name}</div>
              </Col>
              <Col
                span={8}
                style={{
                  ...DefaultStyles.subHeader,
                  textAlign: 'end',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <div style={{fontSize: 16}}>
                  AU$
                  {showPrice(item)}
                </div>
                {(item.status === "payment_refund" || item.status === "payment_partial_refund")  && (
                  <div>
                    <Tag color="blue" style={{ marginRight: 0 }}>Refunded</Tag>
                  </div>
                )}
                {/* {item.status === "payment_partial_refund" && (
                  <div>
                    <Tag color="blue" style={{ marginRight: 0 }}>Partial Refunded</Tag>
                  </div>
                )} */}
              </Col>
              <Col span={2}>
                <Row align="middle" justify="end">
                  <Col>
                    <RightOutlined style={{
                      ...DefaultStyles.subHeader,
                      display: 'flex'
                    }}
                    />

                  </Col>
                </Row>
              </Col>
            </Row>
          )
        })}
      </AppLayout>
    </SkeletonScreen>
  );
}

const styles = {
  card: {
    backgroundColor: COLORS.gray,
    borderRadius: 10,
    padding: 15,
    marginBottom: 10
  }
}

export default PaymentRecord;

import React, { useEffect, useState } from 'react';
import {
  Button, Col, Row, Collapse, Skeleton
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _, { last } from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import * as Service from '../core/Service';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';
import SkeletonScreen from '../components/SkeletonScreen';

const { Panel } = Collapse;

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [faqList, setFaqList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getFaqList()
  }, [])

  const getFaqList = async () => {
    setLoading(true)
    const resp = await Service.call('get', '/api/web/faq')
    setFaqList(_.orderBy(resp.data, ['sorting'], ['asc']))
    setLoading(false)
  }

  return (
    <SkeletonScreen loading={loading}>
      <AppLayout
        color="#fff"
        headerOptions={{
          title: t('faq'),
          leftComponent: <LeftCircleFilled
            onClick={() => {
              history.goBack();
            }}
            style={DefaultStyles.leftArrow}
          />
        }}
      >
        <Row justify="center">
          <Col
            span={24}
          >
            <Collapse expandIconPosition="right" style={styles.collapse} bordered={false}>
              {_.map(faqList, (item) => {
                if (_.isEmpty(item)) return;
                return (
                  <Panel
                    style={styles.header}
                    header={i18n.language === 'en' ? item.title : item.title_chi}
                    key={item.faq_id}
                  >
                    {ReactHtmlParser(i18n.language === 'en' ? item.content : item.content_chi)}
                  </Panel>
                )
              })}
            </Collapse>
          </Col>
        </Row>
      </AppLayout>
    </SkeletonScreen>
  )
}

const styles = {
  collapse: {
    backgroundColor: COLORS.white
  },
  header: {
    ...FONTS.body,
    color: COLORS.dark
  }
}

export default FAQ;

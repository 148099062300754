import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import CarParkMapView from '../map/CarParkMapView'
import * as CommonActions from '../../redux/actions/common';
import { COLORS, DefaultStyles } from '../../constants';


const BookingMapView = ({
  setLoading, backHome, homepageMap
}) => {
  const history = useHistory()
  const app = useSelector((state) => state.app)
  const booking = useSelector((state) => state.booking)
  const dispatch = useDispatch();


  const [carParkList, setCarParkList] = useState([])

  useEffect(() => {
    getCarPark()
  }, [])

  const getCarPark = async() => {
    setLoading(true)
    let dataSource = []
    try {
      const resp = await Service.call('get', `/api/web/car_park/booking${homepageMap ? '?type=home' : ''}`)
      if (resp.status <= 0) {
        setLoading(false)
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }
      const availableResp = await Service.call('get', '/api/web/booking_info/available')
      if (availableResp.status <= 0) {
        setLoading(false)
        return notification.error({
          message: Main.getErrorCodeMsg(availableResp.errorCode, availableResp.errorMessage),
          duration: 0
        });
      }
      const carParkMap = _.keyBy(resp.data, 'id')
      const mergedCarPark = _.mergeWith(carParkMap, availableResp.data, (carPark, status) => {
        return {
          ...carPark,
          status
        }
      })
      dataSource = _.values(mergedCarPark)
      setCarParkList(dataSource)
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setCarParkList(dataSource);
    }
    setLoading(false)
  }

  return (
    <AppLayout
      containerStyle={{ paddingBottom: 0, backgroundColor: 'transparent' }}
      fullWidth
      headerOptions={{
        hide: true,
      }}
    >
      <div style={{ height: '100vh', overflow: 'hidden', position: 'relative' }}>
        <LeftCircleFilled
          style={{
            color: COLORS.dark,
            fontSize: 32,
            position: 'absolute',
            top: 30,
            left: 20,
            zIndex: 99
          }}
          onClick={() => {
            if (backHome) {
              return history.goBack()
            }
            dispatch(CommonActions.setBookingStep('select-location'))
          }}
        />
        <CarParkMapView
          redirect={backHome || undefined}
          carPark={carParkList}
        />
      </div>
    </AppLayout>
  )
}

export default BookingMapView;

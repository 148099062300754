import React, { useEffect, useState } from 'react';
import {
} from 'antd';
import {
  LeftCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import Coupon from '../Coupon'
import {
  setBookingStep
} from '../../redux/actions/common'

import AppLayout from '../AppLayout'

const BookingCoupon = ({ setLoading }) => {
  const dispatch = useDispatch()
  return (
    <AppLayout
      headerOptions={{
        title: 'Coupon Selection',
        rightComponent: <CloseCircleFilled
          onClick={() => {
            dispatch(setBookingStep('payment-detail'))
          }}
          style={{ fontSize: 32, textAlign: 'rigth' }}
        />
      }}
    >
      <Coupon
        setLoading={setLoading}
        applyCouponCallback={() => dispatch(setBookingStep('payment-detail'))}
      />
    </AppLayout>
  )
}


const styles = {
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: '#1A468F',
    borderColor: 'transparent',
    height: 50
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600'
  },
  input: {
    height: 50,
    borderRadius: 10
  },
  formInput: {
    backgroundColor: '#F5F5F5',
    width: '100%',
    height: 64,
    borderRadius: 10,
    border: 'none',
    color: '#3B3B3C',
    fontSize: 24,
    fontWeight: '400'
  },
  fieldIcon: {
    width: 40,
    height: 40,
    margin: '0px 5px'
  },
}

export default BookingCoupon;

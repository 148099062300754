import React, { useEffect } from 'react';
import {
  Button, Col, Input, Row, Form, message, notification
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setUser } from '../redux/actions/common'
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles, FONTS } from '../constants';

function AccountForm() {
  const { t } = useTranslation();
  const userObj = useSelector((state) => state.app.user);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = async (formData) => {
    const { name, email } = formData;

    let resp = await Service.call('put', '/api/web/user', { name, email });
    if (resp.errorCode) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      })
    }

    resp = await Service.call('get', '/api/web/user');
    if (resp.errorCode) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      })
    }

    dispatch(setUser(resp.data));
    message.success('Success');
    return history.push(`/account`);
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: "Account",
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.push('/account');
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row justify="center">
        <Col
          span={24}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              rules={[{ required: true, message: t('required') }]}
              initialValue={userObj.name}
            >
              <Input
                style={DefaultStyles.formInput}
                size="large"
                prefix={<img alt="" src="/assets/id-card.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('name')}
              />
            </Form.Item>
            <Form.Item
              name="email"
              validateFirst
              rules={[{
                required: true,
                message: t('required')
              },
              {
                type: 'email',
                message: t('incorrect_email_format')
              }]}
              initialValue={userObj.email}
            >
              <Input
                style={DefaultStyles.formInput}
                size="large"
                prefix={<img alt="" src="/assets/email.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('email')}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={DefaultStyles.button}
                type="primary"
                htmlType="submit"
              >
                <span style={DefaultStyles.buttonText}>
                  {t('update_user_info')}
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </AppLayout>
  );
}


export default AccountForm;

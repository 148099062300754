import React, { useEffect, useState, useCallback } from 'react';
import {
  Row, Col, Button, Empty, notification, Select, Spin
} from 'antd';
import {
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
// import moment from 'moment';
import moment from 'moment/min/moment-with-locales';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import AppLayout from '../AppLayout'
import CarParkItem from '../CarParkItem'
import {
  setBookingStep, setOrder, setSelectedCar, setSelectedParkingSlot, setSelectedPID, setSelectedPlan, setSelectedTimeSlot
} from '../../redux/actions/common';
import { COLORS, DefaultStyles } from '../../constants';

const { Option } = Select;

const ParkingSlot = () => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    moment.locale(i18n.language === 'zh_hant' ? 'zh-hk' : 'en');
  }, [i18n.language])

  const history = useHistory()
  const config = useSelector((state) => state.app.config)
  const booking = useSelector((state) => state.booking)
  const charger = useSelector((state) => state.charger)
  const { selectedTimeSlot, selectedParkingSlot } = booking
  const [parkingSlotList, setParkingSlotList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const { selectedCarPark } = charger;
  const dispatch = useDispatch();

  const getParkingSlotList = useCallback(async (end_time) => {
    setLoading(true)
    setSelectedEndTime(end_time)
    const resp = await Service.call('get', `/api/web/booking_info/available/${selectedCarPark.id}/${selectedTimeSlot.start_time}/${end_time}`)
    if (resp.status <= 0) {
      setLoading(false)
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }
    let number_of_timeslots = _.divide(
      moment.duration(
        moment.unix(end_time).diff(
          moment.unix(selectedTimeSlot.start_time)
        )
      ).asMinutes(),
      15
    )

    let parkingSlotArr = []
    _.map(resp.data, (item) => {
      parkingSlotArr = _.concat(parkingSlotArr, item.pids)
    })

    let parkingSlotGroupByPID = _.groupBy(_.compact(parkingSlotArr), 'pid')


    let availableParkingSlot = _.map(parkingSlotGroupByPID, (item, key) => {
      if (_.isEmpty(item)) return null;
      if (item.length !== number_of_timeslots) return null
      if (_.includes(_.map(item, 'status'), 2) || _.includes(_.map(item, 'status'), 10)) return null
      return {
        pid: key,
        pidDetail: _.first(item).pidDetail,
        booking_items: item
      }
    })

    setParkingSlotList(_.compact(availableParkingSlot))
    dispatch(setSelectedParkingSlot({}))
    setLoading(false)
  }, []);

  const bookCharger = async () => {
    if (_.isEmpty(selectedParkingSlot)) return notification.info({ message: 'Please select parking slot.'})

    let number_of_timeslots = selectedParkingSlot.booking_items.length
    let minute = number_of_timeslots * 15 // each session is 15 minutes
    const resp = await Service.call('get', `/api/web/plan/UltraFast/${selectedParkingSlot.pid}/${selectedTimeSlot.start_time}/${minute}`)
    if (resp.status <= 0) {
      return notification.info({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
    }

    const bookingInfoResp = await Service.call('post', `/api/web/booking_info/hold/${selectedParkingSlot.pid}`, { booking_items: selectedParkingSlot.booking_items })
    if (bookingInfoResp.status <= 0) {
      return notification.info({
        message: `${bookingInfoResp.errorMessage} [${bookingInfoResp.errorCode}] `,
      });
    }
    dispatch(setSelectedPlan(resp.data))
    dispatch(setSelectedCar({}))
    dispatch(setOrder({}))
    dispatch(setBookingStep('payment-detail'))
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('parking_slot'),
        leftComponent: (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={() => {
              dispatch(setSelectedParkingSlot({}))
              dispatch(setSelectedTimeSlot({}))
              dispatch(setBookingStep('select-time-slot'))
            }}
          />
        )
      }}
    >
      <Row>
        <Col span={24}>
          <CarParkItem item={selectedCarPark} />
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 30]}>
        <Col span={24}>
          <span style={DefaultStyles.subBody}>{t('date')}</span>
          <br />
          <span style={DefaultStyles.header}>
            {moment.unix(selectedTimeSlot.start_time).format('YYYY-MM-DD')}
            {' '}
            {t(_.lowerCase(moment.unix(selectedTimeSlot.start_time).format('ddd')))}
          </span>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 0]}>
            <Col span={9}>
              <p style={DefaultStyles.subBody}>{t('start_time')}</p>
              <Row align="middle">
                <Col style={DefaultStyles.header}>
                  {moment.unix(selectedTimeSlot.start_time).format('hh:mm')}
                  {t(moment.unix(selectedTimeSlot.start_time).locale('en').format('a'))}
                </Col>
              </Row>
            </Col>
            <Col span={15}>
              <p style={DefaultStyles.subBody}>{t('end_time')}</p>
              <Row
                gutter={[10, 0]}
                align="middle"
                justify="center"
                style={{
                  ...DefaultStyles.button,
                  margin: 0,
                  borderBottom: `1px solid ${COLORS.primary}`,
                  backgroundColor: selectedEndTime ? COLORS.white : COLORS.primary,
                  borderRadius: selectedEndTime ? 0 : 15,
                  marginTop: selectedEndTime ? -5 : 10,
                  marginBottom: 20,
                  // lineHeight: 2
                }}
              >
                <Col span={2}>
                  <img
                    src={selectedEndTime ? "/assets/timer.svg" : "/assets/white_timer.svg"}
                    alt="Timer"
                    style={{
                      width: 30, height: 30, objectFit: 'contain'
                    }}
                  />
                </Col>
                <Col
                  span={22}
                >
                  <Select
                    size="large"
                    bordered={false}
                    style={{
                      ...DefaultStyles.header,
                      width: '100%',
                      margin: '0px 5px',
                    }}
                    showArrow={false}
                    value={selectedEndTime ? `${moment.unix(selectedEndTime).format('hh:mm')} ${t(moment.unix(selectedEndTime).locale('en').format('a'))}` : null}
                    className="end-time-selector"
                    placeholder={<span style={{ ...DefaultStyles.subHeader, color: COLORS.white}}>{t('select_end_time')}</span>}
                    onChange={getParkingSlotList}
                  >
                    {
                      _.times(7, (item) => {
                        let session = moment.unix(selectedTimeSlot.start_time)
                          .add(30, 'minute')
                          .add(item * 15, 'minute')
                        return (
                          <Option
                            key={session.unix()}
                            value={session.unix()}
                          >
                            {session.format('hh:mm')}
                            {t(session.locale('en').format('a'))}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </Row>
              <div>
                <span style={DefaultStyles.subBody}>
                  *
                  {t('you_at_least_to_choose_30_mins')}
                </span>
                <br />
                <span style={DefaultStyles.subBody}>
                  *
                  {t('buffer_message', { buffer: config.CHARGING_BUFFER })}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {!_.isEmpty(parkingSlotList)
      && (
        <>
          <Row gutter={[0, 20]}>
            <Col span={24} style={DefaultStyles.subBody}>{t('select_parking_slot')}</Col>
            <Col span={24}>
              <Spin spinning={loading}>
                <ParkingSlotList data={parkingSlotList} />
              </Spin>
            </Col>
          </Row>
          <StatusIndicator />
        </>
      )}
      {/* No parking slot message */}
      {
        _.isEmpty(parkingSlotList)
        && selectedEndTime !== null
        && !loading
        && (
        <Row align="middle">
          <Col
            span={24}
            style={{
              ...DefaultStyles.subBody,
              textAlign: 'center',
              height: 60
            }}
          >
            {t('no_available_booking_plan_please_select_another_time_slot')}
          </Col>
        </Row>
        )
      }
      <Button
        disabled={loading}
        style={{ ...DefaultStyles.button, opacity: _.isEmpty(selectedParkingSlot) ? 0.6 : 1 }}
        onClick={bookCharger}
      >
        <span style={{...DefaultStyles.buttonText}}>
          {t('book_charger')}
        </span>
      </Button>
    </AppLayout>
  )
}

const ParkingSlotList = ({ data }) => {
  const booking = useSelector((state) => state.booking)
  const { selectedParkingSlot } = booking
  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultParkSlot()
  }, [data])

  const setDefaultParkSlot = () => {
    const availableParkingSlots = _.map(data, (item) => {
      const availableBookingItems = _.filter(item.booking_items, (bookingInfo) => {
        return ((bookingInfo.status === 1))
      })
      if (!_.isEmpty(availableBookingItems)) return item
    })
    if (_.isEmpty(availableParkingSlots)) return;
    dispatch(setSelectedParkingSlot(_.first(availableParkingSlots)))
    dispatch(setSelectedPID(_.first(availableParkingSlots).pidDetail))
  }

  const getBackgroundColor = (item) => {
    // hold or booked
    const holdOrBookedItems = _.filter(item.booking_items, (bookingInfo) => {
      return ((bookingInfo.status === 10 || bookingInfo.status === 2))
    })

    if (!_.isEmpty(holdOrBookedItems)) return COLORS.dark

    // selected
    if (!_.isEmpty(selectedParkingSlot) && item.pid === selectedParkingSlot.pid) return COLORS.light

    return COLORS.primary
  }

  if (_.isEmpty(data)) return null

  return (
    <Row>
      {_.map(data, (item) => {
        return (
          <Col span={6} key={item.pid}>
            <Row justify="center" align="middle">
              <Col span={22}>
                <Button
                  style={{
                    ...DefaultStyles.button,
                    backgroundColor: getBackgroundColor(item)
                  }}
                  onClick={() => {
                    // hold or booked
                    const holdOrBookedItems = _.filter(item.booking_items, (bookingInfo) => {
                      return ((bookingInfo.status === 10 || bookingInfo.status === 2))
                    })

                    if (!_.isEmpty(holdOrBookedItems)) return

                    dispatch(setSelectedParkingSlot(item))
                    dispatch(setSelectedPID(item.pidDetail))
                  }}
                >
                  <span style={DefaultStyles.buttonText}>
                    {item.pidDetail.parking_lot}
                  </span>
                </Button>
              </Col>
            </Row>
          </Col>
        )
      })}
    </Row>
  )
}


const StatusIndicator = () => {
  const { t } = useTranslation()
  return (
    <Row justify="start" align="middle" gutter={[0, 30]}>
      <Col span={8}>
        <Row align="middle" justify="center">
          <Col
            span={23}
            style={{
              fontSize: 14,
              fontWeight: '500',
              height: 30,
              backgroundColor: COLORS.primary,
              color: COLORS.white,
              borderRadius: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('available')}
          </Col>
        </Row>
      </Col>
      {/* <Col span={8}>
        <Row align="middle" justify="center">
          <Col
            span={23}
            style={{
              fontSize: 14,
              fontWeight: '500',
              height: 30,
              backgroundColor: COLORS.dark,
              color: COLORS.white,
              borderRadius: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('unavailable')}
          </Col>
        </Row>
      </Col> */}
      <Col span={8}>
        <Row align="middle" justify="center">
          <Col
            span={23}
            style={{
              fontSize: 14,
              fontWeight: '500',
              height: 30,
              backgroundColor: COLORS.light,
              color: COLORS.white,
              borderRadius: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('selected')}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}


export default ParkingSlot;

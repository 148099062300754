import Immutable from 'seamless-immutable';
import * as types from '../actions/types';

const initialState = Immutable({
  step: 'scan-charger',
  selectedCar: {},
  selectedPID: {},
  selectedPlan: {},
  selectedCarPark: {},
  selectedCoupon: {},
  userChargeRecord: {},
  selectedDiscount: 0,
  startTime: 0,
  chargedTime: null, // duration in sec
  refundStatus: 0,
  refundTime: 0
});

const appReducer = {
  charger: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_CHARGER_INIT: {
        return {
          ...state,
          selectedCar: {},
          selectedPID: {},
          selectedPlan: {},
          selectedCarPark: {},
          selectedCoupon: {},
          userChargeRecord: {},
          selectedDiscount: 0,
          startTime: 0,
          chargedTime: null,
          refundStatus: 0,
          refundTime: 0
        };
      }
      case types.SET_CHARGER_STEP: {
        return {...state, step: action.data};
      }
      case types.SET_SELECTED_CAR: {
        return {...state, selectedCar: action.data};
      }
      case types.SET_SELECTED_PID: {
        return {...state, selectedPID: action.data};
      }
      case types.SET_SELECTED_PLAN: {
        return {...state, selectedPlan: action.data};
      }
      case types.SET_SELECTED_CAR_PARK: {
        return {...state, selectedCarPark: action.data};
      }
      case types.SET_SELECTED_COUPON: {
        return {...state, selectedCoupon: action.data};
      }
      case types.SET_USER_CHARGE_RECORD: {
        return {...state, userChargeRecord: action.data};
      }
      case types.SET_SELECTED_DISCOUNT: {
        return {...state, selectedDiscount: action.data};
      }
      case types.SET_CHARGER_STATUS: {
        return {...state, ...action.data};
      }
      case types.SET_START_TIME: {
        return {...state, startTime: action.data};
      }
      case types.SET_CHARGED_TIME: {
        return {...state, chargedTime: action.data};
      }

      default:
        return state;
    }
  }
};

export default appReducer;

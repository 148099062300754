
import React from 'react'
import { useDispatch } from 'react-redux';
import { setSelectedCarPark } from '../../redux/actions/common';

const ext = 'png'; // org: 'svg'

const Marker = ({
  title, item, setZoom, setCenter, zoom
}) => {
  const dispatch = useDispatch()
  let source = `url('/assets/map-pin-available.${ext}')`
  switch (item.status) {
    case 'full':
      source = `url('/assets/map-pin-full.${ext}')`
      break;
    case 'almost full':
      source = `url('/assets/map-pin-almost-full.${ext}')`
      break;
    case 'available':
      source = `url('/assets/map-pin-available.${ext}')`
      break;
    default:
      source = `url('/assets/map-pin-full.${ext}')`
      break;
  }
  return (
    <button
      type="button"
      // role="button"
      // tabIndex={0}
      onClick={() => {
        dispatch(setSelectedCarPark(item))
        setZoom(16)
        setCenter({ lat: item.lat, lng: item.lng })
      }}
      label={title}
      style={{
        width: (zoom / 18) * 80,
        height: (zoom / 18) * 80,
        ...styles.marker,
        backgroundImage: source,
      }}
    />
  )
};


const CurrentMarker = ({
  title
}) => {
  return (
    <div
      label={title}
      style={styles.myLocation}
    />

  )
};

const styles = {
  marker: {
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'all 0.5 ease-in-out',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: 0,
    top: 0,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat"
  },
  myLocation: {
    top: '50%',
    left: '50%',
    width: 30,
    height: 30,
    userSelect: 'none',
    borderRadius: '100%',
    backgroundColor: 'rgb(83, 131, 236)',
    border: '5px solid #fff',
    transform: 'translate(-50%, -50%)',
    objectFit: 'contain'
  }
}
export { CurrentMarker }

export default Marker;

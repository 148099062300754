import React, { useEffect, useState } from 'react';
import { LeftCircleFilled } from '@ant-design/icons';
import {
  Button, Checkbox, Col, Input, Row, Select, Form, notification
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OTPCodeButton from '../components/OTPCodeButton'
import { setAuth, setModal, setUser } from '../redux/actions/common'
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import AppLayout from '../components/AppLayout';
import { COLORS, DefaultStyles } from '../constants';

const { Option } = Select;

const Registration = () => {
  const app = useSelector((state) => state.app)
  const site_meta = useSelector((state) => state.app.site_meta)
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [code, setCode] = useState(null)
  const [checked, setChecked] = useState(false)

  const { config = {} } = app;
  const {
    COUNTRY = "",
  } = config;

  const onFinish = async (formData) => {
    const dataObj = {
      ...formData,
      phone: `${formData.phonePrefix}-${formData.phone}`
    }

    const resp = await Service.call('post', '/api/web/user', dataObj);
    if (resp.errorCode) {
      return notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage, false),
        duration: 0
      })
    }

    await login(dataObj)
    // history.push('/registration-success')
  }

  const login = async (formData) => {
    let dataObj = formData;
    let resp = await Service.call('post', `/api/auth/web`, dataObj);

    if (resp.errorMessage) {
      notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
      return history.push('/login')
    }

    resp = await Service.call('get', `/api/web/user`);
    if (resp.errorMessage) {
      notification.error({
        message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
        duration: 0
      });
      return history.push('/login')
    }
    if (_.isEmpty(resp.data)) return history.push('/login')


    dispatch(setUser(resp.data));
    dispatch(setAuth(true));
    history.replace('/')
  }


  const getTnC = async () => {
    const modal = {
      visible: true,
      title: t('terms_and_conditions'),
      content: site_meta.static_terms_content.meta_value
    }
    dispatch(setModal(modal))
  }

  const getPrivacyPolicy = async () => {
    const modal = {
      visible: true,
      title: t('privacy_policy'),
      content: site_meta.static_privacy_content.meta_value
    }
    dispatch(setModal(modal))
  }

  let defaultPhonePrefix = "+852";
  let defaultPhoneLength = 8;
  let minPhoneLength = 8;
  if (COUNTRY === "AU") {
    defaultPhonePrefix = "+61";
    defaultPhoneLength = 10;
    minPhoneLength = 9;
  }
  const prefixSelector = (
    <Form.Item
      style={{ display: "none" }}
      name="phonePrefix"
      initialValue={defaultPhonePrefix}
      noStyle
    >
      <Select size="large" bordered={false} disabled style={{ ...DefaultStyles.body, width: '100%' }}>
        <Option value={defaultPhonePrefix}>{defaultPhonePrefix}</Option>
      </Select>
    </Form.Item>
  );

  const getRegistrationButtonOpacity = () => {
    if (_.isEmpty(code)) return 0;
    if (!checked) return 0.5;
    return 1
  }

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        title: t('register'),
        leftComponent: <LeftCircleFilled
          onClick={() => {
            history.push('/login')
          }}
          style={DefaultStyles.leftArrow}
        />
      }}
    >
      <Row justify="center">
        <Col
          span={24}
        >
          <Form
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="phone"
              validateFirst
              rules={[
                { required: true, message: t('required') }, {
                  min: minPhoneLength,
                  max: defaultPhoneLength,
                  message: t(`phone_number_should_be_${defaultPhoneLength}_digits`)
                }
              ]}
            >
              <Input
                style={DefaultStyles.formInput}
                size="large"
                prefix={(
                  <Row align="middle">
                    <Col>
                      <img alt="phone" src="/assets/phone.svg" style={DefaultStyles.fieldIcon} />
                    </Col>
                    <Col>
                      {prefixSelector}
                    </Col>
                  </Row>
                    )}
                placeholder={t('phone')}
              />
            </Form.Item>
            <Row align="middle" gutter={[20, 0]}>
              <Col span={15}>
                <Form.Item
                  name="code"
                  rules={[{ required: true, message: t('required') }]}
                  noStyle
                >
                  <Input
                    onChange={(e) => setCode(e.target.value)}
                    style={DefaultStyles.formInput}
                    size="large"
                    prefix={<img alt="phone 2fa" src="/assets/otp.svg" style={DefaultStyles.fieldIcon} />}
                    placeholder={t('verification_code')}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <OTPCodeButton
                  style={{...DefaultStyles.button }}
                  form={form}
                  action="/api/web/verify/send_otp"
                />
              </Col>
            </Row>
            <Row gutter={[20, 0]} style={{ marginBottom: 10 }}>
              <Col span={15} />
              <Col span={9}>
                <Row justify="center" style={{ fontSize: 12, textAlign: 'center', color: '#777777' }}>{t('resend_after_60_seconds')}</Row>
              </Col>
            </Row>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: t('incorrect_email_format')
                }]}
            >
              <Input
                disabled={_.isEmpty(code)}
                style={{
                  ...DefaultStyles.formInput,
                  opacity: _.isEmpty(code) ? 0 : 1,
                  transition: 'all 0.5s ease-in-out'
                }}
                size="large"
                prefix={<img alt="email" src="/assets/email.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('email')}
              />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[{ required: false, message: t('required') }]}
            >
              <Input
                disabled={_.isEmpty(code)}
                style={{
                  ...DefaultStyles.formInput,
                  opacity: _.isEmpty(code) ? 0 : 1,
                  transition: 'all 0.5s ease-in-out'
                }}
                size="large"
                prefix={<img alt="id card" src="/assets/id-card.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('name')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('required') }]}
            >
              <Input.Password
                disabled={_.isEmpty(code)}
                style={{
                  ...DefaultStyles.formInput,
                  opacity: _.isEmpty(code) ? 0 : 1,
                  transition: 'all 0.5s ease-in-out'
                }}
                size="large"
                prefix={<img alt="password" src="/assets/password.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('password')}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              dependencies={['password']}
              hasFeedback
              validateFirst
              rules={[
                {
                  required: true,
                  message: t('please_confirm_your_password'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password
                disabled={_.isEmpty(code)}
                style={{
                  ...DefaultStyles.formInput,
                  opacity: _.isEmpty(code) ? 0 : 1,
                  transition: 'all 0.5s ease-in-out'
                }}
                size="large"
                prefix={<img alt="confirm password" src="/assets/password.svg" style={DefaultStyles.fieldIcon} />}
                placeholder={t('confirm_password')}
              />
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              name="agree_tnc"
              // rules={[
              //   {
              //     required: _.isEmpty(code),
              //     message: 'Please confirm the terms and conditions and the privacy policy!',
              //     transform: (value) => (value || undefined),
              //     type: 'boolean',
              //   },
              // ]}
            >
              <Checkbox
                onChange={(value) => setChecked(!checked)}
                className="custom-checkbox"
                style={{
                  color: '#777777',
                  opacity: _.isEmpty(code) ? 0 : 1,
                  top: '.2em',
                  display: 'inline-flex'
                }}
              >
                {t('i_have_read_and_accepted_the')}
                <span
                  role="button"
                  tabIndex={0}
                  onMouseDown={getTnC}
                  style={{ color: COLORS.dark, padding: 0 }}
                >
                  {' '}
                  {t('terms_and_conditions')}
                  {' '}
                </span>
                {t('and_the')}
                <span
                  role="button"
                  tabIndex={0}
                  onMouseDown={getPrivacyPolicy}
                  style={{ color: COLORS.dark, padding: 0 }}
                >
                  {' '}
                  {t('privacy_policy')}
                </span>
                .
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                disabled={!checked}
                style={{
                  ...DefaultStyles.button,
                  opacity: getRegistrationButtonOpacity(),
                  transition: 'all 0.5s ease-in-out'
                }}
                type="primary"
                htmlType="submit"
              >
                <span style={DefaultStyles.buttonText}>
                  {t('register')}
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default Registration;

import React, {useState, useEffect} from 'react';
import { Button, message, notification } from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { disable } from 'debug';
import * as Service from '../core/Service'
import { COLORS, FONTS } from '../constants';

let timer;

const OTPCodeButton = ({
  form,
  style,
  action
}) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const [sent, setSent] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isSent, setIsSent] = useState(false);

  const { config = {} } = app;
  const {
    COUNTRY = "",
  } = config;

  useEffect(() => {
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      setDisabled(false)
      setSent(false);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [countdown]);

  const startCountdown = (value) => {
    setSent(true);
    setDisabled(true)
    setCountdown(value);
    setIsSent(true);
  };

  const getCode = async () => {
    let defaultPhoneLength = 8;
    let minPhoneLength = 8;
    if (COUNTRY === "AU") {
      defaultPhoneLength = 10;
      minPhoneLength = 9;
    }
    if (_.isEmpty(form.getFieldValue('phone')) || form.getFieldValue('phone').length > defaultPhoneLength || form.getFieldValue('phone').length < minPhoneLength) return message.warning(t('please_input_a_valid_phone_number'))
    setDisabled(true)
    const resp = await Service.call('post',
      action,
      {
        phone: `${form.getFieldValue('phonePrefix')}-${form.getFieldValue('phone')}`
      })
    if (resp.errorMessage) {
      setDisabled(false)
      return notification.info({
        message: `${resp.errorMessage}`
      })
    }

    startCountdown(60)

    return notification.info({
      message: t('sms_sent')
    })
  }

  const displayWording = () => {
    const reg = /.*[\u4e00-\u9fa5]+.*$/
    let wording = ''
    if (sent) {
      wording = countdown
    } else if (isSent) {
      wording = t('resend')
    } else {
      wording = t('get_code')
    }
    return <span style={{ fontSize: reg.test(wording) ? 16 : undefined }}>{wording}</span>
  }


  return (
    <>
      <Button
        onClick={getCode}
        style={{...styles.button, ...style, opacity: disabled ? 0.8 : 1}}
        disabled={disabled}
      >
        {displayWording()}
      </Button>
    </>
  );
};

const styles = {
  button: {
    ...FONTS.subHeader,
    color: COLORS.white,
    width: '100%',
  }
}

export default OTPCodeButton;

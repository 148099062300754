import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, notification, Empty
} from 'antd';
import {
  PlusOutlined,
  LeftCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import AppLayout from '../AppLayout'
import {
  setMonthlyStep, setSelectedSubscription
} from '../../redux/actions/common';
import { COLORS, DefaultStyles } from '../../constants';

const MonthlyList = ({
  setLoading,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [dataList, setDataList] = useState({})
  const dispatch = useDispatch();

  useEffect(() => {
    getDataList()
  }, [])

  const getDataList = async() => {
    setLoading(true)
    let dataSource = []
    try {
      const resp = await Service.call('get', '/api/web/user/subscription');
      dataSource = resp.data
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(dataSource);
    }
    setLoading(false)
  }

  return (
    <AppLayout
      headerOptions={{
        title: t('my_monthly_plan'),
        leftComponent: (
          <LeftCircleFilled
            style={DefaultStyles.leftArrow}
            onClick={() => {
              return history.push('/')
            }}
          />
        )
      }}
    >
      <Row justify="center" gutter={[0, 10]}>
        <Col span={24}>
          {_.isEmpty(
            dataList
          ) ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('no_subscription')}
            />
            )
            : _.map(dataList, (item) => {
              return (
                <SubscriptionItem
                  key={item.id}
                  item={item}
                />
              )
            })}
        </Col>
        <Col span={24}>
          <Button
            style={{ ...DefaultStyles.button }}
            icon={<PlusOutlined style={{ ...styles.buttonIcon}} />}
            onClick={() => {
              dispatch(setMonthlyStep('select-location'))
            }}
          >
            <span style={{ ...DefaultStyles.buttonText }}>{t('add_new')}</span>
          </Button>
        </Col>
      </Row>
    </AppLayout>
  )
}

const SubscriptionItem = ({ item }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <Row
      style={DefaultStyles.subscriptionCard}
      align="middle"
      justify="space-between"
      onClick={() => {
        dispatch(setSelectedSubscription(item))
        dispatch(setMonthlyStep('monthly-detail'))
      }}
    >
      <Col span={16}>
        <Row gutter={[0, 0]}>
          <Col span={23} style={{...DefaultStyles.subHeader, wordBreak: 'break-all'}}>{item.pid}</Col>
          <Col span={23} style={DefaultStyles.body}>{item.planDetail.name}</Col>
          <Col span={23} style={DefaultStyles.subBody}>{item.carParkDetail.name}</Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row justify="end" style={{ textAlign: 'start' }} gutter={[0, 0]}>
          <Col span={22} style={DefaultStyles.subBody}>{t('start_date')}</Col>
          <Col span={22} style={DefaultStyles.body}>
            {moment.unix(item.start_time).format('YYYY-MM-DD')}
          </Col>
          <Col
            span={22}
            style={{
              ...DefaultStyles.subBody,
              marginTop: 10,
              backgroundColor: COLORS.light,
              padding: '10px 15px',
              borderRadius: 10,
              textAlign: 'center'
            }}
          >
            <span>
              {item.pidDetail.parking_lot}
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const styles = {
  card: {
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    marginBottom: 10,
    minHeight: 80,
    padding: 15,
  },
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: '#1A468F',
    borderColor: 'transparent',
    height: 50,
    marginTop: 20
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '600'
  },
  buttonIcon: {
    fontSize: 18,
    color: '#fff'
  }
}


export default MonthlyList;

import React, { useEffect, useState } from 'react';
import {
} from 'antd';
import {
  CloseCircleFilled
} from '@ant-design/icons';
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Coupon from '../Coupon'
import {
  setMonthlyStep
} from '../../redux/actions/common'

import AppLayout from '../AppLayout'

const BookingCoupon = ({ setLoading }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <AppLayout
      headerOptions={{
        title: t('coupon_selection'),
        rightComponent: <CloseCircleFilled
          onClick={() => {
            dispatch(setMonthlyStep('payment-detail'))
          }}
          style={{ fontSize: 32, textAlign: 'rigth' }}
        />
      }}
    >
      <Coupon
        setLoading={setLoading}
        applyCouponCallback={() => dispatch(setMonthlyStep('payment-detail'))}
      />
    </AppLayout>
  )
}

export default BookingCoupon;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Button,
  notification,
  Row,
  Col,
  Divider,
  Modal
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Elements, CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash'
import { COLORS, DefaultStyles } from '../constants';
import PaymentWallet from './payment/Wallet';
import PaymentCard from './payment/Card';
import * as Service from '../core/Service'
import RejectPayment from './payment/RejectPayment';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// #InjectedCheckoutForm
const StripeCheckoutForm = (props) => {
  const app = useSelector((state) => state.app)
  const history = useHistory()
  const { t } = useTranslation()
  const [clientSecret, setClientSecret] = useState("");

  const {
    price,
    order,
    onSuccessCallBack,
    displayItems,
    setLoading,
    checkOrderStatus
  } = props;

  useEffect(() => {
    getClientSecret()
  }, [])

  const getClientSecret = async () => {
    setLoading(true)
    let resp = await Service.call('put', `/api/web/payment/stripe`, {
      order_key: order.order_key,
    });
    setLoading(false)
    if (resp.status <= 0) {
      const { errorMessage = "" } = resp
      let errorMessageDisplay = t('failed_to_connect_to_the_payment_channel_please_try_again_later');
      if (errorMessage) {
        errorMessageDisplay = errorMessage;
      }

      Modal.error({
        title: t('payment_fail'),
        content: (
          <div>
            {errorMessageDisplay}
          </div>
        )
      });
      return null;
    }
    const {
      transaction,
      stripe,
    } = resp;

    setClientSecret(stripe ? stripe.client_secret : '')
  };

  const locale = useMemo(() => {
    const lang = localStorage.getItem('language');
    if (_.isEmpty(lang)) return 'en';
    if (lang === 'zh_hant') return 'zh-HK'
    return lang;
  }, []);

  // Invalid Order
  if (order.status !== 'placed') return <RejectPayment status={order.status} />
  return (
    <div style={{ minHeight: '100vh' }}>
      {clientSecret && (
        <Elements
          options={{
            theme: 'flat',
            clientSecret,
            variables: {
              colorPrimary: COLORS.green,
              colorText: COLORS.green,
            },
            locale
          }}
          stripe={stripePromise}
        >
          <OrderDetail price={order.final_price} />
          <PaymentWallet
            clientSecret={clientSecret}
            price={order.final_price * 100}
            displayItems={displayItems}
            setLoading={setLoading}
            onSuccessCallBack={onSuccessCallBack}
            checkOrderStatus={checkOrderStatus}
          />
          <PaymentCard
            clientSecret={clientSecret}
            order={order}
            price={price}
            onSuccessCallBack={onSuccessCallBack}
            checkOrderStatus={checkOrderStatus}
          />
        </Elements>
      )}
    </div>
  );
};

const OrderDetail = ({ price }) => {
  const { t } = useTranslation()
  return (
    <Row justify="center" style={{textAlign: 'center'}}>
      <Col
        style={{
          ...DefaultStyles.body,
          color: '#767676'
        }}
        span={24}
      >
        {t('credit')}
      </Col>
      <Col style={DefaultStyles.header} span={24}>
        HK$
        {price.toFixed(2)}
      </Col>
    </Row>
  )
}

export {
  OrderDetail
}
export default StripeCheckoutForm;
